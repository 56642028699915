import { ITextFieldProps, MessageBar, MessageBarType, Stack, TextField } from '@fluentui/react';
import { EncounterStatus } from 'api/models/encounter.model';
import { TModal } from 'components';
import { FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { classicDateOnly } from 'utils/dateOnly';
import {
    selectWorkListEditItem,
    selectWorkListReadOnly,
    selectWorkListSaving,
    selectIsCurrentWorkListEditEncounterWorkList,
    selectCurrentWorkList,
} from 'state/slices/admin-huddle/worklists/worklist.selectors';
import {
    cleanupEncounterWorkListModal,
    setWorkListConfirmationModalOpen,
    setEncounterWorkListModalNote,
    setEncounterWorkListModalOpen,
} from 'state/slices/admin-huddle/worklists/worklist.slice';
import EncounterCorrespondenceNotes, { EncounterCorrespondenceNotesProps } from './EncounterCorrespondenceNotes';
import { IActionButton } from 'components/ActionButton';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'interfaces/route-params';
import UserDisplayName from 'components/UserDisplayName';
import {
    updateEncounterWorkListEncounterNote,
    setOnHoldStatus,
    saveEncounterCorrespondenceEncounterWorkList,
    saveEncounterCorrespondenceAndSend,
} from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.actions';
import {
    selectEncounterWorkListNoteModalIsOpen,
    selectEncounterWorkListNoteModalType,
    selectEncounterWorkListNoteModalNote,
    selectEncounterWorkListNoteModalSaving,
    selectEncounterWorkListNoteModalAction,
} from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.selectors';
import {
    EncounterWorkList,
    EncounterWorkListModalType,
    EncounterWorkListNoteModalAction,
} from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { WorkListConfirmModalType } from 'state/slices/admin-huddle/worklists/worklist.state';
import BannerInfoDetailItem from '../LayerComponents/VisitSummary/VisitSummaryEncounterDate';
import { getTimeTextFromValue } from 'utils/getTimeOptions';

const modalTitleLookup: Partial<Record<EncounterWorkListModalType, string>> = {
    [EncounterWorkListModalType.AdministrativeHold]: 'Administrative Hold',
    [EncounterWorkListModalType.BillingNote]: 'Billing Note',
    [EncounterWorkListModalType.CorrectionNote]: 'Notes',
};

const lookupComponent: Record<EncounterWorkListModalType, (props: EncounterCorrespondenceNotesProps) => JSX.Element | null> = {
    [EncounterWorkListModalType.AdministrativeHold]: AdministrativeHoldAndBillingNote,
    [EncounterWorkListModalType.BillingNote]: AdministrativeHoldAndBillingNote,
    [EncounterWorkListModalType.CorrectionNote]: EncounterCorrespondenceNotes,
};

const labelByNoteType: Partial<Record<EncounterWorkListModalType, string>> = {
    [EncounterWorkListModalType.AdministrativeHold]: 'Reason for hold',
    [EncounterWorkListModalType.BillingNote]: 'Additional billing notes',
};

const placeholderByNoteType: Partial<Record<EncounterWorkListModalType, string>> = {
    [EncounterWorkListModalType.AdministrativeHold]: 'Brief summary of why this encounter is being put on hold',
    [EncounterWorkListModalType.BillingNote]: '',
};

export const lookupEditEncounterActionTextAndIcon: Record<EncounterWorkListNoteModalAction, { label: string; iconName: string }> =
{
    [EncounterStatus.Billed]: {
        label: 'Save and Abort',
        iconName: 'Undo',
    },
    [EncounterStatus.ReBillOnHold]: {
        label: 'Save and Send for Clarification',
        iconName: 'Send',
    },
    [EncounterStatus.CorrectionsCompleted]: {
        label: 'Save and Send to Ready to Re-Approve',
        iconName: 'Send',
    },
    [EncounterStatus.CorrectionAmend]: {
        label: 'Save and Send for Provider Correction & Addendum',
        iconName: 'Send',
    },
    Rebill: {
        label: 'Reapprove Encounter',
        iconName: 'Checkmark',
    },
    [EncounterStatus.CorrectionsNeeded]: {
        label: 'Send for Non-Provider Correction & Reapproval',
        iconName: 'Send',
    },
};

//TODO: Future James to come back and make this modal logic better.
export function EncounterNoteModal() {
    const dispatch = useDispatch();
    const { tenantId } = useParams<RouteParams>();

    const isOpen = useSelector(selectEncounterWorkListNoteModalIsOpen);
    const noteType = useSelector(selectEncounterWorkListNoteModalType);
    const note = useSelector(selectEncounterWorkListNoteModalNote);
    const savingStatus = useSelector(selectEncounterWorkListNoteModalSaving);
    const savingStatusWorkList = useSelector(selectWorkListSaving);
    const saving = savingStatus === LoadingStatus.Pending;
    const savingWorkList = savingStatusWorkList === LoadingStatus.Pending;

    const hasError = savingStatus === LoadingStatus.Failed;
    const readOnly = useSelector(selectWorkListReadOnly);

    const encounterView = useSelector(selectWorkListEditItem<IWorkListEncounterView>);
    const encounterAction = useSelector(selectEncounterWorkListNoteModalAction);

    const isEditEncounterWorkList = useSelector(selectIsCurrentWorkListEditEncounterWorkList);
    const currentWorkList = useSelector(selectCurrentWorkList);

    const isOpenBilledEncounterWorkList =
        currentWorkList === EncounterWorkList.BilledInsurance || currentWorkList === EncounterWorkList.BilledPatient;

    const _encounterOnHold = encounterView?.encounter?.isOnAdministrativeHold;

    const _onDismiss = () => dispatch(setEncounterWorkListModalOpen({ isOpen: false }));
    const _onDismissed = () => dispatch(cleanupEncounterWorkListModal());

    const _handleSave = () => {
        switch (noteType) {
            case 'administrativeHold': {
                _encounterOnHold ? dispatch(updateEncounterWorkListEncounterNote(tenantId)) : dispatch(setOnHoldStatus(tenantId));
                break;
            }
            case 'billingNote': {
                dispatch(updateEncounterWorkListEncounterNote(tenantId));
                break;
            }
            case 'correctionNote': {
                dispatch(
                    saveEncounterCorrespondenceEncounterWorkList({
                        tenantId,
                    }),
                );
                break;
            }
        }
    };

    const _handleSaveAndSendEncounter = (encounterStatus?: EncounterStatus | 'Rebill') => {
        if (
            encounterStatus === EncounterStatus.Billed ||
            encounterStatus === EncounterStatus.CorrectionsCompleted ||
            encounterStatus === 'Rebill'
        ) {
            dispatch(setWorkListConfirmationModalOpen({ isOpen: true, type: encounterStatus as WorkListConfirmModalType }));
        } else if (encounterView?.patientId && encounterStatus) {
            dispatch(saveEncounterCorrespondenceAndSend({ tenantId, patientId: encounterView.patientId, encounterStatus }));
        }
    };

    const _confirmText = saving || savingWorkList ? 'Saving Note...' : 'Save Note';
    const Component = noteType ? lookupComponent[noteType] : null;

    const saveDisabled = !note || note.length < 3 || saving || savingWorkList || readOnly;

    const saveActionButton: IActionButton = {
        text: _confirmText,
        iconProps: { iconName: 'Save' },
        disabled: saveDisabled,
        onClick: _handleSave,
    };

    const cancelActionButton: IActionButton = {
        text: readOnly ? 'Close' : 'Cancel',
        type: 'DefaultButton',
        onClick: _onDismiss,
        disabled: saving || savingWorkList,
    };

    const saveAndCancelButtons: IActionButton[] = [{ ...saveActionButton, type: 'PrimaryButton' }, cancelActionButton];

    const editEncounterActionButtons: IActionButton[] = [cancelActionButton];

    if (encounterAction) {
        const { label, iconName } = lookupEditEncounterActionTextAndIcon[encounterAction];

        editEncounterActionButtons.unshift({
            text: saving || savingWorkList ? 'Saving and Sending...' : label,
            iconProps: { iconName },
            type: 'PrimaryButton',
            disabled: saveDisabled,
            onClick: () => _handleSaveAndSendEncounter(encounterAction),
        });
    } else {
        editEncounterActionButtons.unshift(saveActionButton);
    }

    const lookupMainButtonsForEncounterWorkList: Partial<Record<EncounterWorkListNoteModalAction, IActionButton[]>> = {
        [EncounterStatus.Billed]: editEncounterActionButtons,
        [EncounterStatus.CorrectionsCompleted]: editEncounterActionButtons,
        [EncounterStatus.CorrectionAmend]: editEncounterActionButtons,
        [EncounterStatus.CorrectionsNeeded]: editEncounterActionButtons,
        [EncounterStatus.ReBillOnHold]: editEncounterActionButtons,
        Rebill: editEncounterActionButtons,
    };

    const lookupMainButtons: Record<EncounterWorkListModalType, IActionButton[]> = {
        [EncounterWorkListModalType.AdministrativeHold]: saveAndCancelButtons,
        [EncounterWorkListModalType.BillingNote]: saveAndCancelButtons,
        [EncounterWorkListModalType.CorrectionNote]:
            encounterAction &&
                (isEditEncounterWorkList || isOpenBilledEncounterWorkList || currentWorkList === EncounterWorkList.Denials)
                ? lookupMainButtonsForEncounterWorkList[encounterAction] ?? []
                : editEncounterActionButtons,
    };

    return (
        <TModal
            title={noteType ? modalTitleLookup[noteType] ?? 'Unknown Note' : 'Unknown Note'}
            modalProps={{ isOpen, onDismiss: _onDismiss, onDismissed: _onDismissed, isBlocking: true }}
            mainButtons={readOnly ? [cancelActionButton] : noteType ? lookupMainButtons[noteType] : []}
            isDraggable
        >
            <Stack tokens={{ childrenGap: 10 }} style={{ padding: 10 }}>
                <EncounterNoteModalHeader
                    patientName={encounterView ? `${encounterView?.patientLastName}, ${encounterView?.patientFirstName}` : 'N/A'}
                    encounterDate={encounterView?.encounterDate}
                    billingProviderId={encounterView?.billingProviderId}
                    treatingProviderId={
                        encounterView?.appointments ? encounterView.appointments[0]?.treatingProviderId : undefined
                    }
                    apptStartTime={encounterView?.appointments ? encounterView?.appointments[0]?.startTime : undefined}
                    encounterReason={encounterView?.encounter?.encounterReason}
                />
                {hasError && <MessageBar messageBarType={MessageBarType.error}>Something went wrong.</MessageBar>}
                {Component && <Component />}
            </Stack>
        </TModal>
    );
}

function AdministrativeHoldAndBillingNote() {
    const dispatch = useDispatch();

    const _onChange = (_: FormEvent, value?: string) => {
        dispatch(setEncounterWorkListModalNote(value ?? ''));
    };

    const noteType = useSelector(selectEncounterWorkListNoteModalType);
    const note = useSelector(selectEncounterWorkListNoteModalNote);
    const saving = useSelector(selectWorkListSaving) === LoadingStatus.Pending;

    if (!noteType) return null;

    const _textFieldProps: ITextFieldProps = {
        label: labelByNoteType[noteType],
        placeholder: placeholderByNoteType[noteType],
        style: { minHeight: 200, minWidth: 350 },
    };

    return (
        <TextField
            multiline
            required
            value={note}
            onChange={_onChange}
            disabled={saving}
            {..._textFieldProps}
            autoFocus={note === ''}
        ></TextField>
    );
}

export function EncounterNoteModalHeader({
    patientName,
    encounterDate,
    billingProviderId,
    treatingProviderId,
    encounterReason,
    apptStartTime,
}: {
    patientName?: string;
    encounterDate?: string;
    billingProviderId?: string;
    apptStartTime?: string;
    encounterReason?: string;
    treatingProviderId?: string;
}) {
    return (
        <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
            <BannerInfoDetailItem label="Patient" text={patientName ?? 'Unknown Patient'} />
            <BannerInfoDetailItem label="Encounter Date" text={encounterDate ? classicDateOnly(encounterDate) : 'N/A'} />
            <BannerInfoDetailItem label="Billing Provider" text={<UserDisplayName userId={billingProviderId} />} />
            {apptStartTime && (
                <BannerInfoDetailItem label="Appt. Start Time" text={getTimeTextFromValue(apptStartTime) ?? 'Uknown Time'} />
            )}
            {encounterReason && (
                <BannerInfoDetailItem label="Encounter Reason" text={encounterReason ?? 'Unknown Encounter Reason'} />
            )}
            {treatingProviderId && (
                <BannerInfoDetailItem label="Treating Provider" text={<UserDisplayName userId={treatingProviderId} />} />
            )}
        </Stack>
    );
}
