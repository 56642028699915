import { IDropdownOption } from '@fluentui/react';
import { createAsyncThunk, createSelector, createSlice, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IPayer } from 'api/models/payer.model';
import { AxiosResponse, AxiosError } from 'axios';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { LookupState } from 'interfaces/lookup-state';
import { RootState } from 'state/store';

type PayerState = LookupState<IPayer>;

const initialState: PayerState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    data: {},
};

export const getPayers = createAsyncThunk<
    AxiosResponse<Dictionary<IPayer>>,
    unknown,
    {
        rejectValue: AxiosError;
    }
>('getPayers', async () => {
    const res = await dentalApi.getPayers();
    return res;
});

const payersSlice = createSlice({
    name: 'payers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPayers.pending, (state) => {
                state.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getPayers.fulfilled, (state, action) => {
                state.initialLoad = LoadingStatus.Completed;
                state.data = action.payload.data;
            })
            .addCase(getPayers.rejected, (state) => {
                state.initialLoad = LoadingStatus.Failed;
            });
    },
});

const { reducer } = payersSlice;

export const selectPayers = (state: RootState): Dictionary<IPayer> | undefined => state.tenant.payers.data;
export const selectPayersLoading = (state: RootState) => state.tenant.payers.loading;
export const selectPayerData = createSelector(selectPayers, (data) => (data ? data : {}));
export const payersList = createSelector(selectPayers, (data) => {
    if (data) return Object.keys(data).map((key) => data[key]) as IPayer[];
    return [];
});
export const filteredPayers = createSelector(payersList, (data) => {
    return data.filter((p) => !p.isDeleted);
});

export const payerOptions = createSelector(filteredPayers, (data) => {
    return data.map((p) => ({ key: p.id, text: p.name ?? 'Unknown Payer' } as IDropdownOption));
});

export default reducer;
