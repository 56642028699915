import { Stack } from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';
import { IWrapReport } from 'api/models/wrap.model';
import { Field } from 'components';
import { useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import ReportWrapper from 'pages/Reporting/ReportWrapper';
import { useDispatch, useSelector } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { selectReportProperties, selectReportingValidationErrors } from 'state/slices/reporting/reporting.selectors';
import { setWrapProp, toggleWrapId } from 'state/slices/reporting/reporting.slice';
import { payerOptions } from 'state/slices/tenant/payers.slice';
import { classicDateOnly } from 'utils/dateOnly';

export default function Wrap() {
    const query = useSelector(selectReportProperties) as IWrapReport;
    const dispatch = useDispatch();

    const tenantId = useTenantId();

    const errors = useSelector(selectReportingValidationErrors);

    const _onViewReport = () => {
        if (query) dispatch(reportActionLookup[ReportType.Wrap]({ tenantId, ...query }));
    };

    const _payerOptions = useSelector(payerOptions);

    return (
        <ReportWrapper
            validationConfig={[
                { fieldName: 'Start Date', validation: ['required'], value: query?.startDate },
                { fieldName: 'End Date', validation: ['required'], value: query?.endDate },
            ]}
            onViewReport={_onViewReport}
        >
            <Stack tokens={{ childrenGap: 10 }} grow horizontal wrap>
                <Stack.Item>
                    <Field.Date
                        label="Start Date"
                        required
                        value={query?.startDate ? classicDateOnly(query?.startDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setWrapProp({ path: 'startDate', value: newDate }));
                        }}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="End Date"
                        required
                        value={query?.endDate ? classicDateOnly(query?.endDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setWrapProp({ path: 'endDate', value: newDate }));
                        }}
                        isReasonable
                        disabled={!query?.startDate}
                        minReasonableErrorMessage="End date must be after start date."
                        minReasonableDate={query?.startDate ? new Date(classicDateOnly(query?.startDate)) : undefined}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Carriers)"
                        label="Carrier"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={_payerOptions}
                        onChange={(ev, option) => {
                            if (option?.key) dispatch(toggleWrapId({ id: option.key as string, path: 'insuranceCarrier' }));
                        }}
                        selectedKey={query?.insuranceCarrier}
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}
