import { createSelector } from '@reduxjs/toolkit';
import { IChartProcedure } from 'api/models/chart.model';
import { IProcedureDetail } from 'api/models/procedure-detail.model';
import { find, findIndex } from 'lodash';
import { ProcedureActionType } from 'state/slices/charting/chart/chart.slice';
import { selectProcedureTemplatesLookup } from 'state/slices/lookups/lookups.selectors';
import { RootState } from 'state/store';
import { IQuickNotesState } from './quick-notes.state';

export const selectQuickNotes = (state: RootState): IQuickNotesState => state.chartNotes.quickNotes;
export const selectCompletedProcedures = (state: RootState): IChartProcedure[] =>
    state.chartNotes.quickNotes.assets.chartProcedures.completed;

export const filteredCompletedProcedures = createSelector(
    selectCompletedProcedures,
    (selectCompletedProcedures): IChartProcedure[] =>
        selectCompletedProcedures.filter((prod) => prod.type === ProcedureActionType.Treatment),
);

export const selectCompletedProcedureDetails = createSelector(
    selectProcedureTemplatesLookup,
    filteredCompletedProcedures,
    (procedureDetailsWithTemplates, completedProcedures): IProcedureDetail[] => {
        return procedureDetailsWithTemplates.filter(
            (detail) => findIndex(completedProcedures, (proc) => proc.procedureId === detail.id) > -1,
        );
    },
) as (state: RootState, tenantId: string) => IProcedureDetail[];
