import { IDropdownOption, Stack } from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';

import { TimeOfServiceReportQuery } from 'api/models/time-of-service-report-query.model';
import { Field } from 'components';
import { useSelector, useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { getAllLocationsOfCareForReports } from 'state/slices/reporting/reporting.actions';
import {
    selectLocationsOfCareFromReporting,
    selectReportProperties,
    selectReportingValidationErrors,
} from 'state/slices/reporting/reporting.selectors';
import { setTimeOfServiceProp, toggleTimeOfServiceQueryItem } from 'state/slices/reporting/reporting.slice';
import { payerOptions } from 'state/slices/tenant/payers.slice';
import { selectTreatingProviderOptions } from 'state/slices/tenant/providers.slice';
import { classicDateOnly } from 'utils/dateOnly';
import ReportWrapper from '../../ReportWrapper';

type Props = {
    type: ReportType.TimeOfServiceCollectionDetails | ReportType.TimeOfServiceCollectionSummary;
};

export default function TimeOfServiceCollectionReport({ type }: Props) {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const locationsOfCare = useSelector(selectLocationsOfCareFromReporting);
    const _providerOptions = useSelector(selectTreatingProviderOptions);
    const _payerOptions = useSelector(payerOptions);

    const errors = useSelector(selectReportingValidationErrors);

    const reportProperties = useSelector(selectReportProperties) as TimeOfServiceReportQuery | undefined;

    useEffect(() => {
        dispatch(getAllLocationsOfCareForReports({ tenantId }));
    }, []);

    const locationsOfCareOptions: IDropdownOption[] = locationsOfCare.map((loc) => ({
        key: loc.id,
        text: loc.displayName,
    }));

    const onViewReport = () => {
        if (reportProperties)
            dispatch(
                reportActionLookup[type]({
                    tenantId,
                    ...reportProperties,
                }),
            );
    };

    return (
        <ReportWrapper
            validationConfig={[
                {
                    fieldName: 'Start Date',
                    validation: ['required'],
                    value: reportProperties?.startDate,
                },
                {
                    fieldName: 'End Date',
                    validation: ['required'],
                    value: reportProperties?.endDate,
                },
            ]}
            onViewReport={onViewReport}
        >
            <Stack grow horizontal wrap tokens={{ childrenGap: 10 }}>
                <Stack.Item>
                    <Field.Date
                        label="Start Date"
                        required
                        hasDatePicker
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setTimeOfServiceProp({ path: 'startDate', value: newDate }));
                        }}
                        value={reportProperties?.startDate ? classicDateOnly(reportProperties?.startDate, 'MM/dd/yyyy') : ''}
                        errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="End Date"
                        required
                        isReasonable
                        hasDatePicker
                        value={reportProperties?.endDate ? classicDateOnly(reportProperties?.endDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setTimeOfServiceProp({ path: 'endDate', value: newDate }));
                        }}
                        disabled={!reportProperties?.startDate}
                        minReasonableErrorMessage="End date must be after start date."
                        minReasonableDate={
                            reportProperties?.endDate ? new Date(classicDateOnly(reportProperties?.endDate)) : undefined
                        }
                        errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                    />
                </Stack.Item>

                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Provider)"
                        label="Providers"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={_providerOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleTimeOfServiceQueryItem({ value: option.key as string, path: 'treatingProviderIds' }),
                                );
                        }}
                        selectedKey={reportProperties?.treatingProviderIds}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Locations of Care)"
                        label="Locations of Care"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={locationsOfCareOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleTimeOfServiceQueryItem({ value: option.key as string, path: 'locationOfCareIds' }),
                                );
                        }}
                        selectedKey={reportProperties?.locationOfCareIds}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Carriers)"
                        label="Carrier"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={_payerOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleTimeOfServiceQueryItem({ value: option.key as string, path: 'insuranceCarrierIds' }),
                                );
                        }}
                        selectedKey={reportProperties?.insuranceCarrierIds}
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}
