import IPatientContact from 'api/models/patient-contact.model';
import IPatient from 'api/models/patient.model';
import { IUpcomingAppointment } from 'api/models/Scheduling/patientAppointment.model';
import { IPendingChartTreatmentPlanPhaseProcedure } from 'api/models/treatment-plan.model';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { SyncStatus, SyncStatuses } from 'interfaces/syncing-statuses';
import { ISlidingFeePlan } from 'api/models/sliding-fee-program.model';
import { IValidationError } from 'hooks/useValidation';
import ErrorTypes from 'state/errorTypes';
import { IAppointmentHistory } from 'api/models/appointment-history.model';

export type EditPatientState = {
    patient?: IPatient;
    editPatientPanelOpen: boolean;
    patientHasChange: boolean;
    showInsuranceHistory: boolean;
    isNewPatient: boolean;
    loading: LoadingStatuses;
    loadingEditPatientPovertyPercentage: LoadingStatuses;
    loadingAnnualIncome: LoadingStatuses;
    validationErrors: IValidationError[];
    saving: SyncStatuses;

    contacts?: IPatientContact[];
    unscheduledTreatmentPlanProcedures?: IPendingChartTreatmentPlanPhaseProcedure[];
    appointmentHistory?: IAppointmentHistory[];
    slidingFeePlan?: ISlidingFeePlan;
    upcomingAppointments?: IUpcomingAppointment[];
    error?: ErrorTypes;
};

const editPatientInitialState: EditPatientState = {
    isNewPatient: false,
    showInsuranceHistory: false,
    patientHasChange: false,
    validationErrors: [],
    loading: LoadingStatus.Idle,
    loadingEditPatientPovertyPercentage: LoadingStatus.Idle,
    loadingAnnualIncome: LoadingStatus.Idle,
    saving: SyncStatus.Idle,
    editPatientPanelOpen: false,
};

export default editPatientInitialState;
