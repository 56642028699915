import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { DailyBalanceReporyQuery, DailyBalanceTransactionType } from 'api/models/daily-balance-report-query.model';
import { ReportType } from 'api/models/embed-report.model';
import { Field } from 'components';
import { useSelector, useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { map, sortBy } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { getAllLocationsOfCareForReports } from 'state/slices/reporting/reporting.actions';
import {
    selectLocationsOfCareFromReporting,
    selectReportProperties,
    selectReportingValidationErrors,
} from 'state/slices/reporting/reporting.selectors';
import { setDailyBalanceProp, toggleDailyBalanceListId } from 'state/slices/reporting/reporting.slice';
import { selectTreatingProviderOptions } from 'state/slices/tenant/providers.slice';
import { classicDateOnly } from 'utils/dateOnly';
import ReportWrapper from '../ReportWrapper';
import { payerOptions } from 'state/slices/tenant/payers.slice';

export default function DailyBalanceReport() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const _payerOptions = useSelector(payerOptions);
    const locationsOfCare = useSelector(selectLocationsOfCareFromReporting);
    const _providerOptions = useSelector(selectTreatingProviderOptions);

    const query = useSelector(selectReportProperties) as DailyBalanceReporyQuery | undefined;

    const errors = useSelector(selectReportingValidationErrors);
    const _onViewReport = () => {
        if (query) dispatch(reportActionLookup[ReportType.DailyBalance]({ tenantId, ...query }));
    };

    useEffect(() => {
        dispatch(getAllLocationsOfCareForReports({ tenantId }));
    }, []);

    const locationsOfCareOptions: IDropdownOption[] = locationsOfCare.map((loc) => ({
        key: loc.id,
        text: loc.displayName,
    }));

    const transactionTypeOptions: IDropdownOption[] = sortBy(
        map(DailyBalanceTransactionType, (type) => ({ key: type, text: type })),
        'text',
    );

    const extraPayarOptions: IDropdownOption[] = [
        {
            key: '-1',
            text: 'Self Pay',
        },
        {
            key: '-2',
            text: 'Sliding Fee',
        },
    ];

    const payerOption = sortBy([..._payerOptions, ...extraPayarOptions], 'text');

    return (
        <ReportWrapper
            validationConfig={[
                { fieldName: 'Start Date', validation: ['required'], value: query?.startDate },
                { fieldName: 'End Date', validation: ['required'], value: query?.endDate },
            ]}
            onViewReport={_onViewReport}
        >
            <Stack tokens={{ childrenGap: 10 }} grow horizontal wrap>
                <Stack.Item>
                    <Field.Date
                        label="Start Date"
                        required
                        value={query?.startDate ? classicDateOnly(query?.startDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setDailyBalanceProp({ path: 'startDate', value: newDate }));
                        }}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="End Date"
                        required
                        value={query?.endDate ? classicDateOnly(query?.endDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setDailyBalanceProp({ path: 'endDate', value: newDate }));
                        }}
                        isReasonable
                        disabled={!query?.startDate}
                        minReasonableErrorMessage="End date must be after start date."
                        minReasonableDate={query?.startDate ? new Date(classicDateOnly(query?.startDate)) : undefined}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                    />
                </Stack.Item>

                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Locations of Care)"
                        label="Locations of Care"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={locationsOfCareOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleDailyBalanceListId({ path: 'locationOfCareIds', id: option.key as string }));
                        }}
                        selectedKey={query?.locationOfCareIds}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Provider)"
                        label="Providers"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={_providerOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleDailyBalanceListId({ path: 'treatingProviderIds', id: option.key as string }));
                        }}
                        selectedKey={query?.treatingProviderIds}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Carriers)"
                        label="Carrier"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={payerOption}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleDailyBalanceListId({ id: option.key as string, path: 'insuranceCarrier' }));
                        }}
                        selectedKey={query?.insuranceCarrier ? query?.insuranceCarrier : []}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Dropdown
                        label="Transaction Type"
                        placeholder="(Select Transaction Type)"
                        options={[{ key: '', text: '(Select Transaction Type)' }, ...transactionTypeOptions]}
                        onChange={(ev, option) => {
                            if (option) {
                                dispatch(
                                    setDailyBalanceProp({
                                        path: 'transactionType',
                                        value: option.key,
                                    }),
                                );
                            }
                        }}
                        selectedKey={query?.transactionType}
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}
