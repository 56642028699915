import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';
import { IEncounterSummaryReportQuery } from 'api/models/encounter-reports.model';
import { Field } from 'components';
import { useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { orderBy, sortBy } from 'lodash';
import ReportWrapper from 'pages/Reporting/ReportWrapper';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { getTenantReportingGroups } from 'state/slices/reporting/reporting-groups/reporting-groups.actions';
import { reportingGroupOptions } from 'state/slices/reporting/reporting-groups/reporting-groups.selectors';
import { getAllLocationsOfCareForReports } from 'state/slices/reporting/reporting.actions';
import {
    selectLocationsOfCareFromReporting,
    selectReportProperties,
    selectReportingValidationErrors,
} from 'state/slices/reporting/reporting.selectors';
import { setEncounterSummaryProp, toggleEncounterSummaryId } from 'state/slices/reporting/reporting.slice';
import { payerOptions } from 'state/slices/tenant/payers.slice';
import { proceduresOptions } from 'state/slices/tenant/procedures.slice';
import { classicDateOnly } from 'utils/dateOnly';
import { useReportingProviderTypeProviderOptions } from './EncounterDetails';

export default function EncountersSummary() {
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const errors = useSelector(selectReportingValidationErrors);

    const locationsOfCare = useSelector(selectLocationsOfCareFromReporting);
    const _payerOptions = useSelector(payerOptions);
    const _procedureOptions = useSelector(proceduresOptions);
    const _reportingGroupOptions = useSelector(reportingGroupOptions);

    const query = useSelector(selectReportProperties) as IEncounterSummaryReportQuery;

    const _providerOptions = useReportingProviderTypeProviderOptions(query?.providerType, query?.provider);

    useEffect(() => {
        dispatch(getAllLocationsOfCareForReports({ tenantId }));
        dispatch(getTenantReportingGroups({ tenantId }));
    }, []);

    const locationsOfCareOptions: IDropdownOption[] = locationsOfCare.map((loc) => ({
        key: loc.id,
        text: loc.displayName,
    }));

    const dateTypeOptions: IDropdownOption[] = [
        { key: 'DOS', text: 'Service' },
        { key: 'DOE', text: 'Entry' },
    ];

    const providerTypeOptions: IDropdownOption[] = useMemo(
        () => [
            { key: '', text: '(Select)' },
            ...orderBy(
                [
                    { key: 'Billing', text: 'Billing Provider' },
                    { key: 'Attending', text: 'Attending Provider' },
                    { key: 'Treating', text: 'Treating Provider' },
                    { key: 'UDS', text: 'UDS Provider' },
                ],
                ['text'],
            ),
        ],
        [],
    );

    const _onViewReport = () => {
        if (query) dispatch(reportActionLookup[ReportType.EncountersSummary]({ tenantId, ...query }));
    };

    const encounterGroupOption: IDropdownOption[] = sortBy(
        [
            { key: 'PublicPatientId', text: 'Patient ID' },
            { key: 'ServiceYear', text: 'Service Year' },
            { key: 'ServiceMonth', text: 'Service Month' },
            { key: 'ServiceDay', text: 'Service Day' },
            { key: 'EntryYear', text: 'Entry Year' },
            { key: 'EntryMonth', text: 'Entry Month' },
            { key: 'EntryDay', text: 'Entry Day' },
            { key: 'CDTCode', text: 'CDT Code' },
            { key: 'TreatingProvider', text: 'Treating Provider' },
            { key: 'BillingProvider', text: 'Billing Provider' },
            { key: 'EncounterProvider', text: 'Attending Provider' },
            { key: 'UDSProvider', text: 'UDS Provider' },
            { key: 'LocationOfCare', text: 'Locations Of Care' },
            { key: 'PrimaryInsCarrier', text: 'Primary Ins. Carrier' },
            { key: 'PayerName', text: 'Payer Name' },
        ],
        'text',
    );

    useEffect(() => {
        if (!query?.dateType) {
            dispatch(setEncounterSummaryProp({ path: 'dateType', value: 'DOS' }));
        }

        dispatch(setEncounterSummaryProp({ path: 'rowGroup', value: 'LocationOfCare' }));
        dispatch(setEncounterSummaryProp({ path: 'rowGroup2', value: 'TreatingProvider' }));
    }, []);

    return (
        <ReportWrapper
            validationConfig={[
                {
                    fieldName: 'Date Type',
                    validation: ['required'],
                    value: query.dateType,
                },
                {
                    fieldName: 'Start Date',
                    validation: ['required'],
                    value: query.startDate,
                },
                {
                    fieldName: 'End Date',
                    validation: ['required'],
                    value: query.endDate,
                },
            ]}
            onViewReport={_onViewReport}
        >
            <Stack grow horizontal wrap tokens={{ childrenGap: 20 }}>
                <Stack.Item>
                    <Dropdown
                        label="Date Type"
                        options={dateTypeOptions}
                        required
                        style={{ minWidth: 150 }}
                        selectedKey={query?.dateType}
                        placeholder="(Select)"
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    setEncounterSummaryProp({
                                        path: 'dateType',
                                        value: option.key as string,
                                    }),
                                );
                        }}
                        errorMessage={getValidationError(errors, 'Date Type') ? 'Date type is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="Start Date"
                        required
                        value={query?.startDate ? classicDateOnly(query?.startDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setEncounterSummaryProp({ path: 'startDate', value: newDate }));
                        }}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="End Date"
                        required
                        value={query?.endDate ? classicDateOnly(query?.endDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setEncounterSummaryProp({ path: 'endDate', value: newDate }));
                        }}
                        isReasonable
                        disabled={!query?.startDate}
                        minReasonableErrorMessage="End date must be after start date."
                        minReasonableDate={query?.startDate ? new Date(classicDateOnly(query?.startDate)) : undefined}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                    />
                </Stack.Item>

                <Stack.Item>
                    <Dropdown
                        label="Provider Type "
                        options={providerTypeOptions}
                        selectedKey={query?.providerType}
                        placeholder="(Select)"
                        style={{ minWidth: 150 }}
                        onChange={(ev, option) => {
                            if (option)
                                dispatch(
                                    setEncounterSummaryProp({
                                        path: 'providerType',
                                        value: option.key as string,
                                    }),
                                );
                        }}
                    />
                </Stack.Item>
            </Stack>

            <Stack grow horizontal wrap tokens={{ childrenGap: 20 }}>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Locations of Care)"
                        label="Locations of Care"
                        multiSelect
                        options={locationsOfCareOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleEncounterSummaryId({ id: option.key as string, path: 'locationOfCare' }));
                        }}
                        selectedKey={query?.locationOfCare}
                        style={{ minWidth: 300 }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Provider)"
                        label="Providers"
                        multiSelect
                        options={_providerOptions}
                        selectedKey={query?.provider}
                        onChange={(ev, option) => {
                            if (option?.key) dispatch(toggleEncounterSummaryId({ id: option.key as string, path: 'provider' }));
                        }}
                        style={{ minWidth: 300 }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Carriers)"
                        label="Carrier"
                        multiSelect
                        options={_payerOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleEncounterSummaryId({ id: option.key as string, path: 'insuranceCarrier' }));
                        }}
                        selectedKey={query?.insuranceCarrier}
                        style={{ minWidth: 300 }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Procedures)"
                        label="Procedures"
                        options={_procedureOptions}
                        multiSelect
                        styles={{ callout: { maxWidth: 475 } }}
                        maxResults={100}
                        onChange={(ev, option) => {
                            if (option?.key) dispatch(toggleEncounterSummaryId({ id: option.key as string, path: 'procedure' }));
                        }}
                        selectedKey={query?.procedure}
                        style={{ minWidth: 300 }}
                    />
                </Stack.Item>
            </Stack>
            <Stack grow horizontal wrap tokens={{ childrenGap: 20 }}>
                <Stack.Item>
                    <Dropdown
                        label=" Page Group"
                        options={[{ key: '', text: '(Select)' }, ..._reportingGroupOptions, ...encounterGroupOption]}
                        selectedKey={query?.pageGroup}
                        placeholder="(Select)"
                        onChange={(ev, option) => {
                            if (option)
                                dispatch(
                                    setEncounterSummaryProp({
                                        path: 'pageGroup',
                                        value: option.key as string,
                                    }),
                                );
                        }}
                        style={{ minWidth: 300 }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Dropdown
                        label="Row Group 1"
                        options={[{ key: '', text: '(Select)' }, ..._reportingGroupOptions, ...encounterGroupOption]}
                        selectedKey={query?.rowGroup}
                        placeholder="(Select)"
                        onChange={(ev, option) => {
                            if (option)
                                dispatch(
                                    setEncounterSummaryProp({
                                        path: 'rowGroup',
                                        value: option.key as string,
                                    }),
                                );
                        }}
                        style={{ minWidth: 300 }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Dropdown
                        label="Row Group 2"
                        options={[{ key: '', text: '(Select Group Column)' }, ...encounterGroupOption]}
                        selectedKey={query?.rowGroup2}
                        placeholder="(Select)"
                        onChange={(ev, option) => {
                            if (option)
                                dispatch(
                                    setEncounterSummaryProp({
                                        path: 'rowGroup2',
                                        value: option.key as string,
                                    }),
                                );
                        }}
                        style={{ minWidth: 300 }}
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}
