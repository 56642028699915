import { Dropdown, IDropdownOption, IconButton, Stack, TextField, TooltipHost } from '@fluentui/react';
import { BulkPatientStatementReportQuery } from 'api/models/bulk-patient-statement';
import { ReportType } from 'api/models/embed-report.model';
import { Field } from 'components';
import { useSelector, useTenantId } from 'hooks';
import { IValidationError, getValidationError, validateErrorMessage } from 'hooks/useValidation';
import { sortBy } from 'lodash';
import ReportWrapper from 'pages/Reporting/ReportWrapper';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { getAllLocationsOfCareForReports } from 'state/slices/reporting/reporting.actions';
import {
    selectLocationsOfCareFromReporting,
    selectReportProperties,
    selectReportingValidationErrors,
} from 'state/slices/reporting/reporting.selectors';
import { setBulkPatientStatementProp, toggleBulkPatientStatementListId } from 'state/slices/reporting/reporting.slice';
import { payerOptions } from 'state/slices/tenant/payers.slice';
import { classicDateOnly } from 'utils/dateOnly';

function BulkPatientStatement() {
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const errors = useSelector(selectReportingValidationErrors);

    const errorsMessage = (error: IValidationError | undefined) => {
        if (!error) return undefined;
        if (error.errorTypes.includes('required')) {
            return 'To Patient Last Name Is Required';
        }
        if (error.errorTypes.includes('letterComparison')) {
            return 'Invalid Letter';
        }
    };
    const locationsOfCare = useSelector(selectLocationsOfCareFromReporting);

    const _payerOptions = useSelector(payerOptions);

    const query = useSelector(selectReportProperties) as BulkPatientStatementReportQuery;

    useEffect(() => {
        dispatch(getAllLocationsOfCareForReports({ tenantId }));
    }, []);

    const locationsOfCareOptions: IDropdownOption[] = locationsOfCare.map((loc) => ({
        key: loc.id,
        text: loc.displayName,
    }));

    const dateTypeOptions: IDropdownOption[] = [
        { key: '0', text: 'Full patient balance' },
        { key: 1, text: 'Patient balance within date range' },
    ];

    const _onViewReport = () => {
        if (query) dispatch(reportActionLookup[ReportType.PatientStatementForBulk]({ tenantId, ...query }));
    };

    const extraPayarOptions: IDropdownOption[] = [
        {
            key: '-1',
            text: 'Self Pay',
        },
        {
            key: '-2',
            text: 'Sliding Fee',
        },
    ];

    const payerOption = sortBy([..._payerOptions, ...extraPayarOptions], 'text');

    useEffect(() => {
        if (!query?.showAllOpenBalances) {
            dispatch(setBulkPatientStatementProp({ path: 'showAllOpenBalances', value: '0' }));
        }
        if (!query.alphaFrom) {
            dispatch(setBulkPatientStatementProp({ path: 'alphaFrom', value: 'A' }));
        }
        if (!query.alphaTo) {
            dispatch(setBulkPatientStatementProp({ path: 'alphaTo', value: 'Z' }));
        }
    }, []);
    const validTextInput = (value?: string): boolean => {
        const res = value ? /^(?!\s)[a-zA-Z\s-']*$/.test(value) : true;
        return res;
    };

    return (
        <ReportWrapper
            validationConfig={[
                {
                    fieldName: 'Start Date',
                    validation: ['required'],
                    value: query?.startDate,
                },
                {
                    fieldName: 'End Date',
                    validation: ['required'],
                    value: query?.endDate,
                },
                {
                    fieldName: 'Alpha From',
                    validation: ['required', 'letterComparison'],
                    value: query?.alphaFrom,
                },
                {
                    fieldName: 'Alpha To',
                    validation: ['required', 'letterComparison'],
                    value: query?.alphaTo,
                    itemOptions: { alphaFrom: query?.alphaFrom },
                },
            ]}
            onViewReport={_onViewReport}
        >
            <Stack grow horizontal wrap tokens={{ childrenGap: 20 }}>
                <Stack.Item>
                    <Field.Date
                        label="Start Date"
                        required
                        isReasonable
                        hasDatePicker
                        value={query?.startDate ? classicDateOnly(query?.startDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setBulkPatientStatementProp({ path: 'startDate', value: newDate }));
                        }}
                        errorMessage={getValidationError(errors, 'Start Date') ? 'Start Date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="End Date"
                        required
                        isReasonable
                        hasDatePicker
                        value={query?.endDate ? classicDateOnly(query?.endDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setBulkPatientStatementProp({ path: 'endDate', value: newDate }));
                        }}
                        errorMessage={getValidationError(errors, 'End Date') ? 'End Date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Dropdown
                        label="Patient Balance"
                        options={dateTypeOptions}
                        selectedKey={query?.showAllOpenBalances}
                        required
                        style={{ minWidth: 250 }}
                        placeholder="(Select)"
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    setBulkPatientStatementProp({
                                        path: 'showAllOpenBalances',
                                        value: option.key,
                                    }),
                                );
                        }}
                        errorMessage={getValidationError(errors, 'Patient Balance') ? 'Patient Balance is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Locations of Care)"
                        label="Locations of Care"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={locationsOfCareOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleBulkPatientStatementListId({ id: option.key as string, path: 'locationOfCareIds' }),
                                );
                        }}
                        selectedKey={query?.locationOfCareIds}
                    />
                </Stack.Item>

                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Carriers)"
                        label="Carrier"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={payerOption}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleBulkPatientStatementListId({ id: option.key as string, path: 'payerIds' }));
                        }}
                        selectedKey={query?.payerIds}
                    />
                </Stack.Item>
            </Stack>

            <Stack grow horizontal tokens={{ childrenGap: 20 }}>
                <Stack horizontal>
                    <Stack verticalAlign="center">
                        <TooltipHost content="Filtered to display FROM patients last names that begin with the inputted character. If nothing is inputted, it will default FROM 'A'. i.e. Filter all patients with the last names from 'A' to 'G', input 'A' in the 'From Patient Last Name' field.">
                            <IconButton iconProps={{ iconName: 'Info' }} />
                        </TooltipHost>
                    </Stack>
                    <Stack.Item>
                        <TextField
                            value={query?.alphaFrom}
                            style={{ minWidth: 300 }}
                            maxLength={1}
                            required
                            label="From Patient Last Name (First Character)"
                            onChange={(ev, option) => {
                                if (validTextInput(option))
                                    dispatch(
                                        setBulkPatientStatementProp({
                                            path: 'alphaFrom',
                                            value: option?.toLocaleUpperCase(),
                                        }),
                                    );
                            }}
                            errorMessage={
                                getValidationError(errors, 'Alpha From') ? 'From Patient Last Name is required.' : undefined
                            }
                        />
                    </Stack.Item>
                </Stack>
                <Stack horizontal>
                    <Stack verticalAlign="center">
                        <TooltipHost
                            content="Filtered to display TO patients last names that begin with the inputted character. If nothing is inputted, it will default TO 'Z'
i.e. Filter all patients with the last names from 'A' to 'G', input 'G' in the 'To Patient Last Name' field."
                        >
                            <IconButton iconProps={{ iconName: 'Info' }} />
                        </TooltipHost>
                    </Stack>

                    <TextField
                        label="To Patient Last Name (First Character)"
                        value={query?.alphaTo}
                        maxLength={1}
                        required
                        style={{ minWidth: 300 }}
                        onChange={(ev, option) => {
                            if (validTextInput(option))
                                dispatch(
                                    setBulkPatientStatementProp({
                                        path: 'alphaTo',
                                        value: option?.toLocaleUpperCase(),
                                    }),
                                );
                        }}
                        errorMessage={errorsMessage(getValidationError(errors, 'Alpha To'))}
                    />
                </Stack>
            </Stack>
        </ReportWrapper>
    );
}

export default BulkPatientStatement;
