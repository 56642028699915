import { createAsyncThunk, createSlice, Dictionary, PayloadAction } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IBatch } from 'api/models/batch.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { LookupState } from 'interfaces/lookup-state';
import { map } from 'lodash';
import { createSelector } from 'reselect';
import { AppThunk, RootState } from 'state/store';
import dateOnly from 'utils/dateOnly';
import { v4 as uuid } from 'uuid';

type ModalState = 'Create' | 'Update' | 'Close' | 'Reopen' | 'Hard-Close';
type BatchesState = LookupState<IBatch> & {
    hardCloseDate?: string;
    hardCloseDateLoading: LoadingStatus;
    panelOpen: boolean;
    selectedBatch?: IBatch;
    toggleActiveBatches: boolean;
    modalState?: ModalState;
    batchesFilters: BatchesFilters;
    disableBatchesFilters: boolean;
};

type BatchesFilters = Record<string, string | undefined>;

const initialState: BatchesState = {
    data: {},

    hardCloseDateLoading: LoadingStatus.Idle,
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    panelOpen: false,
    toggleActiveBatches: false,
    disableBatchesFilters: false,
    batchesFilters: {},
};

export const getBatches = createAsyncThunk<Dictionary<IBatch>, string>('fetchBatches', async (tenantId) => {
    const res = await dentalApi.getBatches(tenantId);
    return res.data;
});

export const getHardCloseThroughDate = createAsyncThunk<string, string>('fetchHardCloseThroughDate', async (tenantId) => {
    const res = await dentalApi.getHardCloseThroughDate(tenantId);
    return res.data;
});

export const createBatch = createAsyncThunk<IBatch, { tenantId: string; batch: IBatch }>(
    'createBatch',
    async ({ tenantId, batch }) => {
        const res = await dentalApi.createBatch(tenantId, batch);
        return res.data;
    },
);

export const updateBatch = createAsyncThunk<IBatch, { tenantId: string; batch: IBatch }>(
    'updateBatch',
    async ({ tenantId, batch }) => {
        const res = await dentalApi.updateBatch(tenantId, batch);
        return res.data;
    },
);

const batchSlice = createSlice({
    name: 'batches',
    initialState,
    reducers: {
        cleanupHardCloseDate: (state) => {
            state.hardCloseDate = undefined;
            state.hardCloseDateLoading = LoadingStatus.Idle;
        },
        setBatchPanelOpen: (state, action: PayloadAction<boolean>) => {
            state.panelOpen = action.payload;
        },
        createNewBatch: (state, action: PayloadAction<string>) => {
            state.selectedBatch = {
                id: '',
                isDeleted: false,
                dateOfEntry: new Date().toISOString(),
                displayName: action.payload,
                status: 'Open',
            };
            state.modalState = 'Create';
        },
        setSelectedBatch: (state, action: PayloadAction<{ batch?: IBatch; modalState?: ModalState }>) => {
            const { batch, modalState } = action.payload;
            state.selectedBatch = batch;
            state.modalState = modalState;
        },
        setBatchData: (state, action: PayloadAction<IBatch>) => {
            const batch = action.payload;
            state.data[batch.id] = batch;
        },
        setBatchIdsHardClosed: (
            state,
            action: PayloadAction<{ currentTenant: string; tenantId: string; batchIds?: string[] }>,
        ) => {
            const batchIds = action.payload.batchIds;
            const { tenantId, currentTenant } = action.payload;

            const newBatches = { ...state.data };
            if (tenantId === currentTenant) {
                batchIds?.forEach((batchId) => {
                    if (newBatches[batchId]) (newBatches[batchId] as IBatch).status = 'Hard-Closed';
                });

                //Prevent too many state updates by only updating the state once;
                state.data = newBatches;
            }
        },
        editSelectedBatch: (state, action: PayloadAction<{ path: keyof IBatch; value: unknown }>) => {
            const { path, value } = action.payload;
            if (state.selectedBatch) {
                state.selectedBatch = { ...state.selectedBatch, [path]: value };
            }
        },
        toggleActiveBatches: (state) => {
            state.toggleActiveBatches = !state.toggleActiveBatches;
        },
        setBatchFilters: (state, { payload }: PayloadAction<{ path: string; value: string | undefined }>) => {
            const { path, value } = payload;
            state.batchesFilters[path] = value;
        },
        setDisableBatchesFilters: (state, { payload }: PayloadAction<boolean>) => {
            state.disableBatchesFilters = payload;
        },
        cleanupBatchFilters: (state) => {
            state.batchesFilters = {};
            state.disableBatchesFilters = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBatches.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getBatches.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload;
            })

            .addCase(createBatch.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(createBatch.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data[action.payload.id] = action.payload;
                state.selectedBatch = undefined;
                state.modalState = undefined;
            })

            .addCase(updateBatch.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(updateBatch.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data[action.payload.id] = action.payload;

                state.selectedBatch = undefined;
                state.modalState = undefined;
            })
            .addCase(getHardCloseThroughDate.pending, (state) => {
                state.hardCloseDateLoading = LoadingStatus.Pending;
            })
            .addCase(getHardCloseThroughDate.fulfilled, (state, action) => {
                state.hardCloseDateLoading = LoadingStatus.Completed;
                state.hardCloseDate = action.payload;
            })
            .addCase(getHardCloseThroughDate.rejected, (state) => {
                state.hardCloseDateLoading = LoadingStatus.Failed;
            });
    },
});

export const selectBatchPanelOpen = (state: RootState): boolean => state.tenant.batches.panelOpen;
export const selectBatches = (state: RootState): BatchesState => state.tenant.batches;
export const selectBatchesFilters = createSelector(selectBatches, ({ batchesFilters }) => batchesFilters);
export const selectHardCloseThroughDate = createSelector(selectBatches, ({ hardCloseDate }) => hardCloseDate);
export const selectHardCloseThroughDateLoading = createSelector(
    selectBatches,
    ({ hardCloseDateLoading }) => hardCloseDateLoading,
);
export const selectBatchesFiltersDisabled = createSelector(selectBatches, ({ disableBatchesFilters }) => disableBatchesFilters);
export const selectBatchesLoading = createSelector(selectBatches, ({ loading }) => loading === LoadingStatus.Pending);
export const selectBatchesAsList = createSelector(selectBatches, ({ data }) => {
    return map(data).filter((batch) => batch !== undefined) as IBatch[];
});
export const selectBatchesData = createSelector(selectBatches, ({ data }) => {
    return data;
});
export const selectSelectedBatch = (state: RootState): IBatch | undefined => state.tenant.batches.selectedBatch;
export const selectModalState = createSelector(selectBatches, ({ modalState }) => modalState);

export const toggleActiveBatches = (state: RootState): boolean => state.tenant.batches.toggleActiveBatches;

export const filterBatchList = createSelector(
    selectBatchesAsList,
    toggleActiveBatches,
    selectBatchesFilters,
    (batches, toggleActiveBatches, filters) => {
        return batches.filter(
            (batch) =>
                batch.createdBy !== 'data-exchange-service-id' &&
                batch.createdBy !== 'Billing System' &&
                (filters?.dateOfEntry ? dateOnly(batch.dateOfEntry) === dateOnly(filters.dateOfEntry) : true) &&
                (!toggleActiveBatches ? batch.status === 'Open' : true),
        );
    },
);

export const saveUpdateBatch =
    (tenantId: string): AppThunk<void> =>
        (dispatch, getState) => {
            const batch = getState().tenant.batches.selectedBatch;

            if (batch) {
                if (batch.id) {
                    dispatch(updateBatch({ tenantId, batch }));
                } else {
                    dispatch(createBatch({ tenantId, batch: { ...batch, id: uuid() } }));
                }
            }
        };

export const { actions: batchActions, reducer } = batchSlice;

export default reducer;
