import { Label, MessageBar, ScrollablePane, SelectionMode, Stack } from '@fluentui/react';
import { ExpandableDetailsList } from 'components';
import ClaimHistoryDetailsList from './ClaimHistoryDetailsList';
import ClaimsHistoryHeader from './ClaimsHistoryHeader';
import { useSelector } from 'hooks';
import {
    selectEncounterClaimsSummaryClaimHistory,
    selectEncounterClaimsSummaryLoadingStatus,
} from 'state/slices/ledger/claim-history/claim-history.selectors';
import { IClaimHistory } from 'api/models/encounter-claim-summary.model';
import { format } from 'date-fns';
import { usdCurrencyFormatter } from 'utils';
import { selectPayerData } from 'state/slices/tenant/payers.slice';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { classicDateOnly } from 'utils/dateOnly';

export default function ClaimHistoryList() {
    const loading = useSelector(selectEncounterClaimsSummaryLoadingStatus) === LoadingStatus.Pending;
    const claimsHistoryItems = useSelector(selectEncounterClaimsSummaryClaimHistory);
    const payerLookup = useSelector(selectPayerData);

    return (
        <Stack grow>
            <ClaimsHistoryHeader heading="Claim History" />
            {!loading && !claimsHistoryItems.length ? (
                <MessageBar>No claim history items to show.</MessageBar>
            ) : (
                <div style={{ display: 'flex', flex: 1, position: 'relative' }}>
                    <ScrollablePane>
                        <ExpandableDetailsList<IClaimHistory>
                            columns={[
                                {
                                    key: 'date',
                                    fieldName: 'date',
                                    minWidth: 100,
                                    maxWidth: 120,
                                    name: 'Date/Time',
                                    onRender: (item) => {
                                        return item?.date ? classicDateOnly(item.date) : '';
                                    },
                                },
                                {
                                    key: 'payerSource',
                                    fieldName: 'payerSource',
                                    minWidth: 100,
                                    maxWidth: 120,
                                    name: 'Payer Source',
                                    onRender: (item) => {
                                        return item?.payerSource ? payerLookup[item?.payerSource]?.name ?? '' : '';
                                    },
                                },
                                { key: 'claimNumber', fieldName: 'claimNumber', minWidth: 80, maxWidth: 120, name: 'Claim #' },
                                { key: 'status', fieldName: 'status', minWidth: 80, maxWidth: 120, name: 'Status' },
                                {
                                    key: 'amount',
                                    fieldName: 'amount',
                                    minWidth: 80,
                                    maxWidth: 100,
                                    name: 'Claim Amount',
                                    onRender: (item) => {
                                        return usdCurrencyFormatter.format(item?.amount ?? 0);
                                    },
                                },
                                {
                                    key: 'paid',
                                    fieldName: 'paid',
                                    minWidth: 80,
                                    maxWidth: 100,
                                    name: 'Paid',
                                    onRender: (item) => {
                                        return usdCurrencyFormatter.format(item?.paid ?? 0);
                                    },
                                },
                                {
                                    key: 'ediMessage',
                                    minWidth: 80,
                                    maxWidth: 120,
                                    name: 'EDI Messages',
                                    fieldName: 'ediMessage',
                                    onRender: (item) => <span title={item?.ediMessage ?? ''}>{item?.ediMessage ?? ''}</span>,
                                },
                            ]}
                            items={claimsHistoryItems}
                            showGrid
                            compact
                            enableShimmer={loading}
                            shimmerLines={2}
                            allowMultipleRowsExpanded
                            sortColumns={['date']}
                            initialSortDirection={['asc']}
                            sortOnMount
                            canExpandRow={(item) => !!item.procedureHistories?.length}
                            onRenderExpandedRowContent={(props) => (
                                <Stack styles={{ root: { paddingLeft: 10 } }}>
                                    <ClaimHistoryDetailsList items={props?.item?.procedureHistories ?? []} />
                                </Stack>
                            )}
                            selectionMode={SelectionMode.none}
                        />
                    </ScrollablePane>
                </div>
            )}
        </Stack>
    );
}
