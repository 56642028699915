import { useSelector } from 'react-redux';
import { payersList, selectPayers, selectPayersLoading } from 'state/slices/tenant/payers.slice';
import { selectTenantPayersData, tenantPayersList } from 'state/slices/tenant/tenant-payers.slice';

function usePayers() {
    const _payers = useSelector(selectPayers);
    const _payersLoading = useSelector(selectPayersLoading);
    const _payersList = useSelector(payersList);
    const _tenantPayers = useSelector(selectTenantPayersData);
    const _tenantPayersList = useSelector(tenantPayersList);

    return {
        payers: _payers,
        payersLoading: _payersLoading,
        payersList: _payersList,
        tenantPayers: _tenantPayers,
        tenantPayersList: _tenantPayersList,
    };
}

export default usePayers;
