import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';
import { FinancialSummaryReportQuery } from 'api/models/financial-summary-report-query.model';
import { Field } from 'components';
import { useSelector, useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { sortBy } from 'lodash';
import ReportWrapper from 'pages/Reporting/ReportWrapper';
import { useDispatch } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import {
    selectLocationsOfCareFromReporting,
    selectReportingValidationErrors,
    selectReportProperties,
} from 'state/slices/reporting/reporting.selectors';
import { setFinancialTransactionDetailsProp, toggleFinancialTransactionDetailsId } from 'state/slices/reporting/reporting.slice';
import { payerOptions } from 'state/slices/tenant/payers.slice';
import { proceduresOptions } from 'state/slices/tenant/procedures.slice';
import { selectTreatingProviderOptions } from 'state/slices/tenant/providers.slice';
import { classicDateOnly } from 'utils/dateOnly';
import { FinancialTransactionDetailsReportQuery } from './model';
const tokens = { childrenGap: 20 };
const dropdownStyles = { minWidth: 200 };

// setFinancialTransactionDetailsProp,
// toggleFinancialTransactionDetailsId
function FinancialTransactionDetails() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const errors = useSelector(selectReportingValidationErrors);

    const locationsOfCare = useSelector(selectLocationsOfCareFromReporting);
    const _providerOptions = useSelector(selectTreatingProviderOptions);
    const _payerOptions = useSelector(payerOptions);
    const _procedureOptions = useSelector(proceduresOptions);
    const _locationsOfCareOptions: IDropdownOption[] = locationsOfCare.map((loc) => ({
        key: loc.id,
        text: loc.displayName,
    }));

    const query = useSelector(selectReportProperties) as FinancialTransactionDetailsReportQuery;

    const dateTypeOptions: IDropdownOption[] = [
        { key: 'DOS', text: 'Service' },
        { key: 'DOE', text: 'Entry' },
    ];
    const extraPayarOptions: IDropdownOption[] = [
        {
            key: '-1',
            text: 'Self Pay',
        },
        {
            key: '-2',
            text: 'Sliding Fee',
        },
    ];

    const payerOption = sortBy([..._payerOptions, ...extraPayarOptions], 'text');
    const _onViewReport = () => {
        if (query) dispatch(reportActionLookup[ReportType.FinancialTransactionDetails]({ tenantId, ...query }));
    };
    return (
        <ReportWrapper
            onViewReport={_onViewReport}
            validationConfig={[
                {
                    fieldName: 'Date Type',
                    validation: ['required'],
                    value: query.dateType,
                },
                {
                    fieldName: 'Start Date',
                    validation: ['required'],
                    value: query?.startDate,
                },
                {
                    fieldName: 'End Date',
                    validation: ['required'],
                    value: query?.endDate,
                },
            ]}
        >
            <Stack horizontal grow wrap tokens={tokens}>
                    <Dropdown
                        label="Date Type"
                        options={dateTypeOptions}
                        required
                        selectedKey={query?.dateType}
                        placeholder="(Select)"
                        style={{ minWidth: 150 }}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    setFinancialTransactionDetailsProp({
                                        path: 'dateType',
                                        value: option.key as string,
                                    }),
                                );
                        }}
                        errorMessage={getValidationError(errors, 'Date Type') ? 'Date type is required.' : undefined}
                    />
                <Stack.Item>
                    <Field.Date
                        value={query?.startDate ? classicDateOnly(query.startDate, 'MM/dd/yyyy') : ''}
                        label="Start Date"
                        hasDatePicker
                        required
                        onChange={(_, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setFinancialTransactionDetailsProp({ path: 'startDate', value: newDate }));
                        }}
                        errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        value={query?.endDate ? classicDateOnly(query.endDate, 'MM/dd/yyyy') : ''}
                        label="End Date"
                        hasDatePicker
                        required
                        onChange={(_, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setFinancialTransactionDetailsProp({ path: 'endDate', value: newDate }));
                        }}
                        disabled={!query?.startDate}
                        isReasonable
                        minReasonableErrorMessage="End date must be after start date."
                        minReasonableDate={query?.startDate ? new Date(classicDateOnly(query?.startDate)) : undefined}
                        errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        label="Providers"
                        placeholder="(Select Provider)"
                        style={dropdownStyles}
                        options={_providerOptions}
                        multiSelect
                        onChange={(_, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleFinancialTransactionDetailsId({
                                        id: option.key as string,
                                        path: 'treatingProviderIds',
                                    }),
                                );
                        }}
                        selectedKey={query?.treatingProviderIds ? query.treatingProviderIds : []}
                    />
                </Stack.Item>
            </Stack>
            <Stack horizontal grow wrap tokens={tokens}>
                <Stack.Item>
                    <Field.SearchCombo
                        label="Locations of Care"
                        placeholder="(Select Locations of Care)"
                        style={dropdownStyles}
                        options={_locationsOfCareOptions}
                        multiSelect
                        onChange={(_, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleFinancialTransactionDetailsId({ id: option.key as string, path: 'locationOfCareIds' }),
                                );
                        }}
                        selectedKey={query?.locationOfCareIds ? query.locationOfCareIds : []}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        label="Carrier"
                        placeholder="(Select Carriers)"
                        style={dropdownStyles}
                        options={payerOption}
                        multiSelect
                        onChange={(_, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleFinancialTransactionDetailsId({
                                        id: option.key as string,
                                        path: 'insuranceCarrierIds',
                                    }),
                                );
                        }}
                        selectedKey={query?.insuranceCarrierIds ? query.insuranceCarrierIds : []}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        label="Procedure"
                        placeholder="(Select Procedures)"
                        style={dropdownStyles}
                        styles={{ callout: { maxWidth: 475 } }}
                        maxResults={100}
                        options={_procedureOptions}
                        multiSelect
                        onChange={(_, option) => {
                            if (option?.key)
                                dispatch(toggleFinancialTransactionDetailsId({ id: option.key as string, path: 'procedureIds' }));
                        }}
                        selectedKey={query?.procedureIds ? query.procedureIds : []}
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}

export default FinancialTransactionDetails;
