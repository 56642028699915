import { Selection, ISelection, IObjectWithKey, ConstrainMode, ScrollablePane } from '@fluentui/react';
import { ExpandableDetailsList, SortableDetailsList } from 'components';
import { useSelector } from 'hooks';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setWorkListSelectedItems } from 'state/slices/admin-huddle/worklists/worklist.slice';
import { selectWorkListData, selectWorkListLoading } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { LoadingStatus } from 'interfaces/loading-statuses';
import EncounterWorkListProceduresDetailsList from './EncounterProceduresDetailsList';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import WorkListDetailListRow from '../WorkListDetailListRow';
import { WorkListDetailsListDataForChildren } from '../WorkListBuilder';
import { IBillingProcedure } from 'api/models/billing-procedure.model';

function EncountersDetailsList({
    columns,
    expandedRowColumns,
    selectionMode,
    sortColumns,
    initialSortDirection,
}: WorkListDetailsListDataForChildren<IWorkListEncounterView, IBillingProcedure>): JSX.Element {
    const encounters = useSelector(selectWorkListData<IWorkListEncounterView>);
    const loading = useSelector(selectWorkListLoading) === LoadingStatus.Pending;

    const encounterSelection = useMemo(() => {
        return new Selection({
            onSelectionChanged: () => {
                const selectedEncounters = _getSelectionDetails() as IWorkListEncounterView[];
                dispatch(setWorkListSelectedItems(selectedEncounters));
            },
            getKey: (item) => item?.encounter?.id ?? item.id,
            items: encounters,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function _getSelectionDetails() {
        return encounterSelection.getSelection() as IWorkListEncounterView[];
    }

    const dispatch = useDispatch();

    return (
        <div style={{ position: 'relative', flex: 1, display: 'flex' }}>
            <ScrollablePane>
                {expandedRowColumns ? (
                    <ExpandableDetailsList<IWorkListEncounterView>
                        selectionMode={selectionMode}
                        sortOnMount
                        stickyHeader
                        constrainMode={ConstrainMode.unconstrained}
                        initialSortDirection={initialSortDirection}
                        sortColumns={sortColumns}
                        items={encounters}
                        allowMultipleRowsExpanded
                        shimmerLines={3}
                        setKey="id"
                        canExpandAll
                        onRenderRow={(props) => {
                            if (!props) return null;
                            return <WorkListDetailListRow {...props} id={props.item?.encounter?.id} />;
                        }}
                        onRenderExpandedRowContent={(props) => (
                            <EncounterWorkListProceduresDetailsList columns={expandedRowColumns} items={props?.item.procedures} />
                        )}
                        enableShimmer={loading}
                        columns={columns}
                        selection={encounterSelection as unknown as ISelection<IObjectWithKey>}
                        showGrid
                    />
                ) : (
                    <SortableDetailsList<IWorkListEncounterView>
                        selectionMode={selectionMode}
                        sortOnMount
                        stickyHeader
                        constrainMode={ConstrainMode.unconstrained}
                        initialSortDirection={initialSortDirection}
                        sortColumns={sortColumns}
                        items={encounters}
                        shimmerLines={3}
                        setKey="id"
                        onRenderRow={(props) => {
                            if (!props) return null;
                            return <WorkListDetailListRow {...props} id={props.item?.encounter?.id} />;
                        }}
                        enableShimmer={loading}
                        columns={columns}
                        selection={encounterSelection as unknown as ISelection<IObjectWithKey>}
                        showGrid
                    />
                )}
            </ScrollablePane>
        </div>
    );
}

export default EncountersDetailsList;
