import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { ChargesAndPaymentsGroup, ChargesAndPaymentsReportQuery } from 'api/models/charges-and-payments-report-query.model';
import { ReportType } from 'api/models/embed-report.model';
import { Field } from 'components';
import { useSelector, useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { map, sortBy } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { getAllLocationsOfCareForReports } from 'state/slices/reporting/reporting.actions';
import {
    selectLocationsOfCareFromReporting,
    selectReportProperties,
    selectReportingValidationErrors,
} from 'state/slices/reporting/reporting.selectors';
import { setChartAndPaymentsProp, toggleChargeAndPaymentsListId } from 'state/slices/reporting/reporting.slice';
import { payerOptions } from 'state/slices/tenant/payers.slice';
import { proceduresOptions } from 'state/slices/tenant/procedures.slice';
import { selectTreatingProviderOptions } from 'state/slices/tenant/providers.slice';
import { classicDateOnly } from 'utils/dateOnly';
import ReportWrapper from '../../ReportWrapper';
import { reportingGroupOptions } from 'state/slices/reporting/reporting-groups/reporting-groups.selectors';
import { getTenantReportingGroups } from 'state/slices/reporting/reporting-groups/reporting-groups.actions';

type Props = {
    type: ReportType.ChargeAndPaymentDetails | ReportType.ChargeAndPaymentSummary;
};

export default function ChargeAndPaymentsSummaryDetails({ type }: Props) {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const locationsOfCare = useSelector(selectLocationsOfCareFromReporting);
    const _providerOptions = useSelector(selectTreatingProviderOptions);
    const _payerOptions = useSelector(payerOptions);
    const _procedureOptions = useSelector(proceduresOptions);
    const _groupByOptions = useSelector(reportingGroupOptions);
    const query = useSelector(selectReportProperties) as ChargesAndPaymentsReportQuery | undefined;

    const errors = useSelector(selectReportingValidationErrors);

    useEffect(() => {
        dispatch(getAllLocationsOfCareForReports({ tenantId }));
        dispatch(getTenantReportingGroups({ tenantId }));
    }, []);

    const locationsOfCareOptions: IDropdownOption[] = locationsOfCare.map((loc) => ({
        key: loc.id,
        text: loc.displayName,
    }));

    const adjustmentDetailsGroupOptions: IDropdownOption[] = sortBy(
        map(ChargesAndPaymentsGroup, (group) => ({
            key: group,
            text: group,
        })),
        'text',
    );

    const onViewReport = () => {
        dispatch(reportActionLookup[type]({ tenantId, ...query }));
    };

    const extraPayarOptions: IDropdownOption[] = [
        {
            key: '-1',
            text: 'Self Pay',
        },
        {
            key: '-2',
            text: 'Sliding Fee',
        },
    ];

    const payerOption = sortBy([..._payerOptions, ...extraPayarOptions], 'text');

    return (
        <ReportWrapper
            validationConfig={[
                { fieldName: 'Start Date', validation: ['required'], value: query?.startDate },
                { fieldName: 'End Date', validation: ['required'], value: query?.endDate },
            ]}
            onViewReport={onViewReport}
        >
            <Stack grow horizontal wrap tokens={{ childrenGap: 20 }}>
                <Stack.Item>
                    <Field.Date
                        label="Start Date"
                        required
                        hasDatePicker
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setChartAndPaymentsProp({ path: 'startDate', value: newDate }));
                        }}
                        value={query?.startDate ? classicDateOnly(query?.startDate, 'MM/dd/yyyy') : ''}
                        errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="End Date"
                        required
                        isReasonable
                        hasDatePicker
                        value={query?.endDate ? classicDateOnly(query?.endDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setChartAndPaymentsProp({ path: 'endDate', value: newDate }));
                        }}
                        disabled={!query?.startDate}
                        minReasonableErrorMessage="End date must be after start date."
                        minReasonableDate={query?.startDate ? new Date(classicDateOnly(query?.startDate)) : undefined}
                        errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Provider)"
                        label="Providers"
                        multiSelect
                        maxResults={100}
                        style={{ minWidth: 300 }}
                        options={_providerOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleChargeAndPaymentsListId({ id: option.key as string, path: 'treatingProviderIds' }),
                                );
                        }}
                        selectedKey={query?.treatingProviderIds}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Locations of Care)"
                        label="Locations of Care"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={locationsOfCareOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleChargeAndPaymentsListId({ id: option.key as string, path: 'locationOfCareIds' }));
                        }}
                        selectedKey={query?.locationOfCareIds}
                    />
                </Stack.Item>
            </Stack>

            <Stack grow horizontal wrap tokens={{ childrenGap: 20 }}>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Carriers)"
                        label="Carrier"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={payerOption}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleChargeAndPaymentsListId({ id: option.key as string, path: 'insuranceCarrierIds' }),
                                );
                        }}
                        selectedKey={query?.insuranceCarrierIds}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Procedures)"
                        label="Procedures"
                        style={{ minWidth: 300 }}
                        styles={{ callout: { maxWidth: 475 } }}
                        options={_procedureOptions}
                        multiSelect
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleChargeAndPaymentsListId({ id: option.key as string, path: 'procedureIds' }));
                        }}
                        selectedKey={query?.procedureIds}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Dropdown
                        label="Group Column 1"
                        placeholder="(Select Group Column)"
                        options={[
                            { key: '', text: '(Select Group Column)' },
                            ..._groupByOptions,
                            ...adjustmentDetailsGroupOptions,
                        ]}
                        onChange={(ev, option) => {
                            if (option) {
                                dispatch(
                                    setChartAndPaymentsProp({
                                        path: 'groupCol1',
                                        value: option.key,
                                    }),
                                );
                            }
                        }}
                        style={{ minWidth: 300 }}
                        selectedKey={query?.groupCol1}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Dropdown
                        label="Group Column 2"
                        placeholder="(Select Group Column)"
                        options={[{ key: '', text: '(Select Group Column)' }, ...adjustmentDetailsGroupOptions]}
                        onChange={(ev, option) => {
                            if (option) {
                                dispatch(
                                    setChartAndPaymentsProp({
                                        path: 'groupCol2',
                                        value: option.key,
                                    }),
                                );
                            }
                        }}
                        style={{ minWidth: 300 }}
                        selectedKey={query?.groupCol2}
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}
