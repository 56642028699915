import {
    CommandBar,
    DefaultButton,
    ICommandBarItemProps,
    IStackTokens,
    Link,
    PrimaryButton,
    ScrollablePane,
    Separator,
    Spinner,
    Stack,
    TextField,
    Toggle,
} from '@fluentui/react';
import { Field, Section } from 'components';
import useQuery from 'hooks/useQuery';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import {
    adjustAllPatientAdjustments,
    clearAdjustments,
    clearPayments,
    payAllPatientPayments,
    setPatientPaymentsAndAdjustmentsValidationErrors,
    toggleAllowOveradjustmentOrPayment,
    updatePatientPaymentSource,
    updatePatientPaymentTransaction,
} from 'state/slices/ledger/ledger.slice';
import PaymentsAndAdjustmentsList from './PaymentsAndAdjustmentsList';
import PaymentInfoSection from './PaymentInfoSection';
import AdjustmentInfoSection from './AdjustmentInfoSection';
import { useValidation } from 'hooks';
import {
    cleanupMakePatientPaymentsOrAdjustmentsPage,
    createPatientAdjustmentInformation,
    createPatientPaymentInformation,
    createPatientPaymentOrAdjustmentInformation,
    getPatientEncounterSummariesWithLineItemsView,
    getPatientPaymentsAndAdjustmentsBillingProcedures,
    savePatientPaymentInfoAndAdjustmentInfo,
    updatePatientPaymentSourceAndTransactionsBatchId,
} from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.actions';
import { BatchField } from 'pages/components/Batch/BatchField';
import { IValidationConfig, getValidationError } from 'hooks/useValidation';
import { useCookies } from 'react-cookie';
import { selectBatches } from 'state/slices/tenant/batches.slice';
import { Cookies } from 'interfaces/cookies';
import {
    selectHidePatientPaymentSourceIdentifier,
    selectPatientAdjustmentInfoSaving,
    selectPatientAdjustmentTransactions,
    selectPatientAdjustmentTransactionsAsList,
    selectPatientOverpaymentOrOverAdjustment,
    selectPatientPaymentInfoSaving,
    selectPatientPaymentMethodIsCheckOrAuth,
    selectPatientPaymentsAndAdjustmentsBillingProceduresIsLoading,
    selectPatientPaymentsAndAdjustmentsInfoSaving,
    selectPatientPaymentsAndAdjustmentsProceduresGroupedByEncounter,
    selectPatientPaymentsAndAdjustmentsProceduresGroupedByEncounterWithBalance,
    selectPatientPaymentsOrAdjustmentsModified,
    selectPatientPaymentSource,
    selectPatientPaymentTransactions,
    selectPatientTotalAdjustmentAmount,
    selectPaymentAndAdjustmentAmounts,
    selectPaymentsAndAdjustmentTotalPayment,
} from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.selectors';
import PaymentAndAdjustmentResultMessageBar from './PaymentAndAdjustmentResultMessageBar';
import { usdCurrencyFormatter } from 'utils';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import { useAppDispatch } from 'hooks/useAppDispatch';
/**
 * Gets section heading based on context for making a payment/adjustment
 *
 * @param {({
 *     hideMakeAdjustment: boolean;
 *     hideMakePayment: boolean;
 * })} {
 *     hideMakeAdjustment,
 *     hideMakePayment,
 * }
 * @return {*}
 */
function getSectionHeading({
    hideMakeAdjustment,
    hideMakePayment,
    totalPatientBalance,
}: {
    hideMakeAdjustment: boolean;
    hideMakePayment: boolean;
    totalPatientBalance: number;
}) {
    let heading = '';
    if (!hideMakeAdjustment && !hideMakePayment) {
        heading = 'Make Payment and Adjustment';
    } else if (!hideMakePayment) {
        heading = 'Make Payment';
    } else {
        if (!hideMakeAdjustment) heading = 'Make Adjustment';
    }

    heading += ` (Total Patient Balance: ${usdCurrencyFormatter.format(totalPatientBalance)})`;

    return heading;
}

function getOverpaymentOverAdjustmentHeading({
    hideMakeAdjustment,
    hideMakePayment,
}: {
    hideMakeAdjustment: boolean;
    hideMakePayment: boolean;
}) {
    if (!hideMakeAdjustment && !hideMakePayment) return 'Allow Overpayment/Overadjustment';
    if (!hideMakeAdjustment) return 'Allow Overadjustment';
    if (!hideMakePayment) return 'Allow Overpayment';
}

const stackTokens: IStackTokens = {
    childrenGap: 10,
};

/**
 *
 * Trying to understand how to show prompt when user leaves page.
 *
 *
 */

export default function MakePaymentAndAdjustment() {
    const dispatch = useAppDispatch();

    const { push } = useHistory();

    const { encounterId, tenantId, patientId } = useParams<RouteParams>();
    const [isMouseOver, setMouseOver] = useState(false);

    const query = useQuery();
    const hideMakePayment = query.get('hideMakePayment') === 'true';
    const hideMakeAdjustment = query.get('hideMakeAdjustment') === 'true';

    const [cookies] = useCookies();
    const { data: batches } = useSelector(selectBatches);
    const selectedBatch = batches[cookies[Cookies.SelectedBatch]];

    useEffect(() => {
        if (selectedBatch) dispatch(updatePatientPaymentSourceAndTransactionsBatchId(selectedBatch));
    }, [selectedBatch, dispatch]);

    useEffect(() => {
        if (!patientId) return;
        const _getPatientEncounterSummaries = dispatch(getPatientEncounterSummariesWithLineItemsView({ tenantId, patientId }));
        const _getPatientPaymentsAndAdjustmentsBillingProcs = dispatch(
            getPatientPaymentsAndAdjustmentsBillingProcedures({ tenantId, patientId }),
        );

        return () => {
            _getPatientEncounterSummaries.abort('CancelPatientEncounterSummariesViewPageUnloaded');
            _getPatientPaymentsAndAdjustmentsBillingProcs.abort('CancelPatientPaymentsAndAdjustmentsBillingProcedures');
        };
    }, [tenantId, patientId, dispatch]);

    const overpaymentOrAdjustment = useSelector(selectPatientOverpaymentOrOverAdjustment);
    const paymentSource = useSelector(selectPatientPaymentSource);
    const totalAdjustmentAmount = useSelector(selectPatientTotalAdjustmentAmount);
    const adjustmentTransactionsList = useSelector(selectPatientAdjustmentTransactionsAsList);
    const modified = useSelector(selectPatientPaymentsOrAdjustmentsModified);
    const methodIdentifierLabel = useSelector(selectPatientPaymentMethodIsCheckOrAuth);
    const hideMethodIdentifier = useSelector(selectHidePatientPaymentSourceIdentifier);
    const { paymentAdjustmentsValid, paymentAmount, adjustmentAmount, remainingPaymentAmount } = useSelector(
        selectPaymentAndAdjustmentAmounts,
    );
    const totalPaymentDue = useSelector(selectPaymentsAndAdjustmentTotalPayment);

    const loadingPatientBillingProcedures = useSelector(selectPatientPaymentsAndAdjustmentsBillingProceduresIsLoading);
    const patientPaymentsAndAdjustmentsInfoSaving = useSelector(selectPatientPaymentsAndAdjustmentsInfoSaving);

    const savingPaymentInfo = useSelector(selectPatientPaymentInfoSaving);
    const savingAdjustmentInfo = useSelector(selectPatientAdjustmentInfoSaving);

    const allSummaries = useSelector(selectPatientPaymentsAndAdjustmentsProceduresGroupedByEncounter);
    const summariesWithAmount = useSelector(selectPatientPaymentsAndAdjustmentsProceduresGroupedByEncounterWithBalance);

    const transactions = useSelector(selectPatientPaymentTransactions);
    const adjustmentTransactions = useSelector(selectPatientAdjustmentTransactions);

    useEffect(() => {
        if (patientId && !loadingPatientBillingProcedures) {
            dispatch(createPatientPaymentOrAdjustmentInformation(patientId, hideMakePayment, hideMakeAdjustment, selectedBatch));
        }
        return () => {
            dispatch(cleanupMakePatientPaymentsOrAdjustmentsPage());
        };
    }, [loadingPatientBillingProcedures]);

    const makePaymentAndAdjustment = (adjustmentOrPayment: 'adjustment' | 'payment') => {
        push({ search: '' });
        if (patientId) {
            if (adjustmentOrPayment === 'payment') dispatch(createPatientPaymentInformation(patientId, selectedBatch));
            if (adjustmentOrPayment === 'adjustment') dispatch(createPatientAdjustmentInformation(patientId, selectedBatch));
        }
    };

    const isMakingPaymentAndAdjustment = !hideMakeAdjustment && !hideMakePayment;

    const _onSave = () => {
        if (patientId)
            dispatch(
                savePatientPaymentInfoAndAdjustmentInfo(tenantId, patientId, encounterId, hideMakeAdjustment, hideMakePayment),
            );
    };

    const _onClearPayments = () => {
        dispatch(clearPayments());
    };

    const _onClearAdjustments = () => {
        dispatch(clearAdjustments());
    };

    const _onPayAll = (maxAmount: number) => {
        dispatch(payAllPatientPayments({ maxAmount }));
    };

    const _onAdjustAll = (maxAmount: number) => {
        dispatch(adjustAllPatientAdjustments({ maxAmount }));
    };

    const _onToggleOverpaymentOrAdjustment = () => {
        dispatch(toggleAllowOveradjustmentOrPayment());
    };

    const _onChangeNote = (event?: FormEvent, value?: string) => {
        dispatch(updatePatientPaymentSource({ path: 'note', value }));
    };

    const cancelAndGoBack = () => {
        let urlPath = `/${tenantId}/patient/${patientId}`;
        if (encounterId) urlPath += `/encounter/${encounterId}`;
        push(`${urlPath}/ledger/patient-payments-and-adjustments`);
    };

    /**
     * Gets command bar items based on context of making payment/adjustment.
     *
     * @param {({
     *     hideMakeAdjustment: boolean;
     *     hideMakePayment: boolean;
     * })} {
     *     hideMakeAdjustment,
     *     hideMakePayment,
     * }
     * @return {*}
     */
    const getCommandBarItems = useCallback(
        ({ hideMakeAdjustment, hideMakePayment }: { hideMakeAdjustment: boolean; hideMakePayment: boolean }) => {
            const commandBarItems: ICommandBarItemProps[] = [];
            const disabled = loadingPatientBillingProcedures;
            const paymentCommandBarItems: ICommandBarItemProps[] = [
                {
                    key: 'distribute-payment',
                    text: 'Distribute Payment',
                    iconProps: {
                        iconName: 'Add',
                    },
                    onClick: () => _onPayAll(paymentAmount),
                    disabled: disabled || overpaymentOrAdjustment,
                },
                {
                    key: 'clear-payments',
                    text: 'Clear Payments',
                    iconProps: {
                        iconName: 'Cancel',
                    },
                    onClick: _onClearPayments,
                    disabled,
                },
            ];
            const adjustmentCommandBarItems: ICommandBarItemProps[] = [
                {
                    key: 'distribute-adjustment',
                    text: 'Distribute Adjustment',
                    iconProps: {
                        iconName: 'Add',
                    },
                    onClick: () => _onAdjustAll(adjustmentAmount),
                    disabled: disabled || overpaymentOrAdjustment,
                },
                {
                    key: 'clear-adjustmnets',
                    text: 'Clear Adjustments',
                    iconProps: {
                        iconName: 'Cancel',
                    },
                    onClick: _onClearAdjustments,
                    disabled,
                },
            ];
            if (!hideMakeAdjustment && !hideMakePayment) {
                commandBarItems.push(
                    ...paymentCommandBarItems,
                    {
                        key: 'divider',
                        onRender: () => <Separator vertical styles={{ root: { paddingRight: 10, paddingLeft: 10 } }} />,
                    },
                    ...adjustmentCommandBarItems,
                );
            } else if (!hideMakeAdjustment) {
                commandBarItems.push(...adjustmentCommandBarItems);
            } else if (!hideMakePayment) {
                commandBarItems.push(...paymentCommandBarItems);
            }

            return commandBarItems;
        },
        [paymentAmount, adjustmentAmount, loadingPatientBillingProcedures, overpaymentOrAdjustment],
    );

    const getValdiationConfig = useCallback((): IValidationConfig => {
        const validationConfig: IValidationConfig = [
            {
                fieldName: 'Batch',
                value: selectedBatch,
                validation: ['required'],
            },
            {
                fieldName: 'Notes',
                value: paymentSource?.note,
                validation: ['characterLimit'],
                itemOptions: { characterLimit: 150 },
            },
        ];

        if (!hideMakePayment) {
            validationConfig.push(
                {
                    fieldName: "Today's Payment",
                    value: paymentSource?.amount,
                    validation: ['required'],
                },
                {
                    fieldName: 'Payment Method',
                    value: paymentSource?.method,
                    validation: ['required'],
                },
            );
            if (!hideMethodIdentifier) {
                validationConfig.push({
                    fieldName: methodIdentifierLabel,
                    value: paymentSource?.methodIdentifier,
                    validation: ['required'],
                });
            }
        }

        if (!hideMakeAdjustment) {
            const adjustmentReasonFromTransaction = adjustmentTransactionsList[0]?.adjustmentReasonId;
            validationConfig.push(
                {
                    fieldName: 'Adjustment Amount',
                    value: totalAdjustmentAmount,
                    validation: ['required'],
                },
                {
                    fieldName: 'Adjustment Reason',
                    value: adjustmentReasonFromTransaction,
                    validation: ['required'],
                },
            );
        }

        return validationConfig;
    }, [
        hideMakeAdjustment,
        hideMakeAdjustment,
        selectBatches,
        paymentSource,
        adjustmentTransactionsList,
        totalAdjustmentAmount,
        hideMethodIdentifier,
        methodIdentifierLabel,
    ]);

    const [errors, submit, cleanupErrors] = useValidation(getValdiationConfig(), _onSave, {
        shouldZerosNotFulfillRequired: true,
    });

    useEffect(() => {
        cleanupErrors();
    }, [hideMakeAdjustment, hideMakeAdjustment]);

    useEffect(() => {
        dispatch(setPatientPaymentsAndAdjustmentsValidationErrors(errors));
    }, [errors]);

    const isMakeAdjustmentOnly = hideMakePayment;

    return (
        <Stack onMouseOver={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)} grow>
            <Prompt
                when={
                    savingAdjustmentInfo !== LoadingStatus.Completed &&
                    savingPaymentInfo !== LoadingStatus.Completed &&
                    savingAdjustmentInfo !== LoadingStatus.Failed &&
                    savingPaymentInfo !== LoadingStatus.Failed &&
                    modified &&
                    !isMouseOver //Not sure how else to do this :(
                }
                message={'Are you sure you want to leave? Any unsaved information will be lost.'}
            />
            <Section
                footer={
                    <Stack tokens={stackTokens} grow horizontal verticalAlign="center">
                        <Stack.Item>
                            <PaymentAndAdjustmentResultMessageBar />
                        </Stack.Item>
                        <Stack horizontal horizontalAlign="end" grow tokens={stackTokens}>
                            {patientPaymentsAndAdjustmentsInfoSaving && <Spinner label="Saving..." labelPosition="right" />}
                            <DefaultButton iconProps={{ iconName: 'Cancel' }} onClick={cancelAndGoBack}>
                                Cancel
                            </DefaultButton>
                            <PrimaryButton
                                iconProps={{ iconName: 'Save' }}
                                onClick={submit}
                                disabled={!paymentAdjustmentsValid || patientPaymentsAndAdjustmentsInfoSaving}
                            >
                                Save
                            </PrimaryButton>
                        </Stack>
                    </Stack>
                }
                heading={
                    <Stack horizontal tokens={stackTokens}>
                        {getSectionHeading({ hideMakeAdjustment, hideMakePayment, totalPatientBalance: totalPaymentDue })}

                        <Toggle
                            styles={{ root: { marginBottom: 0, paddingLeft: 20 }, label: { padding: 0 } }}
                            inlineLabel
                            label={getOverpaymentOverAdjustmentHeading({ hideMakeAdjustment, hideMakePayment })}
                            checked={overpaymentOrAdjustment}
                            onChange={_onToggleOverpaymentOrAdjustment}
                            disabled={loadingPatientBillingProcedures}
                        />
                    </Stack>
                }
            >
                <Stack styles={{ root: { maxHeight: 320, overflowY: 'auto', overflowX: 'hidden' } }} grow tokens={stackTokens}>
                    <Stack horizontal tokens={stackTokens} verticalAlign="start">
                        <BatchField error={errors} textfieldProps={{ label: 'Batch' }} />
                        <Field.Date
                            label="Batch Date"
                            value={
                                !isMakeAdjustmentOnly ? paymentSource?.dateOfEntry : adjustmentTransactionsList?.[0]?.dateOfEntry
                            }
                            autoComplete="off"
                            readOnly
                        />
                    </Stack>
                    <Stack grow tokens={stackTokens}>
                        {!hideMakePayment ? (
                            <>
                                <PaymentInfoSection isMakingPaymentAndAdjustment={isMakingPaymentAndAdjustment} />
                                <TextField
                                    multiline
                                    rows={1}
                                    label="Notes"
                                    value={paymentSource?.note}
                                    onChange={_onChangeNote}
                                    description={`Used ${paymentSource?.note?.length ?? 0} of 150 characters.`}
                                    errorMessage={getValidationError(errors, 'Notes') ? 'Character limit must be below 150.' : ''}
                                />
                            </>
                        ) : (
                            <Link onClick={() => makePaymentAndAdjustment('payment')}>+ Make Payment</Link>
                        )}
                        {!hideMakeAdjustment ? (
                            <AdjustmentInfoSection isMakingPaymentAndAdjustment={isMakingPaymentAndAdjustment} />
                        ) : (
                            <Link onClick={() => makePaymentAndAdjustment('adjustment')}>+ Make Adjustment</Link>
                        )}
                    </Stack>
                </Stack>
            </Section>
            <CommandBar
                styles={{ root: { paddingLeft: 0 } }}
                items={getCommandBarItems({ hideMakeAdjustment, hideMakePayment })}
            />
            <div style={{ position: 'relative', flex: 1, display: 'flex' }}>
                <ScrollablePane>
                    <PaymentsAndAdjustmentsList
                        allSummaries={allSummaries}
                        transactions={transactions}
                        onChangePayment={(encounterId, transactionId, amount) => {
                            dispatch(updatePatientPaymentTransaction({ encounterId, transactionId, amount }));
                        }}
                        adjustmentTransactions={adjustmentTransactions}
                        summariesWithAmount={summariesWithAmount}
                        paymentAmount={paymentAmount}
                        remainingPaymentAmount={remainingPaymentAmount}
                        makingPaymentOrAdjustment
                        overpayment={overpaymentOrAdjustment}
                    />
                </ScrollablePane>
            </div>
        </Stack>
    );
}
