import { EncounterWorkList } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import EncounterWorkListComponent from './EncounterWorkList';
import useApproveWorklistCommandBar from '../Hooks/useApproveWorklistCommandBar';

export default function ReadyToReview(): JSX.Element {
    const commandBarProps = useApproveWorklistCommandBar();

    return (
        <EncounterWorkListComponent
            commandBarProps={commandBarProps}
            workLists={[EncounterWorkList.ReadyToReview, EncounterWorkList.ReadyToReviewOnHold]}
        />
    );
}
