import { RouteParams } from 'interfaces/route-params';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
    RecentlyViewedWorkListItemResponse,
    getWorkListData,
    onViewRecentWorkListItem,
} from 'state/slices/admin-huddle/worklists/worklist.actions';
import {
    cleanupWorkListData,
    cleanupWorkListPagination,
    cleanupWorkListMessageBar,
    setCurrentWorkList,
    setWorkListCurrentPageNumber,
    setWorkListItemsPerPage,
    setWorkListReadOnly,
    cleanupWorkListOpenLayers,
    setWorklistFilters,
} from 'state/slices/admin-huddle/worklists/worklist.slice';
import { WorkList } from 'state/slices/admin-huddle/worklists/worklist.state';
import {
    CommandBar,
    ICommandBarProps,
    MessageBar,
    MessageBarType,
    Pivot,
    PivotItem,
    Spinner,
    Stack,
    Text,
} from '@fluentui/react';
import {
    selectWorkListCurrentPageNumber,
    selectWorkListData,
    selectWorkListHasFilters,
    selectWorkListHasSearched,
    selectWorkListItemsPerPage,
    selectWorkListLoading,
    selectWorkListTotalItems,
    selectWorkListMessageBar,
    selectWorkListContinuationTokens,
    selectWorkListPageCount,
    selectWorkListTotalItemsLoading,
} from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import PageSelector from 'components/PageSelector';

import { signalRGroupSubscriptionService, useSignalR } from 'hooks/signalr/useSignalr';
import queryString from 'query-string';
import useWorkListSignalRConfig from 'hooks/signalr/useWorkListSignalRConfig';
import WorkListRecentItems from './WorkListRecentItems';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useId } from '@uifabric/react-hooks';
import workListDetailsLookup, { WorkListDetailsData } from './WorkListDetailsLookup';
import { workListColumnComponents, workListFilterComponents, workListLayerComponents } from './WorkListComponentLookup';
import WorkListFilterWrapper from './FilterComponents/WorkListFilterWrapper';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { SignalRGroup } from 'hooks/signalr/signalRGroupSubscriptionService';

/** Lookup the name pivot/tab label for each EncounterWorkList */
const workListTabNameLookup: Record<WorkList, string> = {
    BilledInsurance: 'Approved - Insurance',
    BilledPatient: 'Approved - Slide/Self-Pay',
    BilledInsuranceCorrectionNeeded: 'Sent for Correction - Insurance',
    BilledPatientCorrectionNeeded: 'Sent for Correction - Slide/Self-Pay',
    BilledInsuranceCorrectionAmend: 'Awaiting Provider Correction - Insurance',
    BilledPatientCorrectionAmend: 'Awaiting Provider Correction - Slide/Self-Pay',
    Denials: 'Denials',
    EditEncountersDenials: 'Denials',
    EditEncountersCorrectionAmend: 'Awaiting Provider Correction',
    EditEncounters: 'Corrections Requested (Non-Provider)',
    ReadyToRebill: 'Ready to Reapprove',
    ReadyToRebillOnHold: 'Clarification Needed',
    ReadyToReview: 'Ready to Review',
    ReadyToReviewOnHold: 'On Hold',
    AmendRequireEncounters: 'Awaiting Amendment',
    OutstandingCheckInAppointments: 'Outstanding Check Ins',
    OutstandingCheckoutAppointments: 'Outstanding Checkouts',
    AwaitingAttestation: 'Awaiting Signature/Attestation',
    Predeterminations: 'Predetermination Requests',
};

//This is the data that can be passed to the WorkListBuilder children
export type WorkListDetailsListDataForChildren<C = any | undefined, E = any | undefined> = Pick<
    WorkListDetailsData<C>,
    'selectionMode' | 'readOnly' | 'sortColumns' | 'initialSortDirection'
> & {
    columns: ISortableColumn<C>[];
    expandedRowColumns?: ISortableColumn<E>[];
};

//WorkListBuilder component properties
export type WorkListBuilderProps<C, E> = {
    workLists: WorkList[];
    upperContent?: JSX.Element | null | (() => JSX.Element);
    commandBarProps?: ICommandBarProps;
    hideFilters?: boolean;
    hideRecentItems?: boolean;
    children: (detailsListData: WorkListDetailsListDataForChildren<C, E> | undefined) => React.ReactNode;
};

/**
 *
 *
 * @export
 * @template C
 * @template E
 * @param {PropsWithChildren<WorkListBuilderProps<C, E>>} {
 *     children,
 *     workLists,
 *     upperContent,
 *     commandBarProps,
 * }
 * @return {*}
 */
export default function WorkListBuilder<C = any | undefined, E = any | undefined>({
    children,
    workLists,
    upperContent,
    hideFilters,
    hideRecentItems,
    commandBarProps,
}: PropsWithChildren<WorkListBuilderProps<C, E>>) {
    const dispatch = useAppDispatch();
    const { tenantId } = useParams<RouteParams>();
    const { registerSignalRConfig } = useSignalR({ disableAutoMessageCleanup: true });

    const { location } = useHistory();
    //Boolean selectors
    const hasFilters = useSelector(selectWorkListHasFilters);
    const hasSearched = useSelector(selectWorkListHasSearched);

    const currentPageNumber = useSelector(selectWorkListCurrentPageNumber);
    const itemsPerPage = useSelector(selectWorkListItemsPerPage);
    const totalItems = useSelector(selectWorkListTotalItems);

    //Encounter views
    const views = useSelector(selectWorkListData);
    //Work list loading status.
    const loading = useSelector(selectWorkListLoading);
    const loadingCompleted = loading === LoadingStatus.Completed;
    const loadingPending = loading === LoadingStatus.Pending;
    const loadingError = loading === LoadingStatus.Failed;

    const loadingTotalItems = useSelector(selectWorkListTotalItemsLoading);
    const loadingTotalItemsPending = loadingTotalItems === LoadingStatus.Pending;
    const loadingTotalItemsCompleted = loadingTotalItems === LoadingStatus.Completed;

    const query = useMemo(() => queryString.parse(location.search), [location.search]);
    const selectedTab = (query.selectedTab ?? workLists[0]) as WorkList;

    //Currently selected encounter details state. Holds selectionMode, columns, procedureColumns, read only props, etc. Can be passed to children.
    const [workListDetailsData, setWorkListDetailsData] = useState<WorkListDetailsData<C> | undefined>(
        workListDetailsLookup[selectedTab],
    );

    const hasRecentItems =
        hideRecentItems !== undefined
            ? !hideRecentItems
            : workListDetailsData?.hasRecentItems !== undefined
                ? workListDetailsData.hasRecentItems
                : true;

    const hasPageSelector = workListDetailsData?.hasPageSelector !== undefined ? workListDetailsData.hasPageSelector : true;

    const workListSignalRConfig = useWorkListSignalRConfig(selectedTab);

    //Cleans up the entire worklist state.
    function cleanupWorkList() {
        dispatch(cleanupWorkListData());
    }

    //cleanup

    //Handle contextual (per work list) state.
    useEffect(() => {
        if (!isValidSelectedTab(workLists, selectedTab)) return;

        dispatch(setCurrentWorkList(selectedTab));
        dispatch(cleanupWorkListPagination());
        dispatch(cleanupWorkListOpenLayers());

        setWorkListDetailsData(workListDetailsLookup[selectedTab]);

        //Set initial filter values immediately as the tab/worklist changes. Ensures the request for the worklist has these filters.
        const worklistDetailData = workListDetailsLookup[selectedTab];
        dispatch(setWorklistFilters({ initialFilters: worklistDetailData.filterInitialValues }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tenantId, selectedTab]);

    //Handle setting work list read only
    useEffect(() => {
        if (workListDetailsData) dispatch(setWorkListReadOnly(!!workListDetailsData.readOnly));
    }, [workListDetailsData, dispatch]);

    //Handle getting data
    useEffect(() => {
        if (!isValidSelectedTab(workLists, selectedTab)) return;

        const _getWorkListData = dispatch(getWorkListData({ tenantId, workList: selectedTab }));

        return () => {
            //When the component unloads cancel the get worklist data request.
            _getWorkListData.abort('CurrentWorkListChanged');
            signalRGroupSubscriptionService.unsubscribeFromGroups(tenantId, [SignalRGroup.Tenant]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId, selectedTab, currentPageNumber, itemsPerPage]);

    useEffect(() => {
        return () => {
            cleanupWorkList();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //handle SignalR messages
    useEffect(() => {
        if (workListSignalRConfig.length) {
            registerSignalRConfig(workListSignalRConfig);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workListSignalRConfig]);

    //Unique identifiers for mapped component keys.
    const filterComponentId = useId();
    const layerComponentId = useId();

    //Theres no need to recalc these on each render. Just memoize until a necessary update occurs.
    const filters = useMemo(
        () =>
            !hideFilters
                ? (workListDetailsData?.filterComponents?.map((componentName, index) => {
                    const Component = workListFilterComponents[componentName];
                    return <Component key={`${index}-${filterComponentId}`} />;
                }) ?? [])
                : [],
        [workListDetailsData?.filterComponents, filterComponentId, hideFilters],
    );

    const layers = useMemo(() => {
        return (
            workListDetailsData?.layerComponents?.map((componentName, index) => {
                const Component = workListLayerComponents[componentName];
                return <Component readOnly={!!workListDetailsData.readOnly} key={`${index}-${layerComponentId}`} />;
            }) ?? []
        );
    }, [workListDetailsData?.layerComponents, layerComponentId, workListDetailsData?.readOnly]);

    const columns = useMemo(() => {
        return workListDetailsData?.columns?.map((col) => workListColumnComponents[col]) ?? [];
    }, [workListDetailsData?.columns]);

    const expandedRowColumns = useMemo(() => {
        return workListDetailsData?.expandedRowColumns?.map((col) => workListColumnComponents[col]) ?? [];
    }, [workListDetailsData?.expandedRowColumns]);

    //Handles when the user performs an action on a recent worklist item.
    const _onViewRecentWorkListItem = useCallback(
        (item: RecentlyViewedWorkListItemResponse, key: string) => {
            dispatch(onViewRecentWorkListItem(tenantId, item, key));
        },
        [dispatch, tenantId],
    );

    return (
        <>
            <Stack tokens={{ childrenGap: 10 }} style={{ padding: 10 }} grow>
                {filters.length && !hideFilters && <WorkListFilterWrapper>{filters}</WorkListFilterWrapper>}
                {typeof upperContent === 'function' ? upperContent() : upperContent}
                <Stack grow>
                    <Stack tokens={{ childrenGap: 5 }} horizontal horizontalAlign="space-between" verticalAlign="center" wrap>
                        <EncounterWorkListPivot workLists={workLists} selectedTab={selectedTab} />
                        {hasRecentItems && (
                            <WorkListRecentItems<C>
                                recentlyViewedActions={workListDetailsData?.recentlyViewedActions}
                                onViewRecentItem={_onViewRecentWorkListItem}
                                getRecentlyViewedItemFromData={workListDetailsData?.getRecentlyViewedItemFromData}
                            />
                        )}
                    </Stack>
                    <WorkListMessageBar />
                    {commandBarProps && (
                        <CommandBar
                            styles={{ root: { paddingLeft: 0 } }}
                            items={commandBarProps.items.map((item) => ({
                                ...item,
                                disabled: !loadingCompleted || !views.length || item.disabled,
                            }))}
                        />
                    )}
                    {loadingCompleted && !views.length ? (
                        hasFilters && hasSearched ? (
                            <MessageBar>No results exist for the current filters.</MessageBar>
                        ) : (
                            <MessageBar>No work list items exist here.</MessageBar>
                        )
                    ) : loadingError ? (
                        <MessageBar messageBarType={MessageBarType.error}>Something went wrong.</MessageBar>
                    ) : null}
                    <Stack horizontal grow>
                        {(loadingCompleted && views.length) || loadingPending ? (
                            <>
                                {children
                                    ? children({
                                        readOnly: workListDetailsData?.readOnly,
                                        selectionMode: workListDetailsData?.selectionMode,
                                        columns,
                                        expandedRowColumns,
                                        initialSortDirection: workListDetailsData?.initialSortDirection,
                                        sortColumns: workListDetailsData?.sortColumns,
                                    })
                                    : null}
                            </>
                        ) : null}
                        {layers}
                    </Stack>
                </Stack>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    {hasPageSelector && <EncounterWorkListPageSelector />}
                    {loadingTotalItemsCompleted || totalItems ? (
                        <>
                            {totalItems ? (
                                <Text>
                                    {hasFilters && hasSearched ? 'Total Results' : 'Total Items'}: {totalItems}
                                </Text>
                            ) : null}
                        </>
                    ) : loadingTotalItemsPending ? (
                        <Spinner
                            labelPosition="left"
                            label={`Loading total ${hasFilters && hasSearched ? 'results' : 'item'} count...`}
                        />
                    ) : null}
                </Stack>
            </Stack>
        </>
    );
}
/**Message bar for display worklist related feedback to the user*/
export function WorkListMessageBar() {
    const dispatch = useDispatch();

    const { message, messageBarType } = useSelector(selectWorkListMessageBar);

    const messageBarNotVisible = !message || !messageBarType;

    if (messageBarNotVisible) return null;

    const iconNameLookup: Partial<Record<MessageBarType, string>> = {
        [MessageBarType.success]: 'CheckMark',
        [MessageBarType.blocked]: 'Blocked',
        [MessageBarType.error]: 'Error',
    };

    return (
        <MessageBar
            styles={{ root: { paddingBottom: 0, marginBottom: 0 } }}
            messageBarType={messageBarType}
            messageBarIconProps={{ iconName: iconNameLookup[messageBarType] }}
            onDismiss={() => {
                dispatch(cleanupWorkListMessageBar());
            }}
        >
            <Stack tokens={{ childrenGap: 10 }} horizontal horizontalAlign="space-around">
                <span>{message}</span>
            </Stack>
        </MessageBar>
    );
}
/**Is the selected tab valid for the given encounter worklist tabs available?*/
function isValidSelectedTab(encounterWorkLists: WorkList[], selectedTab: WorkList) {
    return encounterWorkLists.findIndex((workList) => workList === selectedTab) > -1;
}

type EncounterWorkListPivotProps = {
    workLists: WorkList[];
    selectedTab: WorkList;
};

function EncounterWorkListPivot({ workLists, selectedTab }: EncounterWorkListPivotProps) {
    const { push } = useHistory();

    /**Set selected tab using query params.*/
    const setSelectedTab = useCallback(
        (encounterWorkList: WorkList) => {
            push({ search: `?selectedTab=${encounterWorkList}` });
        },
        [push],
    );

    //Handles an invalid selected tab. If invalid, set the selected tab to the first known tab.
    useEffect(() => {
        if (!isValidSelectedTab(workLists, selectedTab)) setSelectedTab(workLists[0]);
    }, [selectedTab, setSelectedTab, workLists]);

    if (workLists.length === 1) return null;

    const pivotItems = workLists.map((workList, index) => (
        <PivotItem key={`${workList}-${index}`} headerText={workListTabNameLookup[workList]} itemKey={workList} />
    ));

    return (
        <Pivot
            onLinkClick={(item) => {
                if (item?.props.itemKey) {
                    setSelectedTab(item.props.itemKey as WorkList);
                }
            }}
            selectedKey={selectedTab}
        >
            {pivotItems}
        </Pivot>
    );
}

function EncounterWorkListPageSelector() {
    const dispatch = useDispatch();
    //Pagination selectors:
    const itemsPerPage = useSelector(selectWorkListItemsPerPage);
    const currentPage = useSelector(selectWorkListCurrentPageNumber);
    const pageCount = useSelector(selectWorkListPageCount);
    const currentPageNumber = useSelector(selectWorkListCurrentPageNumber);
    const continuationTokens = useSelector(selectWorkListContinuationTokens);

    const hasContinuationTokens = !!continuationTokens.length;

    //Loading status:
    const loading = useSelector(selectWorkListLoading);
    const loadingCompleted = loading === LoadingStatus.Completed;

    return (
        <Stack tokens={{ childrenGap: 5 }} horizontal>
            <PageSelector
                onChangePage={(pageNumber) => {
                    dispatch(setWorkListCurrentPageNumber(pageNumber));
                }}
                onChangePageSize={(pageSize) => {
                    dispatch(setWorkListItemsPerPage(pageSize));
                    //Since we don't know the number of pages when we change the size (Unless we calculated it). Set the current page back to 1.
                    dispatch(setWorkListCurrentPageNumber(1));
                }}
                maxDisplayedPages={10}
                currentPage={currentPageNumber}
                pageSize={itemsPerPage}
                disabled={!loadingCompleted}
                disableNext={
                    //If we have continuation tokens, look for a continuation token for the current page (index based). Otherwise we use the page count.
                    hasContinuationTokens ? !continuationTokens[currentPage - 1] : pageCount ? currentPage === pageCount : true
                }
                disablePrevious={
                    //If we have continuation tokens, look for a continuation token for previous page (index based). Otherwise we use the page count.
                    hasContinuationTokens ? !continuationTokens[currentPage - 2] : pageCount ? currentPage === 1 : true
                }
            />
        </Stack>
    );
}
