import { ComboBox, Dropdown, IDropdownOption, Stack, TextField } from '@fluentui/react';
import IPatientContact from 'api/models/patient-contact.model';
import { Field } from 'components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectContact } from 'state/slices/contacts/contacts.slice';
import { selectSameAddressAsPatient } from 'state/slices/edit-patient/edit-patient.selectors';
import { classicDateOnly } from 'utils/dateOnly';
import ContactAddressFields from './ContactAddressFields';

enum ContactType {
    EmergencyContact = 'EmergencyContact',
    Guarantor = 'Guarantor',
}

function ContactFields(): JSX.Element {
    const address = useSelector(selectSameAddressAsPatient);
    const contact = useSelector(selectContact);

    //For storing the initial contact data without any edits.
    const [initialContact, setInitialContact] = useState<IPatientContact>();

    useEffect(() => {
        if (contact && !initialContact) setInitialContact(contact);
    }, [contact]);

    useEffect(() => {
        return () => {
            setInitialContact(undefined);
        };
    }, []);

    const contactTypeOptions: IDropdownOption[] = [
        {
            key: '',
            text: '(Select Contact Type)',
        },
        {
            key: ContactType.Guarantor,
            text: ContactType.Guarantor,
        },
        {
            key: ContactType.EmergencyContact,
            text: 'Emergency Contact',
        },
    ];
    const getContactType = () => {
        if (contact) {
            if (contact.isGuarantor) return ContactType.Guarantor;
            if (contact.isEmergencyContact) return ContactType.EmergencyContact;
            return '';
        }

        return '';
    };

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack tokens={{ childrenGap: 10 }}>
                <Stack grow horizontal tokens={{ childrenGap: 10 }}>
                    <ComboBox
                        label="Patient's relationship to contact"
                        style={{ width: 220 }}
                        selectedKey={contact?.relationshipId}
                        allowFreeform
                        autoComplete="on"
                        options={[
                            {
                                key: '1',
                                text: 'Self',
                            },
                            {
                                key: '2',
                                text: 'Spouse',
                            },
                            {
                                key: '3',
                                text: 'Child',
                            },
                            {
                                key: '4',
                                text: 'Other',
                            },
                            {
                                key: '5',
                                text: 'Grandparent',
                            },
                            {
                                key: '6',
                                text: 'Grandchild',
                            },
                            {
                                key: '7',
                                text: 'Nephew or Niece',
                            },
                            {
                                key: '9',
                                text: 'Foster Child',
                            },
                            {
                                key: '10',
                                text: 'Ward',
                            },
                            {
                                key: '11',
                                text: 'Stepson or Stepdaughter',
                            },
                            {
                                key: '12',
                                text: 'Employee',
                            },
                            {
                                key: '13',
                                text: 'Unknown',
                            },
                            {
                                key: '14',
                                text: 'Handicapped Dependent',
                            },
                            {
                                key: '15',
                                text: 'Sponsored Dependent',
                            },
                            {
                                key: '16',
                                text: 'Dependent of a Minor Dependent',
                            },
                            {
                                key: '17',
                                text: 'Significant Other',
                            },
                            {
                                key: '18',
                                text: 'Mother',
                            },
                            {
                                key: '19',
                                text: 'Father',
                            },
                            {
                                key: '21',
                                text: 'Emancipated Minor',
                            },
                            {
                                key: '22',
                                text: 'Organ Donor',
                            },
                            {
                                key: '23',
                                text: 'Cadaver Donor',
                            },
                            {
                                key: '24',
                                text: 'Sponsored Dependent',
                            },
                            {
                                key: '25',
                                text: 'Child (Ins. not Financially Respons.)',
                            },
                            {
                                key: '26',
                                text: 'Life Partner',
                            },
                            {
                                key: '27',
                                text: "Child (Mother's Insurance)",
                            },
                            {
                                key: '28',
                                text: "Child (Father's Insurance)",
                            },
                            {
                                key: '29',
                                text: 'Child (of Mother, Ins. not Financially Respons.)',
                            },
                            {
                                key: '30',
                                text: 'Child (of Father, Ins. not Financially Respons.)',
                            },
                            {
                                key: '31',
                                text: "Stepson or Stepdaughter (Stepmother's Insurance)",
                            },
                            {
                                key: '32',
                                text: "Stepson or Stepdaughter (Stepfather's Insurance)",
                            },
                        ]}
                        disabled
                    />

                    <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end">
                        <Dropdown label="Contact Type" selectedKey={getContactType()} disabled options={contactTypeOptions} />
                    </Stack>
                </Stack>
                <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                    <Stack.Item>
                        <ComboBox
                            label="Title"
                            style={{ width: 120 }}
                            selectedKey={contact?.titleId}
                            allowFreeform
                            autoComplete="on"
                            disabled
                            options={[
                                {
                                    key: 'Mr.',
                                    text: 'Mr.',
                                },
                                {
                                    key: 'Miss.',
                                    text: 'Miss.',
                                },
                                {
                                    key: 'Mrs.',
                                    text: 'Mrs.',
                                },
                                {
                                    key: 'None',
                                    text: 'None',
                                },
                            ]}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField label="First Name" value={contact?.firstName} autoComplete="off" disabled />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField label="Middle Name" value={contact?.middleName} autoComplete="off" disabled />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField label="Last Name" value={contact?.lastName} autoComplete="off" disabled />
                    </Stack.Item>
                </Stack>
                <Stack grow horizontal tokens={{ childrenGap: 10 }}>
                    <Field.Phone label="Home Number" value={contact?.homePhoneNumber} autoComplete="off" disabled />
                    <Field.Phone label="Mobile Number" value={contact?.mobilePhoneNumber} autoComplete="off" disabled />
                </Stack>
                {contact?.isGuarantor && (
                    <Stack.Item>
                        <Field.Date
                            label="Date of Birth"
                            value={contact?.dateOfBirth ? classicDateOnly(contact.dateOfBirth) : ''}
                            hasDatePicker
                            autoComplete="off"
                            disabled
                            allowFutureDates={false}
                        />
                    </Stack.Item>
                )}
            </Stack>
            {contact?.isGuarantor && (
                <Stack.Item grow>
                    <Field.Email
                        label="Email"
                        value={contact?.email}
                        autoComplete="off"
                        disabled
                        styles={{
                            root: {
                                width: 400,
                            },
                        }}
                    />
                </Stack.Item>
            )}
            {contact?.isGuarantor ? <ContactAddressFields address={address} contact={contact} /> : null}
        </Stack>
    );
}

export default ContactFields;
