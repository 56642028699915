import { Stack, Text, TextField } from '@fluentui/react';
import { Field, TModal } from 'components';
import { useTenantId } from 'hooks';
import { Cookies } from 'interfaces/cookies';
import { FormEvent } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import {
    batchActions,
    saveUpdateBatch,
    selectBatchesAsList,
    selectModalState,
    selectSelectedBatch,
} from 'state/slices/tenant/batches.slice';
import { batchCookieOptions } from './BatchPanel';

export function BatchModal(): JSX.Element {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const batches = useSelector(selectBatchesAsList);
    const selectedBatch = useSelector(selectSelectedBatch);
    const modalState = useSelector(selectModalState);

    const closeBatch = modalState === 'Close';
    const reopenBatch = modalState === 'Reopen';

    const _dismiss = () => dispatch(batchActions.setSelectedBatch({ batch: undefined, modalState }));
    const _dismissed = () => dispatch(batchActions.setSelectedBatch({ modalState: undefined }));

    const [cookies, setCookies, removeCookie] = useCookies([Cookies.SelectedBatch]);

    const _handleSave = () => {
        if (closeBatch) {
            dispatch(batchActions.editSelectedBatch({ path: 'status', value: 'Closed' }));
            if (selectedBatch?.id === cookies[Cookies.SelectedBatch]) {
                removeCookie(Cookies.SelectedBatch, batchCookieOptions);
            }
        }
        if (reopenBatch) {
            dispatch(batchActions.editSelectedBatch({ path: 'status', value: 'Open' }));
            if (selectedBatch?.id === cookies[Cookies.SelectedBatch]) {
                removeCookie(Cookies.SelectedBatch, batchCookieOptions);
            }
        }
        dispatch(saveUpdateBatch(tenantId));
    };

    const batchComponentLookup: Record<string, JSX.Element> = {
        Close: <CloseBatchFields />,
        Reopen: <ReopenBatchFields />,
        Create: <CreateUpdateBatchFields />,
        Update: <CreateUpdateBatchFields />,
    };

    const Components = modalState ? batchComponentLookup[modalState] : undefined;

    const _onDateChange = (ev?: FormEvent, value?: string) =>
        dispatch(batchActions.editSelectedBatch({ path: 'dateOfEntry', value }));
    const _onNameChange = (ev?: FormEvent, value?: string) =>
        dispatch(batchActions.editSelectedBatch({ path: 'displayName', value }));

    const isNew = selectedBatch?.id === '';
    const modalTitle = `${modalState} Batch`;
    const saveText = modalState;
    const displayNameSame = closeBatch
        ? false
        : batches.filter((batch) => batch.displayName === selectedBatch?.displayName).length > 0;

    return (
        <TModal
            title={modalTitle}
            mainButtons={[
                { text: saveText, onClick: _handleSave, primary: true, disabled: isNew && displayNameSame },
                { text: 'Cancel', onClick: _dismiss },
            ]}
            modalProps={{ isOpen: selectedBatch !== undefined, onDismiss: _dismiss, onDismissed: _dismissed }}
        >
            <Stack style={{ padding: 10 }}>{Components}</Stack>
        </TModal>
    );

    function CreateUpdateBatchFields() {
        return (
            <>
                <Field.Date value={selectedBatch?.dateOfEntry} onChange={_onDateChange} label="Date" disabled readOnly />
                <TextField
                    value={selectedBatch?.displayName}
                    onChange={_onNameChange}
                    label="Name"
                    autoFocus
                    errorMessage={isNew && displayNameSame ? 'Duplicate name, enter a different value' : undefined}
                />
            </>
        );
    }

    function CloseBatchFields() {
        return <Text variant="mediumPlus">{`Are you sure  you want to close batch ${selectedBatch?.displayName}? `}</Text>;
    }

    function ReopenBatchFields() {
        return <Text variant="mediumPlus">{`Are you sure  you want to reopen batch ${selectedBatch?.displayName}? `}</Text>;
    }
}
