import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IChartProcedure } from 'api/models/chart.model';
import IForm from 'api/models/form';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { IChartNotesState } from '../chart-notes.state';
import {
    getQuickNoteProcedures,
    getQuickNoteForms,
    getQuickNoteNextAppointment,
    fetchPAMV,
    IPAMVAssetPayload,
} from './quick-notes.actions';
import { getUserIdentities } from '../../users-identities/user-identities.actions';

export const quickNotesExtraReducers = (builder: ActionReducerMapBuilder<IChartNotesState>): void => {
    builder
        .addCase(getQuickNoteProcedures.pending, (state) => {
            state.quickNotes.assets.chartProcedures.loading = LoadingStatus.Pending;
        })
        .addCase(
            getQuickNoteProcedures.fulfilled,
            (
                state,
                action: PayloadAction<{
                    completed: IChartProcedure[];
                    treatmentPlanned: IChartProcedure[];
                    referred: IChartProcedure[];
                    preAuthRequired: boolean;
                }>,
            ) => {
                const { completed, referred, treatmentPlanned, preAuthRequired } = action.payload;
                state.quickNotes.assets.chartProcedures.loading = LoadingStatus.Completed;

                state.quickNotes.assets.chartProcedures.completed = completed;
                state.quickNotes.assets.chartProcedures.referred = referred;
                state.quickNotes.assets.chartProcedures.treatmentPlanned = treatmentPlanned;
                state.quickNotes.assets.chartProcedures.preAuthRequired = preAuthRequired;
            },
        )
        .addCase(getQuickNoteProcedures.rejected, (state) => {
            state.quickNotes.assets.chartProcedures.loading = LoadingStatus.Failed;
        })
        .addCase(getQuickNoteForms.pending, (state) => {
            state.quickNotes.assets.forms.loading = LoadingStatus.Pending;
        })
        .addCase(
            getQuickNoteForms.fulfilled,
            (
                state: IChartNotesState,
                action: PayloadAction<{
                    cariesRisk?: IForm;
                    dentalHistory?: IForm;
                    softTissueExam?: IForm;
                    patientProblem?: IForm;
                }>,
            ) => {
                const { cariesRisk, dentalHistory, softTissueExam, patientProblem } = action.payload;
                state.quickNotes.assets.forms.loading = LoadingStatus.Completed;

                state.quickNotes.assets.forms.cariesRisk = cariesRisk;
                state.quickNotes.assets.forms.dentalHistory = dentalHistory;
                state.quickNotes.assets.forms.softTissueExam = softTissueExam;
                state.quickNotes.assets.forms.patientProblem = patientProblem;
            },
        )
        .addCase(getQuickNoteForms.rejected, (state) => {
            state.quickNotes.assets.forms.loading = LoadingStatus.Failed;
        })
        .addCase(getQuickNoteNextAppointment.pending, (state) => {
            state.quickNotes.assets.schedule.loading = LoadingStatus.Pending;
        })
        .addCase(getQuickNoteNextAppointment.fulfilled, (state, action: PayloadAction<IPatientAppointment | undefined>) => {
            const nextAppointment = action.payload;
            state.quickNotes.assets.schedule.loading = LoadingStatus.Completed;
            state.quickNotes.assets.schedule.nextAppointment = nextAppointment;
        })
        .addCase(getQuickNoteNextAppointment.rejected, (state) => {
            state.quickNotes.assets.schedule.loading = LoadingStatus.Failed;
        })
        .addCase(fetchPAMV.pending, (state) => {
            state.quickNotes.assets.pamv.loading = LoadingStatus.Pending;
        })
        .addCase(fetchPAMV.fulfilled, (state, aciton: PayloadAction<IPAMVAssetPayload>) => {
            const { problems, allergies, medications, vitals } = aciton.payload;
            state.quickNotes.assets.pamv.loading = LoadingStatus.Completed;

            state.quickNotes.assets.pamv.allergies = allergies;
            state.quickNotes.assets.pamv.medications = medications;
            state.quickNotes.assets.pamv.problems = problems;
            state.quickNotes.assets.pamv.vitals = vitals;
        })
        .addCase(fetchPAMV.rejected, (state) => {
            state.quickNotes.assets.pamv.loading = LoadingStatus.Failed;
        });
};
