import { createSlice } from '@reduxjs/toolkit';
import editPatientReducers, { editPatientExtraReducers } from './edit-patient.reducers';
import editPatientInitialState from './edit-patient.state';
import insurancesReducers from './insurances/insurances.reducers';

const editPatientSlice = createSlice({
    name: 'editPatient',
    initialState: editPatientInitialState,
    reducers: { ...editPatientReducers, ...insurancesReducers },
    extraReducers: editPatientExtraReducers,
});

export const {
    // [Edit Patient]
    setEditPatient,
    setIsNewPatient,
    setEditPatientPanelOpen,
    cleanupEditPatientPanel,
    cleanupEditPatient,
    editPatientPropChange,
    setPatientPhoneNumbers,
    editPatientAddressPropChange,
    editPatientSignaturePropChange,
    editPatientUDSPropChange,
    editPatientSlidingFeePropChange,
    setEditPatientHasChange,
    setEditPatientValidationErrors,
    editPatientMailingAddressPropChange,
    removePatientSlidingFee,

    // [Insurance]
    setInsurancePrimary,
    toggleInsuranceActive,
    saveInsurance,
    toggleShowInsuranceHistory,
} = editPatientSlice.actions;

export default editPatientSlice.reducer;
