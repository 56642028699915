import { IDropdownOption } from '@fluentui/react';
import IPatientAppointment, { IAppointmentProcedure } from 'api/models/Scheduling/patientAppointment.model';
import { isBefore, isAfter, format } from 'date-fns';
import { IValidationError } from 'hooks/useValidation';
import { flatten, map, some, uniqBy } from 'lodash';
import { createSelector } from 'reselect';
import { payersList } from 'state/slices/tenant/payers.slice';
import { selectProceduresData } from 'state/slices/tenant/procedures.slice';
import { selectProvidersAsList } from 'state/slices/tenant/providers.slice';
import { RootState } from 'state/store';
import convertDashedDateString from 'utils/convertDateStringToLocal';
import { convertStringTimeToDate } from 'utils/convertStringTimeToDate';
import { getDayOfWeekString } from 'utils/getDayOfWeekString';
import { getTimeOptions } from 'utils/getTimeOptions';
import { selectCurrentOperatories, selectCurrentOperatoriesFromSelectedApptLoc } from '../../lookups/operatories/operatories.selectors';
import {
    IBlockAppointmentEvent,
    IPatientAppointmentEvent,
    IPatientAppointmentView,
    selectAllocations,
    selectAppointmentType,
} from '../scheduling.selectors';
import { AppointmentType } from '../scheduling.state';
import { ISelectedAppointmentState } from './schedule-appointment.state';

export const selectScheduleAppointmentState = (state: RootState): ISelectedAppointmentState =>
    state.scheduling.selectedAppointment;

export const selectCurrentScheduleAppointment = createSelector(selectScheduleAppointmentState, (state) => state.data);
export const selectCurrentScheduleDate = createSelector(selectScheduleAppointmentState, (state) => state.data?.date);
export const selectCurrentScheduleAppointmentProcedures = createSelector(
    selectScheduleAppointmentState,
    (state) => (state.data as IPatientAppointment)?.procedures ?? [],
);
export const selectCurrentScheduleAppointmentLoading = createSelector(selectScheduleAppointmentState, (state) => state.loading);
export const selectCurrentAppointmentSelectedPhasesLookup = createSelector(
    selectScheduleAppointmentState,
    (state) => state.selectedPhases,
);

export const selectCurrentAppointmentSelectedProcedures = createSelector(
    selectCurrentAppointmentSelectedPhasesLookup,
    (lookup) => {
        return uniqBy(
            flatten(flatten(map(lookup, (item) => (item ? item.map((i) => i.phaseProcedures) : [])))).map(
                (procedure) =>
                    ({
                        procedureId: procedure.procedureId,
                        code: procedure.procedureCode,
                        toothIds: procedure.toothIds,
                        treatmentPlanPhaseProcedureId: procedure.id,
                    } as IAppointmentProcedure),
            ),
            (proc) => proc.treatmentPlanPhaseProcedureId,
        );
    },
);

export const selectCalendarAllocations = createSelector(
    [selectAllocations, selectProvidersAsList, selectProceduresData, payersList],
    (allocation, providers, procedureLookup, payers) => {
        if (allocation) {
            const ptAllocations = allocation?.patients?.length
                ? allocation.patients
                      .filter((ptAppointment) => !ptAppointment.isDeleted)
                      .map((ptAppointment) => {
                          const [year, month, day] = ptAppointment.date ? ptAppointment.date.split('-') : [];
                          const apptDate = new Date(Number.parseInt(year), Number.parseInt(month) - 1, Number.parseInt(day));
                          const ptProvider = providers.find((provider) => provider?.id === ptAppointment.treatingProviderId);
                          const ptHygienist = providers.find((provider) => provider?.id === ptAppointment.hygienistId);
                          const ptPayer = payers.find((payer) => payer?.id === ptAppointment.patient?.insuranceId);
                          const procedureCodeList: string[] =
                              ptAppointment.procedures && ptAppointment.procedures.length
                                  ? (ptAppointment.procedures
                                        .map((apptProc) =>
                                            apptProc.procedureId ? procedureLookup[apptProc.procedureId]?.code ?? '' : '',
                                        )
                                        .filter((p) => p !== undefined) as string[])
                                  : [];

                          const appointmentStatusOption = [
                              { key: 'unconfirmed', text: 'Unconfirmed' },
                              { key: 'confirmedTxt', text: 'Confirmed, TXT' },
                              { key: 'confirmedPhone', text: 'Confirmed, Phone' },
                              { key: 'leftMsg', text: 'Left Msg, Family/Friend' },
                              { key: 'leftVoicemail', text: 'Left Voicemail' },
                              { key: 'disconnected', text: 'Disconnected' },
                              { key: 'wrongNumber', text: 'Wrong Number' },
                              { key: 'noAnswer', text: 'No Answer' },
                          ].find((option) => option.key === ptAppointment.appointmentStatusId);

                          const extendedProps: IPatientAppointmentView = {
                              ...ptAppointment,
                              procedureCodeList: procedureCodeList,
                              patientFullName: `${ptAppointment.patient?.firstName} ${ptAppointment.patient?.lastName}`,
                              insuranceName: ptPayer?.name,
                              appointmentStatus: appointmentStatusOption?.text,
                              providerDisplayName:
                                  ptProvider?.isTreatingProvider || ptProvider?.isAttestingHygienist
                                      ? `${ptProvider?.firstName} ${ptProvider?.lastName}`
                                      : 'N/A',
                              hygienistDisplayName: ptHygienist?.isHygienist
                                  ? `${ptHygienist?.firstName} ${ptHygienist?.lastName}`
                                  : 'N/A',
                              providerType: 'Treating Provider',
                              providerColor: ptAppointment.hygienistId
                                  ? ptHygienist?.providerSchedule?.scheduleColor
                                  : ptProvider?.providerSchedule?.scheduleColor,
                              type: AppointmentType.Patient,
                          };

                          const calendarEvent: IPatientAppointmentEvent = {
                              id: ptAppointment.id,
                              title: '',
                              resourceId: ptAppointment.operatoryId,
                              start: convertStringTimeToDate(apptDate, ptAppointment.startTime ? ptAppointment.startTime : ''),
                              end: convertStringTimeToDate(apptDate, ptAppointment.endTime ? ptAppointment.endTime : ''),
                              extendedProps,
                          };
                          return calendarEvent;
                      })
                : [];

            const blockAllocations = allocation?.blocks?.length
                ? allocation.blocks
                      .filter((blockAppointment) => !blockAppointment.isDeleted)
                      .map((blockAppointment) => {
                          const [year, month, day] = blockAppointment.date ? blockAppointment.date.split('-') : [];
                          const apptDate = new Date(Number.parseInt(year), Number.parseInt(month) - 1, Number.parseInt(day));
                          const blockAppoinmentType = [
                              { key: 'option-1', text: 'Lunch' },
                              { key: 'option-2', text: 'Continuing Education' },
                              { key: 'option-3', text: 'Private Appointment' },
                              { key: 'option-4', text: 'Meeting' },
                              { key: 'option-5', text: 'Other' },
                          ].find((option) => option.key === blockAppointment.typeId);

                          const calendarEvent: IBlockAppointmentEvent = {
                              id: blockAppointment.id,
                              title: blockAppoinmentType?.text ?? '',
                              resourceId: blockAppointment.operatoryId,
                              start: blockAppointment.startTime
                                  ? convertStringTimeToDate(apptDate, blockAppointment.startTime)
                                  : '',
                              end: blockAppointment.endTime ? convertStringTimeToDate(apptDate, blockAppointment.endTime) : '',

                              backgroundColor: 'gray',
                              extendedProps: {
                                  ...blockAppointment,
                                  type: AppointmentType.Block,
                                  providers: blockAppointment.providers,
                              },
                          };

                          return calendarEvent;
                      })
                : [];
            return [...ptAllocations, ...blockAllocations];
        }
        return [];
    },
);

export const selectCurrentAppointmentOperatories = createSelector(
    selectCalendarAllocations,
    selectCurrentScheduleAppointment,
    (allocations, currentAppointment) =>
        allocations.filter((r) => {
            return r.resourceId === currentAppointment?.operatoryId;
        }),
);

export const selectCurrentScheduleAppointmentValidationErrors = createSelector(
    selectCurrentScheduleAppointment,
    selectCurrentAppointmentOperatories,
    selectAppointmentType,
    selectCurrentScheduleDate,
    (appointment, allocations, appointmentType, date) => {
        const isBlockAppt = appointmentType === AppointmentType.Block;
        const errors: IValidationError[] = [];

        const convertedDate = convertDashedDateString(date);

        const startDate = appointment?.startTime
            ? convertStringTimeToDate(new Date(convertedDate), appointment.startTime)
            : new Date(convertedDate);
        const endDate = appointment?.endTime
            ? convertStringTimeToDate(new Date(convertedDate), appointment.endTime)
            : new Date(convertedDate);

        if (startDate > endDate) errors.push({ fieldName: 'Start', errorTypes: ['start'] });

        if (allocations?.length && !isBlockAppt) {
            const notThisAllocations = allocations.filter((allocation) => allocation.id !== appointment?.id);
            const conflictingAppointments = notThisAllocations.filter((allocation) => {
                if (allocation.start && allocation.end) {
                    const allocationStartTime = new Date(allocation.start.toString()).getTime();
                    const allocationEndTime = new Date(allocation.end.toString()).getTime();

                    return isBefore(startDate, allocationEndTime) && isAfter(endDate, allocationStartTime);
                }
                return false;
            });
            if (conflictingAppointments.length) {
                errors.push({ fieldName: 'Start and End', errorTypes: ['start', 'end'] });
            }
        }
        return uniqBy(errors, (err) => err.errorTypes);
    },
);

export const selectedCurrentAppointmentOperatory = createSelector(
    selectCurrentOperatoriesFromSelectedApptLoc,
    selectCurrentScheduleAppointment,
    (operatories, appointment) => {
        const selectedOperatory = operatories.find((op) => op.id === appointment?.operatoryId);
        return selectedOperatory;
    },
);

export const selectCurrentOperatoryAppointmentTimetable = createSelector(
    selectedCurrentAppointmentOperatory,
    selectCurrentScheduleAppointment,
    (operatory, appointment) => {
        const startTimes: Date[] = [];
        const endTimes: Date[] = [];

        operatory?.periods?.forEach((period) => {
            const dateToUse = format(new Date(convertDashedDateString(appointment?.date) || new Date()), 'MM-dd-yyyy');
            const today = new Date(convertDashedDateString(appointment?.date) || new Date());
            const weekDay = getDayOfWeekString(today);

            if (period.timetables && weekDay) {
                const _timeTables = period.timetables[weekDay];
                if (_timeTables && _timeTables.length) {
                    _timeTables?.forEach((item) => {
                        const todayStartTime = new Date(`${dateToUse}  ${item.startTime}`);
                        const todayEndTime = new Date(`${dateToUse}  ${item.endTime}`);
                        startTimes.push(todayStartTime);
                        endTimes.push(todayEndTime);
                    });
                }
            }
        });

        return { startTimes, endTimes };
    },
);

export const selectTimeOptionWithinRangeBusinessHours = createSelector(
    selectCurrentOperatoryAppointmentTimetable,
    selectCurrentScheduleAppointment,
    (currentOperatoryTimetable, appointment) => {
        const timeOptionsWithinRangeBusinessHours: IDropdownOption<string>[] = [];
        const timeOptionsByTimeString = getTimeOptions();

        //Assume the index of a start time will always equal the relative end time.
        //startTimes[2] endTimes[2] range
        for (let i = 0; i < timeOptionsByTimeString.length - 1; i++) {
            const timeOption = timeOptionsByTimeString[i];
            const timeOptionValue = timeOption.key as string;
            const convertedTimeOptionValue = appointment?.date
                ? convertStringTimeToDate(new Date(convertDashedDateString(appointment?.date)), timeOptionValue).getTime()
                : 0;

            const isTimeOptionValid = some(currentOperatoryTimetable.startTimes, (startTimeDate, index) => {
                const endTime = currentOperatoryTimetable.endTimes[index].getTime();
                const startTime = startTimeDate.getTime();

                return convertedTimeOptionValue >= startTime && convertedTimeOptionValue <= endTime;
            });

            if (isTimeOptionValid) timeOptionsWithinRangeBusinessHours.push(timeOption);
        }
        return timeOptionsWithinRangeBusinessHours;
    },
);
