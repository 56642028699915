import { Stack } from '@fluentui/react';
import { Field, TText } from 'components';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { selectWorkListFilters } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { toggleWorkListFilterPropItem } from 'state/slices/admin-huddle/worklists/worklist.slice';
import { selectTenantPayersWithDisplayNameOption } from 'state/slices/tenant/tenant-payers.slice';

export default function PrimaryPayerSourceFilter() {
    const dispatch = useDispatch();

    const { payers } = useSelector(selectWorkListFilters<any>);
    const payerSourceOptions = useSelector(selectTenantPayersWithDisplayNameOption);

    const handlePayerMultiSelect = (payerId: string) => {
        dispatch(toggleWorkListFilterPropItem({ path: 'payers', value: payerId }));
    };

    const staticOptions = [
        { key: 'Self Pay', text: 'Self Pay' },
        { key: 'Sliding Fee', text: 'SFS-Payer' },
    ];

    const combinedOptions = [...staticOptions, ...payerSourceOptions];

    return (
        <Stack.Item grow>
            <Field.SearchCombo
                placeholder="Select"
                multiSelect={true}
                label="Primary Payer Source"
                selectedKey={payers ?? []}
                options={combinedOptions}
                onChange={(e, option) => {
                    if (option) handlePayerMultiSelect(option.key as string);
                }}
                onRenderOption={(props) => (props?.itemType ? <span>{props.text}</span> : <TText>{props?.text}</TText>)}
            ></Field.SearchCombo>
        </Stack.Item>
    );
}
