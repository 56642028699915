import { Icon, ITextFieldProps, Stack, TextField } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { getValidationError, IValidationError } from 'hooks/useValidation';
import { Cookies } from 'interfaces/cookies';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { selectBatches } from 'state/slices/tenant/batches.slice';
import { BatchPanel } from './BatchPanel';

type Props = {
    textfieldProps?: ITextFieldProps;
    error?: IValidationError[];
};

export function BatchField(props: Props): JSX.Element {
    const [cookies] = useCookies();

    const { data: batches } = useSelector(selectBatches);
    const selectedBatch = batches[cookies[Cookies.SelectedBatch]];

    const [isBatchPanelOpen, { setTrue: setBatchPanelOpen, setFalse: setBatchPanelClosed }] = useBoolean(false);

    return (
        <Stack horizontal>
            <BatchPanel isOpen={isBatchPanelOpen} onDismiss={setBatchPanelClosed} />
            <Stack grow>
                <TextField
                    onRenderSuffix={() => <Icon style={{ cursor: 'pointer' }} iconName={'Search'} onClick={setBatchPanelOpen} />}
                    value={selectedBatch?.displayName ?? ''}
                    readOnly
                    required
                    errorMessage={
                        getValidationError(props.error ?? [], 'Batch') ? 'Batch is required.' : props.textfieldProps?.errorMessage
                    }
                    {...props.textfieldProps}
                />
            </Stack>
        </Stack>
    );
}
