import { createAsyncThunk, createSelector, createSlice, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { AxiosResponse, AxiosError } from 'axios';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { RootState } from 'state/store';
import { ITenantPayer } from 'api/models/payer.model';
import { filter, map } from 'lodash';
import { selectPayerDetailsData } from './payer-details.slice';
import { IDropdownOption } from '@fluentui/react';
import { payersList, selectPayerData } from './payers.slice';

type PayerDetailsState = {
    initialLoad: LoadingStatuses;
    loading: LoadingStatuses;
    data?: Dictionary<ITenantPayer>;
    errors?: string;
};

const initialState: PayerDetailsState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
};

export const getTenantPayers = createAsyncThunk<
    AxiosResponse<Dictionary<ITenantPayer>>,
    { tenantId: string },
    {
        rejectValue: AxiosError;
    }
>('getTenantPayers', async ({ tenantId }) => {
    const res = await dentalApi.getTenantPayers(tenantId);
    return res;
});

const tenantPayersSlice = createSlice({
    name: 'tenantPayer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTenantPayers.pending, (state) => {
                state.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getTenantPayers.fulfilled, (state, action) => {
                state.initialLoad = LoadingStatus.Completed;
                state.data = action.payload.data;
            })
            .addCase(getTenantPayers.rejected, (state, action) => {
                state.initialLoad = LoadingStatus.Failed;
                state.errors = action.payload?.message;
            });
    },
});

const { reducer } = tenantPayersSlice;
export const selectTenantPayers = (state: RootState): PayerDetailsState => state.tenant.tenantPayers;

export const selectTenantPayersData = createSelector(selectTenantPayers, (state) => state?.data ?? {});

export const tenantPayersList = createSelector(
    [selectTenantPayersData],
    (payers) => map(payers).filter((insurance) => insurance !== undefined) as ITenantPayer[],
);

export const selectTenantPayerOptions = createSelector([tenantPayersList], (payers) => {
    const options: IDropdownOption[] = payers.map((p) => ({
        key: p.id ?? '',
        data: p.payerId,
        text: p.name ?? 'Unknown Payer',
    }));

    return options;
});

export type PayerWithDisplayName = ITenantPayer & { displayName?: string };
export const selectTenantPayersWithDisplayName = createSelector(
    selectTenantPayersData,
    selectPayerDetailsData,
    (payers, payerDetails) => {
        if (Object.keys(payers).length === 0) return [];
        return map(filter(payers, (payer) => payer !== undefined) as ITenantPayer[], (payer) => {
            const payerDetail = payerDetails[payer.id];

            return {
                ...payer,
                displayName: payerDetail?.displayName,
            } as PayerWithDisplayName;
        });
    },
);

export const selectTenantPayersWithDisplayNameOption = createSelector(selectTenantPayersWithDisplayName, (payers) => {
    const option: IDropdownOption[] = payers.map((payer) => ({
        key: payer.id,
        text: payer.displayName ? payer.displayName : payer.name ? payer.name : 'Unknown Payer',
    }));
    return option;
});

export const nonDeletedTenantPayersWithDisplayName = createSelector(
    selectTenantPayersWithDisplayName,
    selectPayerData,
    (payers, platformPayerList) => {
        if (!payers.length) return [];

        return payers.map((payer) => {
            const platformPayer = platformPayerList[payer.id];
            const payerWithDisplayname: PayerWithDisplayName = {
                ...payer,
                // If player is deleted on the platform, set isDeleted to true, otherwise use the payer.isDeleted (dental) value
                isDeleted: platformPayer?.isDeleted ? true : payer.isDeleted,
            };

            return payerWithDisplayname;
        });
    },
);

export default reducer;
