import { CommandBar, ScrollablePane, Stack, Toggle } from '@fluentui/react';
import { Cookies } from 'interfaces/cookies';
import { RouteParams } from 'interfaces/route-params';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
    cleanupTreatmentPlanCreditTransactions,
    clearAllTreatmentPlanCreditTransactions,
    payAllTreatmentPlanCreditTransactions,
    toggleTreatmentPlanCreditOverpayment,
} from 'state/slices/ledger/ledger.slice';
import { createAddTreatmentPlanCreditInformation } from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.actions';
import {
    selectSelectedTreatmentPlanCreditPhaseBalanceRemaining,
    selectTreamentPlanCreditPaymentSource,
    selectTreatmentPlanCreditAllowOverpayment,
    selectTreatmentPlanCreditCurrentPhaseView,
    selectTreatmentPlanCreditCurrentPhaseViewBillingProcedures,
} from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.selectors';
import { selectBatches } from 'state/slices/tenant/batches.slice';
import TreatmentPlanCreditAndUACSection from './TreatmentPlanCreditAndUACSection';
import AddTreatmentPlanPrepaymentList from './AddTreatmentPlanPrepaymentList';
import { Helmet } from 'react-helmet';

export default function AddTreatmentPlanPrepayment() {
    const dispatch = useDispatch();
    const { push } = useHistory();

    const { patientId, tenantId, encounterId } = useParams<RouteParams>();

    const [cookies] = useCookies();
    const { data: batches } = useSelector(selectBatches);
    const selectedBatch = batches[cookies[Cookies.SelectedBatch]];

    const paymentSource = useSelector(selectTreamentPlanCreditPaymentSource);
    const remainingAmount = useSelector(selectSelectedTreatmentPlanCreditPhaseBalanceRemaining);
    const currentPhaseView = useSelector(selectTreatmentPlanCreditCurrentPhaseView);
    const selectedBillingProcedures = useSelector(selectTreatmentPlanCreditCurrentPhaseViewBillingProcedures);

    const overpayment = useSelector(selectTreatmentPlanCreditAllowOverpayment);

    const _clearAll = () => {
        dispatch(clearAllTreatmentPlanCreditTransactions());
    };

    const _payAll = () => {
        dispatch(payAllTreatmentPlanCreditTransactions(paymentSource?.amount ?? 0));
    };

    const _toggleOverpayment = () => {
        dispatch(toggleTreatmentPlanCreditOverpayment());
    };

    useEffect(() => {
        if (!currentPhaseView && patientId) {
            const returnUrl = encounterId
                ? `/${tenantId}/patient/${patientId}/encounter/${encounterId}/ledger/treatment-plan-prepayment-uac`
                : `/${tenantId}/patient/${patientId}/ledger/treatment-plan-prepayment-uac`;
            push(returnUrl);
        }
    }, []);

    useEffect(() => {
        if (patientId) dispatch(createAddTreatmentPlanCreditInformation(patientId, selectedBatch, selectedBillingProcedures));
        return () => {
            dispatch(cleanupTreatmentPlanCreditTransactions());
        };
    }, []);

    return (
        <Stack grow>
            <Helmet title="Ledger - Prepayment and UAC - Add TP Prepayment" />
            <TreatmentPlanCreditAndUACSection
                headingRightContent={
                    <Toggle
                        styles={{ root: { marginBottom: 0, paddingLeft: 20 }, label: { padding: 0 } }}
                        label="Allow Overpayment"
                        inlineLabel
                        onChange={_toggleOverpayment}
                        checked={overpayment}
                    />
                }
                hasNotes
                balance={currentPhaseView?.remainingPatientEstimate ?? 0}
                heading="Add Treatment Plan Prepayment"
            />
            <Stack grow>
                <CommandBar
                    styles={{ root: { paddingLeft: 0 } }}
                    items={[
                        {
                            key: 'payAll',
                            text: 'Distribute Payment',
                            iconProps: { iconName: 'Add' },
                            onClick: _payAll,
                            disabled: overpayment,
                        },
                        {
                            key: 'clearAll',
                            text: 'Clear All',
                            iconProps: { iconName: 'Cancel' },
                            onClick: _clearAll,
                        },
                    ]}
                />

                <div style={{ position: 'relative', flex: 1, display: 'flex' }}>
                    <ScrollablePane>
                        <AddTreatmentPlanPrepaymentList
                            remainingAmount={remainingAmount}
                            billingProcedures={selectedBillingProcedures}
                        />
                    </ScrollablePane>
                </div>
            </Stack>
        </Stack>
    );
}
