import { Cookies } from 'interfaces/cookies';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { selectBatches } from 'state/slices/tenant/batches.slice';

export default function useSelectedBatch() {
    const [cookies] = useCookies();
    const { data: batches } = useSelector(selectBatches);

    return batches[cookies[Cookies.SelectedBatch]];
}
