import { MessageBarType } from '@fluentui/react';
import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { IWorkListsState } from '../worklist.state';
import {
    createAmendmentNote,
    getEncounterCorrespondenceEncounterWorkList,
    isWorkListEncounterView,
    markEncountersAsBilled,
    markEncountersAsRebilled,
    recalculateEncounter,
    saveEncounterCorrespondenceEncounterWorkList,
    saveEncounterStatusEncounterWorkList,
    setOnHoldStatus,
    updateEncounterProviderByProviderIdType,
    updateEncounterWorkListEncounterNote,
    toggleEncounterWorkListItemIsRcm,
    updateWorkListEncounterData,
    voidEncounterWorkListNonClinicalProcedure,
} from './encounter-worklists.actions';
import { CaseReducerFunction } from 'state/store';
import { EncounterWorkListModalType, EncounterWorkListNoteModalAction } from './encounter-worklists.state';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { IEncounterCorrespondence } from 'api/models/encounter-claim-summary.model';

export const encounterWorkListsReducers = {
    //Billing Procedure to Void
    setBillingProcedureToVoid: (state: IWorkListsState, action: PayloadAction<IBillingProcedure | undefined>): void => {
        state.billingProcedureToVoid = action.payload;
    },
    //Note Modal
    setEncounterWorkListModalOpen: (
        state: IWorkListsState,
        action: PayloadAction<{ isOpen: boolean; type?: EncounterWorkListModalType; editEncounter?: IWorkListEncounterView }>,
    ): void => {
        const { isOpen, type, editEncounter } = action.payload;
        state.encounterWorkListNoteModal.isOpen = isOpen;
        if (type) state.encounterWorkListNoteModal.type = type;
        if (editEncounter) {
            state.editWorkListItem = editEncounter;

            if (type && editEncounter?.encounter) {
                const encounterNoteLookup: Partial<Record<EncounterWorkListModalType, string | undefined>> = {
                    [EncounterWorkListModalType.AdministrativeHold]: editEncounter.encounter.administrativeHold?.note,
                    [EncounterWorkListModalType.BillingNote]: editEncounter.encounter.billingNote,
                };
                if (encounterNoteLookup[type]) state.encounterWorkListNoteModal.currentNote = encounterNoteLookup[type];
            }
        }
    },
    insertEncounterWorkListCorrespondence: (
        state: IWorkListsState,
        action: PayloadAction<{ encounterId: string; data: IEncounterCorrespondence }>,
    ): void => {
        const { data, encounterId } = action.payload;
        if ((state.editWorkListItem as IWorkListEncounterView | undefined)?.id === encounterId) {
            if (!state.encounterWorkListNoteModal.correspondence) {
                state.encounterWorkListNoteModal.correspondence = [data];
            } else {
                state.encounterWorkListNoteModal.correspondence = [...state.encounterWorkListNoteModal.correspondence, data];
            }
        }
    },
    setEncounterWorkListModalNote: (state: IWorkListsState, action: PayloadAction<string | undefined>): void => {
        state.encounterWorkListNoteModal.currentNote = action.payload;
    },
    setEncounterWorkListModalAction: (state: IWorkListsState, action: PayloadAction<EncounterWorkListNoteModalAction>): void => {
        state.encounterWorkListNoteModal.selectedAction = action.payload;
    },
    setEncounterWorkListModalMessageBar: (
        state: IWorkListsState,
        action: PayloadAction<{ messageBarType: MessageBarType | undefined; message: string | undefined }>,
    ) => {
        state.encounterWorkListNoteModal.messageBarType = action.payload.messageBarType;
        state.encounterWorkListNoteModal.messageBarMessage = action.payload.message;
    },
    cleanupEncounterWorkListMessageBar: (state: IWorkListsState): void => {
        state.encounterWorkListNoteModal.messageBarType = undefined;
        state.encounterWorkListNoteModal.messageBarMessage = undefined;
    },
    cleanupEncounterWorkListModal: (state: IWorkListsState): void => {
        state.encounterWorkListNoteModal.messageBarType = undefined;
        state.encounterWorkListNoteModal.messageBarMessage = undefined;
        state.encounterWorkListNoteModal.correspondence = undefined;
        state.encounterWorkListNoteModal.currentNote = undefined;
        state.encounterWorkListNoteModal.type = undefined;
        state.encounterWorkListNoteModal.selectedAction = undefined;
        state.encounterWorkListNoteModal.savingNoteData = LoadingStatus.Idle;
        state.encounterWorkListNoteModal.savingIsRcm = LoadingStatus.Idle;
        state.encounterWorkListNoteModal.loadingNoteData = LoadingStatus.Idle;
        state.editWorkListItem = undefined;
    },
};

export const encounterWorkListExtraReducers = (builder: ActionReducerMapBuilder<IWorkListsState>) => {
    builder
        .addCase(getEncounterCorrespondenceEncounterWorkList.fulfilled, (state, action) => {
            state.encounterWorkListNoteModal.loadingNoteData = LoadingStatus.Completed;
            state.encounterWorkListNoteModal.correspondence = action.payload;
        })
        .addCase(getEncounterCorrespondenceEncounterWorkList.pending, (state) => {
            state.encounterWorkListNoteModal.loadingNoteData = LoadingStatus.Pending;
        })
        .addCase(getEncounterCorrespondenceEncounterWorkList.rejected, (state) => {
            state.encounterWorkListNoteModal.loadingNoteData = LoadingStatus.Failed;
        })
        .addCase(saveEncounterCorrespondenceEncounterWorkList.fulfilled, (state, action) => {
            if (action.payload) {
                state.encounterWorkListNoteModal.savingNoteData = LoadingStatus.Completed;
                state.encounterWorkListNoteModal.correspondence = action.payload;
                state.messageBarMessage =
                    state.messageBarMessage && state.messageBarMessage === 'Sent encounter successfully.'
                        ? `${state.messageBarMessage.replace('successfully.', '')} and saved notes successfully.`
                        : 'Saved encounter notes successfully.';
                state.messageBarType = MessageBarType.success;
            } else {
                state.encounterWorkListNoteModal.savingNoteData = LoadingStatus.Idle;
            }
        })
        .addCase(saveEncounterCorrespondenceEncounterWorkList.pending, (state) => {
            state.encounterWorkListNoteModal.savingNoteData = LoadingStatus.Pending;
        })
        .addCase(saveEncounterCorrespondenceEncounterWorkList.rejected, (state) => {
            state.encounterWorkListNoteModal.savingNoteData = LoadingStatus.Failed;
        })
        .addCase(saveEncounterStatusEncounterWorkList.fulfilled, (state) => {
            state.saving = LoadingStatus.Completed;
            state.editWorkListItem = undefined;
            state.messageBarMessage = 'Sent encounter successfully.';

            state.messageBarMessage =
                state.messageBarMessage && state.messageBarMessage === 'Saved encounter notes successfully.'
                    ? `${state.messageBarMessage.replace('successfully.', '')} and sent encounter successfully.`
                    : 'Sent encounter successfully.';
            state.messageBarType = MessageBarType.success;
        })
        .addCase(saveEncounterStatusEncounterWorkList.pending, (state) => {
            state.saving = LoadingStatus.Pending;
        })
        .addCase(saveEncounterStatusEncounterWorkList.rejected, (state, action) => {
            state.saving = LoadingStatus.Failed;
            state.messageBarMessage = action.payload;
            state.messageBarType = MessageBarType.error;
        })
        //Ready to review
        .addCase(markEncountersAsBilled.pending, (state) => {
            state.saving = LoadingStatus.Pending;
        })
        .addCase(markEncountersAsBilled.fulfilled, handleMarkEncounterBilledRebilledCase)
        .addCase(markEncountersAsBilled.rejected, (state) => {
            state.saving = LoadingStatus.Failed;

            state.messageBarMessage = 'Failed to bill the selected encounter(s).';
            state.messageBarType = MessageBarType.error;
        })
        .addCase(markEncountersAsRebilled.pending, (state) => {
            state.saving = LoadingStatus.Pending;
        })
        .addCase(markEncountersAsRebilled.fulfilled, handleMarkEncounterBilledRebilledCase)
        .addCase(markEncountersAsRebilled.rejected, (state) => {
            state.saving = LoadingStatus.Failed;

            state.messageBarMessage = 'Failed to rebill the selected encounter(s).';
            state.messageBarType = MessageBarType.error;
        })
        .addCase(updateEncounterProviderByProviderIdType.fulfilled, (state, { payload, meta }) => {
            if (payload) {
                if (state.data?.length) {
                    const indexOfData = (state.data as IWorkListEncounterView[]).findIndex((item) => item.id === payload.id);
                    if (indexOfData > -1)
                        (state.data[indexOfData] as IWorkListEncounterView) = {
                            ...(state.data[indexOfData] as IWorkListEncounterView),
                            encounter: payload,
                            [meta.arg.providerIdType]: payload[meta.arg.providerIdType],
                        };
                }

                if (state.editWorkListItem) {
                    (state.editWorkListItem as IWorkListEncounterView).encounter = payload;

                    if (payload[meta.arg.providerIdType])
                        (state.editWorkListItem as any)[meta.arg.providerIdType] = payload[meta.arg.providerIdType];
                }
            }
        })
        .addCase(createAmendmentNote.fulfilled, (state, { payload }) => {
            if (state.data?.length) {
                state.data = (state.data as IWorkListEncounterView[]).filter(
                    (encounter) => encounter.encounter?.id !== payload.encounterId,
                );
            }
        })

        .addCase(setOnHoldStatus.pending, (state) => {
            state.encounterWorkListNoteModal.savingNoteData = LoadingStatus.Pending;
        })
        .addCase(setOnHoldStatus.fulfilled, (state, { payload }) => {
            state.encounterWorkListNoteModal.savingNoteData = LoadingStatus.Completed;
            if (state.data?.length) {
                const indexOfEncounter = (state.data as IWorkListEncounterView[]).findIndex(
                    (view) => view.encounter?.id === payload.encounter?.id,
                );
                state.data = [...state.data.slice(0, indexOfEncounter), ...state.data.slice(indexOfEncounter + 1)];
            }

            state.encounterWorkListNoteModal.isOpen = false;

            state.messageBarMessage = 'Successfully saved reason for hold note and moved encounter to on hold.';
            state.messageBarType = MessageBarType.success;
        })
        .addCase(setOnHoldStatus.rejected, (state) => {
            state.encounterWorkListNoteModal.savingNoteData = LoadingStatus.Failed;
        })

        // Update Encounter On Hold Note
        .addCase(updateEncounterWorkListEncounterNote.pending, (state) => {
            state.encounterWorkListNoteModal.savingNoteData = LoadingStatus.Pending;
        })
        .addCase(updateEncounterWorkListEncounterNote.rejected, (state) => {
            state.encounterWorkListNoteModal.savingNoteData = LoadingStatus.Failed;
        })
        .addCase(updateEncounterWorkListEncounterNote.fulfilled, (state, { payload: encounter }) => {
            state.encounterWorkListNoteModal.savingNoteData = LoadingStatus.Completed;

            if (state.data?.length && encounter) {
                const indexOfEncounter = (state.data as IWorkListEncounterView[]).findIndex(
                    (view) => view.encounter?.id === encounter.id,
                );

                if (isWorkListEncounterView(encounter)) {
                    (state.data as IWorkListEncounterView[])[indexOfEncounter] = encounter;
                } else {
                    (state.data as IWorkListEncounterView[])[indexOfEncounter].encounter = encounter;
                }

                const noteModalType = state.encounterWorkListNoteModal.type;

                state.encounterWorkListNoteModal.isOpen = false;

                state.messageBarType = MessageBarType.success;
                if (noteModalType === EncounterWorkListModalType.AdministrativeHold)
                    state.messageBarMessage = 'Saved reason for hold note successfully.';
                if (noteModalType === EncounterWorkListModalType.BillingNote)
                    state.messageBarMessage = 'Saved billing note successfully.';
            }
        })

        // Recalculate an Encounter
        .addCase(recalculateEncounter.pending, (state) => {
            state.saving = LoadingStatus.Pending;
        })
        .addCase(recalculateEncounter.fulfilled, (state, { payload }) => {
            state.saving = LoadingStatus.Completed;
            if (state.data?.length) {
                const indexToUpdate = (state.data as IWorkListEncounterView[])?.findIndex(
                    (item) => item.encounter?.id === payload.encounter?.id,
                );
                state.data[indexToUpdate] = payload;
            }
        })
        .addCase(recalculateEncounter.rejected, (state) => {
            state.saving = LoadingStatus.Failed;
        })

        // Void Non-Clinical Procedure
        .addCase(voidEncounterWorkListNonClinicalProcedure.pending, (state) => {
            state.voidProcedureSaving = LoadingStatus.Pending;
        })
        .addCase(voidEncounterWorkListNonClinicalProcedure.fulfilled, (state, { payload }) => {
            state.voidProcedureSaving = LoadingStatus.Completed;

            if (payload?.encounterId && state.data?.length) {
                const indexOfView = (state.data as IWorkListEncounterView[]).findIndex(
                    (view) => view.encounter?.id === payload.encounterId,
                );
                if (indexOfView > -1) {
                    const indexOfProcedure = (state.data as IWorkListEncounterView[])[indexOfView].procedures.findIndex(
                        (p) => p.id === payload.id,
                    );
                    if (indexOfProcedure > -1)
                        (state.data as IWorkListEncounterView[])[indexOfView].procedures[indexOfProcedure] = payload;
                }
            }

            state.billingProcedureToVoid = undefined;

            state.messageBarMessage = 'Voided billing procedure successfully.';
            state.messageBarType = MessageBarType.success;
        })
        .addCase(voidEncounterWorkListNonClinicalProcedure.rejected, (state) => {
            state.voidProcedureSaving = LoadingStatus.Failed;

            state.messageBarMessage = 'Failed to void billing procedure.';
            state.messageBarType = MessageBarType.error;
        })
        .addCase(updateWorkListEncounterData.fulfilled, (state) => {
            state.openLayers = {};

            state.messageBarMessage = 'Patient/Encounter updated successfully.';
            state.messageBarType = MessageBarType.success;
        })
        .addCase(toggleEncounterWorkListItemIsRcm.pending, (state) => {
            state.encounterWorkListNoteModal.savingIsRcm = LoadingStatus.Pending;
        })
        .addCase(toggleEncounterWorkListItemIsRcm.fulfilled, (state, action) => {
            if (action.payload) {
                state.editWorkListItem = action.payload;
                if (state.data) {
                    const indexOfItem = (state.data as IWorkListEncounterView[]).findIndex(
                        (item) => item.id === action.payload?.id,
                    );
                    if (indexOfItem > -1) state.data[indexOfItem] = action.payload;
                }
            }
            state.encounterWorkListNoteModal.messageBarMessage = 'Successfully updated Billing Service flag.';
            state.encounterWorkListNoteModal.messageBarType = MessageBarType.success;
            state.encounterWorkListNoteModal.savingIsRcm = LoadingStatus.Completed;
        })
        .addCase(toggleEncounterWorkListItemIsRcm.rejected, (state) => {
            state.encounterWorkListNoteModal.messageBarMessage = 'Failed to update Billing Service flag.';
            state.encounterWorkListNoteModal.messageBarType = MessageBarType.error;
            state.encounterWorkListNoteModal.savingIsRcm = LoadingStatus.Failed;
        });
};

const handleMarkEncounterBilledRebilledCase: CaseReducerFunction<
    IWorkListsState,
    typeof markEncountersAsRebilled.fulfilled | typeof markEncountersAsBilled.fulfilled
> = (state, action) => {
    state.saving = LoadingStatus.Completed;
    const encounterIds = action.meta.arg.encounterIds;

    if (state.data && encounterIds.length) {
        state.data = (state.data as IWorkListEncounterView[]).filter(
            (e) => e?.encounter && !encounterIds.includes(e?.encounter.id),
        );
    }

    if (action.type === markEncountersAsRebilled.fulfilled.toString())
        state.messageBarMessage = 'Encounter(s) have been reapproved successfully.';
    if (action.type === markEncountersAsBilled.fulfilled.toString())
        state.messageBarMessage = 'Encounter(s) have been approved successfully.';
    state.messageBarType = MessageBarType.success;
};
