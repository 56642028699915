import { Loading } from 'components';
import useAccount from 'hooks/store/useAccount';
import useTenant from 'hooks/store/useTenant';
import useHub, { SignalRMessage, connection, useSignalR } from 'hooks/signalr/useSignalr';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { some } from 'lodash';
import { PropsWithChildren, useCallback, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setHubConnectionStatus } from 'state/slices/signalr.slice';
import { batchActions, selectBatchesData } from 'state/slices/tenant/batches.slice';
import { useCookies } from 'react-cookie';
import { Cookies } from 'interfaces/cookies';
import { batchCookieOptions } from 'pages/components/Batch/BatchPanel';

type Props = unknown;

function AppLoader(props: PropsWithChildren<Props>): JSX.Element {
    const dispatch = useDispatch();
    const { account, getAccount } = useAccount();
    const { getTenantAssets, lookupLoaders } = useTenant();
    const { hubConnectionState } = useHub(connection);
    const { registerSignalRMessage } = useSignalR();

    const batchData = useSelector(selectBatchesData);

    const matchTenant = useRouteMatch<{ tenantId: string }>('/:tenantId');
    const tenantId = matchTenant?.params.tenantId;
    const hasTenantId = tenantId !== undefined;

    const [cookies, _, removeCookie] = useCookies([Cookies.SelectedBatch]);

    useEffect(() => {
        dispatch(setHubConnectionStatus(hubConnectionState));
    }, [hubConnectionState]);

    //We must check if the selected batch is closed every time the data changes.
    //This is because the user may not be connected to signalR when a batch is closed.
    //Ex. User has closed their browser when another user closes the batch.
    useEffect(() => {
        if (batchData) {
            const currentBatch = batchData[cookies[Cookies.SelectedBatch]];
            if (currentBatch?.status === 'Closed' || currentBatch?.status === 'Hard-Closed') {
                removeCookie(Cookies.SelectedBatch, batchCookieOptions);
            }
        }
    }, [batchData]);

    useEffect(() => {
        getAccount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleHardCloseBatchesMessage = useCallback(
        (data: any, args: any[]) => {
            if (tenantId && args.length)
                //tenantid on the data model here, is not camel case for some reason. :(
                dispatch(
                    batchActions.setBatchIdsHardClosed({ currentTenant: tenantId, tenantId: data.tenantid, batchIds: args[0] }),
                );
        },
        [tenantId, dispatch],
    );

    useEffect(() => {
        registerSignalRMessage(SignalRMessage.HardCloseBatch, handleHardCloseBatchesMessage);
        registerSignalRMessage(SignalRMessage.ClosedBatch, batchActions.setBatchData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleHardCloseBatchesMessage, tenantId]);

    useEffect(() => {
        if (account.loading === LoadingStatus.Completed && matchTenant?.params.tenantId) {
            getTenantAssets(matchTenant.params.tenantId);
        }
    }, [account, getTenantAssets, matchTenant?.params.tenantId]);

    if (hasTenantId && some(lookupLoaders, (loading) => loading !== LoadingStatus.Completed)) {
        return <Loading spinnerText="Loading..." />;
    }
    return <> {props.children} </>;
}

export default AppLoader;
