import { EncounterWorkList } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import EncounterWorkListComponent from './EncounterWorkList';
import useApproveWorklistCommandBar from '../Hooks/useApproveWorklistCommandBar';

export default function ReadyToRebill() {
    const commandBarProps = useApproveWorklistCommandBar();

    return (
        <EncounterWorkListComponent
            commandBarProps={commandBarProps}
            workLists={[EncounterWorkList.ReadyToRebill, EncounterWorkList.ReadyToRebillOnHold]}
        />
    );
}
