import { createSelector } from 'reselect';
import { encounterLedgerState } from '../ledger.selectors';
import {
    mapAllEncounterSummaryLineItemsWithTransactionInfo,
    selectPatientEncounterSummariesWithLineItems,
    selectPatientEstimatePaymentsModified,
    selectPatientEstimateTransactionsAsList,
    selectPatientEstimateTransactionsTotal,
    selectPatientOverpaymentOrOverAdjustment,
    selectPatientPaymentTransactionsAsList,
    selectPatientPaymentsAndAdjustmentsBillingProcedures,
    selectPatientPaymentsAndAdjustmentsBillingProceduresLoadingStatus,
    selectPatientPaymentsModified,
    selectPaymentAndAdjustmentAmounts,
    selectPaymentsAndAdjustmentTotalPayment,
    selectPaymentsTotalEstimate,
} from '../patient-payments-and-adjustments/patient-payments-and-adjustments.selectors';
import {
    selectAppliedCreditBalanceViews,
    selectSignedTreatmentPlanViewPatientEstimateBalance,
    selectTreamentPlanCreditTransactionsAsList,
    selectTreamtentPlanCreditModified,
    selectTreatmentPlanCreditAllowOverpayment,
    selectTreatmentPlanCreditTransactionsTotal,
    selectTreatmentPlanViewLoading,
} from '../treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.selectors';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { TransferTo } from 'api/models/encounter-ledger.model';

/** Returns entire uac distribution state. */
export const selectUACDistributionState = createSelector(encounterLedgerState, (state) => state.uacDistribution);

/** Returns the currently loaded uac transaction's related payment source. */
export const selectUACDistributionPaymentSource = createSelector(selectUACDistributionState, (state) => state.paymentSource);

/** Returns the currently loaded uac transaction. */
export const selectUACDistributionTransaction = createSelector(selectUACDistributionState, (state) => state.transaction);

export const selectUACDistributionTransactionToConvertId = createSelector(
    selectUACDistributionState,
    (state) => state.transactionToConvertId,
);

export const selectUACDistributionCommonTransaction = createSelector(
    selectAppliedCreditBalanceViews,
    selectUACDistributionTransactionToConvertId,
    (appliedCreditViews, transactionToConvertId) => {
        if (!transactionToConvertId) return undefined;
        return appliedCreditViews.find((v) => v.id === transactionToConvertId);
    },
);

/** Shows the uac transaction amount as positive. */
export const selectUACDistributionTransactionAmount = createSelector(selectUACDistributionTransaction, (transaction) => {
    const amount = transaction?.amount ?? 0;
    return amount * -1;
});

/** Is the transaction data loading. */
export const selectUACDistributionTransactionLoading = createSelector(selectUACDistributionState, (state) => state.loading);

/**Returns true if any of the sections are loading data. */
export const selectUACDistributionIsLoading = createSelector(
    selectPatientPaymentsAndAdjustmentsBillingProceduresLoadingStatus,
    selectTreatmentPlanViewLoading,
    selectUACDistributionTransactionLoading,
    (loadingPaymentBillingProcedures, loadingTreatmentPlanView, loadingUACTransaction) => {
        return (
            loadingPaymentBillingProcedures === LoadingStatus.Pending ||
            loadingTreatmentPlanView === LoadingStatus.Pending ||
            loadingUACTransaction === LoadingStatus.Pending
        );
    },
);

export const selectAllUACDistributionSectionsOverpaying = createSelector(
    selectPatientOverpaymentOrOverAdjustment,
    selectTreatmentPlanCreditAllowOverpayment,
    (patientBalanceOverpayment, tpOverpayment) => tpOverpayment && patientBalanceOverpayment,
);

/**Returns the saving status of new uac transactions. */
export const selectUACDistributionSaving = createSelector(selectUACDistributionState, (state) => state.saving);

/**Returns a lookup that contains keyed booleans for whether a uac distribution section is open. */
export const selectUACDistributionOpenSections = createSelector(selectUACDistributionState, (state) => {
    return state.openSections;
});

/**Controls whether the convert procedure credit transaction to uac modal is open. */
export const selectConvertUACModalOpen = createSelector(selectUACDistributionState, (state) => !!state.convertModalOpen);

/** Consolidates all modified sections into single is modified boolean. */
export const selectUACDistributionModified = createSelector(
    selectPatientPaymentsModified,
    selectPatientEstimatePaymentsModified,
    selectTreamtentPlanCreditModified,
    (paymentModified, estimateModified, tpPrepaymentModified) => paymentModified || tpPrepaymentModified || estimateModified,
);

/** All transactions for UAC distribution. Ensures all transactions are mapped to the uac paymentSourceId. */
export const selectUACDistributionTransactions = createSelector(
    selectUACDistributionPaymentSource,
    selectPatientPaymentTransactionsAsList,
    selectPatientEstimateTransactionsAsList,
    selectTreamentPlanCreditTransactionsAsList,
    (paymentSource, patientBalanceTransactions, patientEstimateTransactions, treatmentPlanTransactions) => {
        return [...patientBalanceTransactions, ...patientEstimateTransactions, ...treatmentPlanTransactions].map((t) => ({
            ...t,
            paymentSourceId: paymentSource?.id,
        }));
    },
);

/** Returns values related to patient balance. */
export const selectUACDistributionPatientBalanceAmounts = createSelector(
    selectPaymentAndAdjustmentAmounts,
    selectPaymentsAndAdjustmentTotalPayment,
    ({ paymentTransactionsTotal }, totalPatientBalance) => {
        const patientBalanceRemainingAmount = totalPatientBalance - paymentTransactionsTotal;

        return {
            patientBalancePaymentAmount: paymentTransactionsTotal,
            patientBalanceRemainingAmount,
            patientBalanceTotal: totalPatientBalance,
        };
    },
);

/** Returns values related to patient estimate. */
export const selectUACDistributionPatientEstimateAmounts = createSelector(
    selectPatientEstimateTransactionsTotal,
    selectPaymentsTotalEstimate,
    (estimateTransactionsTotal, totalPatientEstimate) => {
        const patientEstimateRemainingAmount = totalPatientEstimate - estimateTransactionsTotal;

        return {
            estimateTransactionsTotal,
            patientEstimateRemainingAmount,
            totalPatientEstimate,
        };
    },
);

/** Returns values related to TP plan remaining. */
export const selectUACDistributionTreatmentPlanAmounts = createSelector(
    selectSignedTreatmentPlanViewPatientEstimateBalance,
    selectTreatmentPlanCreditTransactionsTotal,
    (tpEstimateBalance, transactionsTotal) => {
        const treatmentPlanRemainingAmount = tpEstimateBalance - transactionsTotal;
        return {
            treatmentPlanRemainingTotalBalance: tpEstimateBalance,
            treatmentPlanRemainingTotalPayment: transactionsTotal,
            treatmentPlanRemainingAmount,
        };
    },
);

/**Sums payment amounts across all sections. */
export const selectUACDistributionDistributedAmount = createSelector(
    selectUACDistributionPatientBalanceAmounts,
    selectUACDistributionPatientEstimateAmounts,
    selectUACDistributionTreatmentPlanAmounts,

    ({ patientBalancePaymentAmount }, { estimateTransactionsTotal }, { treatmentPlanRemainingTotalPayment }) =>
        Number((patientBalancePaymentAmount + estimateTransactionsTotal + treatmentPlanRemainingTotalPayment).toFixed(2)),
);

/** Total remaining amount left on uac transaction after subtracting the total currently distributed amount. */
export const selectUACDistributionTotalRemaining = createSelector(
    selectUACDistributionTransactionAmount,
    selectUACDistributionDistributedAmount,
    (uacAmount, distributedAmount) => Number((uacAmount - distributedAmount).toFixed(2)),
);

//UAC Patient balance view data
export const selectUACPatientBalanceAllEncounterSummaries = createSelector(
    selectPatientPaymentsAndAdjustmentsBillingProcedures,
    selectPatientEncounterSummariesWithLineItems,
    (billingProcedures, encounterSummaries) => {
        if (billingProcedures.length) {
            return mapAllEncounterSummaryLineItemsWithTransactionInfo(encounterSummaries, billingProcedures, TransferTo.Patient);
        } else {
            return encounterSummaries;
        }
    },
);

export const selectUACPatientBalanceEncounterSummariesWithFee = createSelector(
    selectUACPatientBalanceAllEncounterSummaries,
    (summaries) => {
        return summaries.filter((s) => s.totalCommonPatientFee > 0);
    },
);
