import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import axios from 'axios';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RootState } from 'state/store';

export enum FeatureFlag {
    ECR = 'Enhanced Claims Resolution',
    ClaimHistory = 'Claim History',
    ChartLedgerFilters = "Chart Ledger Filters",
}

type IFeatureFlagsState = {
    loadingActiveFeatureFlags: LoadingStatus;
    activeFeatureFlags?: FeatureFlag[];
};

const initialState: IFeatureFlagsState = {
    loadingActiveFeatureFlags: LoadingStatus.Idle,
};

const featureFlags = createSlice({
    name: 'featureFlags',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getActiveFeatureFlagsForTenant.pending, (state) => {
                state.loadingActiveFeatureFlags = LoadingStatus.Pending;
            })
            .addCase(getActiveFeatureFlagsForTenant.fulfilled, (state, { payload }) => {
                state.loadingActiveFeatureFlags = LoadingStatus.Completed;
                state.activeFeatureFlags = payload;
            })
            .addCase(getActiveFeatureFlagsForTenant.rejected, (state) => {
                state.loadingActiveFeatureFlags = LoadingStatus.Failed;
            });
    },
});

const { reducer } = featureFlags;

export default reducer;

//Async Actions
export const getActiveFeatureFlagsForTenant = createAsyncThunk<FeatureFlag[], { tenantId: string }, { rejectValue: string }>(
    'getActiveFeatureFlagsForTenant',
    async ({ tenantId }, { rejectWithValue }) => {
        try {
            const response = await dentalApi.getTenantFeatureFlagsForTenant(tenantId);
            return response.data;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return rejectWithValue(e.message);
            }
            return rejectWithValue(e as string);
        }
    },
);

//Selectors
export const selectFeatureFlagsState = (state: RootState) => state.tenant.featureFlags;
export const selectActiveFeatureFlags = createSelector(selectFeatureFlagsState, (state) =>
    (state.activeFeatureFlags ?? []).map((flag) => flag.trim()),
);
export const selectActiveFeatureFlagsLookup = createSelector(selectActiveFeatureFlags, (featureFlags) => {
    return new Set<string>(featureFlags);
});
export const selectActiveFeatureFlagsLoading = createSelector(
    selectFeatureFlagsState,
    (state) => state.loadingActiveFeatureFlags,
);

//Specific feature flags
export const selectHasRequireBatchForChargesFeatureFlag = createSelector(selectActiveFeatureFlagsLookup, (featureFlags) =>
    featureFlags.has('Require Batch for Charges'),
);

export const selectHasStatementConfigurationFeatureFlag = createSelector(selectActiveFeatureFlagsLookup, (featureFlags) =>
    featureFlags.has('Automated Statements Configuration'),
);

export const selectProviderAttestationFeatureFlag = createSelector(selectActiveFeatureFlagsLookup, (featureFlags) =>
    featureFlags.has('Provider Attestation'),
);

