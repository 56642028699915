import {
    DefaultButton,
    getTheme,
    IButtonStyles,
    IconButton,
    mergeStyleSets,
    Modal,
    PrimaryButton,
    Stack,
    Text,
} from '@fluentui/react';
import { DocumentContext, DocumentRefTypes } from 'api/models/document.model';
import IPatient, { IPatientInsurance, IPolicyHolder } from 'api/models/patient.model';
import { SubSection } from 'components';
import Upload from 'components/Upload';
import { useTenantId, useValidation } from 'hooks';
import usePayers from 'hooks/store/usePayers';
import { IValidationConfig, ValidationType } from 'hooks/useValidation';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cleanupPendingDocumentsAndFiles } from 'state/slices/documents/documents.slice';
import { selectSameAddressAsPatient } from 'state/slices/edit-patient/edit-patient.selectors';
import { saveInsurance } from 'state/slices/edit-patient/edit-patient.slice';
import GeneralInformation from './GeneralInformation';
import InsuranceProperties from './InsuranceProperties';
import PolicyHolderInformation from './PolicyHolderInformation';
import { selectTenantPayersWithDisplayName } from 'state/slices/tenant/tenant-payers.slice';
import states from 'components/Field/StateOptions';
import { getPayerDetails, refreshPayerDetails } from 'state/slices/tenant/payer-details.slice';

type Props = {
    setInsurance: (value: IPatientInsurance | undefined) => void;
    insurance: IPatientInsurance | undefined;
    patient?: IPatient;
    showInsuranceModal: boolean;
    setShowInsuranceModal: (value: boolean) => void;
    isNew: boolean;
};

function InsuranceModal({
    setInsurance,
    insurance,
    patient,
    showInsuranceModal,
    setShowInsuranceModal,
    isNew,
}: Props): JSX.Element {
    const theme = getTheme();
    const dispatch = useDispatch();

    const payersWithDisplayName = useSelector(selectTenantPayersWithDisplayName);
    const address = useSelector(selectSameAddressAsPatient);

    const tenantId = useTenantId();
    const payer = payersWithDisplayName.find((payer) => payer?.id === insurance?.insuranceId);

    const onSave = () => {
        if (patient && insurance) {
            if (insurance) {
                dispatch(saveInsurance(insurance));
            }
            setShowInsuranceModal(false);
        }
    };

    const policyHolderAddressStateType = states.find((state) => insurance?.policyHolder?.state === state.key)?.data?.type;

    const validationConfig: IValidationConfig = [
        {
            fieldName: 'First Name',
            validation: ['required'],
            value: insurance?.policyHolder?.firstName,
        },
        {
            fieldName: 'Last Name',
            validation: ['required'],
            value: insurance?.policyHolder.lastName,
        },
        {
            fieldName: 'Relationship',
            validation: ['required'],
            value: insurance?.policyHolder.relationship,
        },

        {
            fieldName: 'Date of Birth',
            validation: ['required', 'minMaxDate', 'notFutureDate'],
            value: insurance?.policyHolder.dateOfBirth,
        },
        {
            fieldName: 'Sex at Birth',
            validation: ['required'],
            value: insurance?.policyHolder.sexAtBirthId,
        },
        {
            fieldName: 'Address Line 1',
            validation: ['required'],
            value: insurance?.policyHolder.streetAddress1,
        },
        {
            fieldName: 'City',
            validation: ['required'],
            value: insurance?.policyHolder.city,
        },
        {
            fieldName: 'State',
            validation: ['required'],
            value: insurance?.policyHolder.state,
        },
        {
            fieldName: 'Zip Code',
            validation: ['required', 'zip'],
            value: insurance?.policyHolder.zip,
            itemOptions: { stateType: policyHolderAddressStateType },
        },
        {
            fieldName: 'Insurance',
            validation: ['required'],
            value: insurance?.insuranceId,
        },

        {
            fieldName: 'Member Id',
            validation: ['required'],
            value: insurance?.memberId,
        },
        {
            fieldName: 'Policy Holder',
            validation: ['required'],
            value: insurance?.policyHolderType,
        },
        {
            fieldName: 'Effective Date',
            validation: ['required', 'minMaxDate', 'notFutureDate'],
            value: insurance?.effectiveDate,
            itemOptions: { minDate: new Date('01/01/2001') },
        },
    ];

    if (insurance?.expirationDate && insurance?.expirationDate?.length > 0) {
        validationConfig.push({
            fieldName: 'Expiration Date',
            validation: ['minMaxDate'],
            value: insurance?.expirationDate,
            itemOptions: { minDate: new Date(insurance?.effectiveDate) },
        });
    }

    const [errors, onSubmit, handleCleanupErrors] = useValidation(
        validationConfig,

        onSave,
    );

    useEffect(() => {
        dispatch(refreshPayerDetails({ tenantId }));
        return () => {
            handleCleanupErrors();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!showInsuranceModal) handleCleanupErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showInsuranceModal]);

    function onPolicyHolderPropChange(option: string) {
        if (insurance && patient) {
            const isPolicyTypeIdPatient = option === 'Patient';

            setInsurance({
                ...insurance,
                policyHolderType: option,
                policyHolder: {
                    ...insurance.policyHolder,
                    relationship: isPolicyTypeIdPatient ? 'Self' : '',
                    firstName: isPolicyTypeIdPatient ? patient.firstName : '',
                    middleName: isPolicyTypeIdPatient ? patient.middleName : '',
                    lastName: isPolicyTypeIdPatient ? patient.lastName : '',
                    dateOfBirth: isPolicyTypeIdPatient ? patient.dateOfBirth : '',
                    ssn: isPolicyTypeIdPatient ? patient.ssn : '',
                    sexAtBirthId: isPolicyTypeIdPatient ? patient.legalSexId : '',
                },
            });
        }
    }

    function newPolicyHolderPropChange(path: keyof IPolicyHolder, value: unknown) {
        if (insurance?.policyHolder) {
            setInsurance({
                ...insurance,
                policyHolder: {
                    ...insurance.policyHolder,
                    [path]: value,
                },
            });
        }
    }

    function _setInsurance(insurance: IPatientInsurance) {
        setInsurance(insurance);
    }

    function newInsurancePropChange(path: keyof IPatientInsurance, value: unknown) {
        if (insurance) {
            setInsurance({ ...insurance, [path]: value });
        }
    }

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: theme.palette.magenta,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                flexDirection: 'row',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: 600,
                padding: '12px 12px 12px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px',
            overflowY: 'hidden',
        },
    });

    const carrier = payersWithDisplayName.find((x) => x?.id === insurance?.insuranceId);
    const carrierName = carrier?.displayName ? carrier.displayName : carrier?.name;
    const saveButtonText = isNew ? 'Add Insurance' : 'Update Insurance';
    const payerAddressParts = [payer?.street1, payer?.city, payer?.state, payer?.zip].filter((part) => part);

    const payerAddress = payerAddressParts.length > 0 ? payerAddressParts.join(', ') : 'N/A';

    return (
        <Modal
            isOpen={showInsuranceModal}
            containerClassName={contentStyles.container}
            onDismiss={() => setShowInsuranceModal(false)}
            isBlocking={true}
        >
            <Stack className={contentStyles.header}>
                <Text variant="xLarge">{`${isNew ? 'Add Insurance' : 'Edit' + ' ' + carrierName}`}</Text>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{ iconName: 'Cancel' }}
                    text="Close"
                    onClick={() => setShowInsuranceModal(false)}
                />
            </Stack>
            <Stack horizontal>
                <Text variant="mediumPlus" style={{ paddingLeft: '24px' }}>
                    <strong>Payer ID:</strong> {payer?.payerId ?? 'N/A'}
                </Text>
                <Text variant="mediumPlus" style={{ paddingLeft: '24px' }}>
                    <strong>Address:</strong> {payerAddress ?? 'N/A'}
                </Text>
            </Stack>
            <Stack tokens={{ childrenGap: 10, padding: 20 }}>
                <Stack tokens={{ childrenGap: 10 }}>
                    <InsuranceProperties
                        insurance={insurance}
                        setInsurance={_setInsurance}
                        newInsurancePropChange={newInsurancePropChange}
                        isNew={isNew}
                        validationErrors={errors}
                    />
                    <PolicyHolderInformation
                        insurance={insurance}
                        validationErrors={errors}
                        onPolicyHolderPropChange={onPolicyHolderPropChange}
                    />
                    {insurance?.policyHolderType && (
                        <GeneralInformation
                            insurance={insurance}
                            newInsurancePropChange={newInsurancePropChange}
                            newPolicyHolderPropChange={newPolicyHolderPropChange}
                            validationErrors={errors}
                            address={address}
                        />
                    )}
                    {patient && insurance && (
                        <SubSection title="Documents">
                            <Upload
                                patientId={patient.id}
                                context={DocumentContext.Insurance}
                                reference={{ type: DocumentRefTypes.PatientInsurance, id: insurance?.id }}
                            />
                        </SubSection>
                    )}
                </Stack>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }} style={{ padding: 24 }}>
                <PrimaryButton text={saveButtonText} onClick={onSubmit} />
                <DefaultButton
                    text="Discard Insurance"
                    onClick={() => {
                        setInsurance(undefined);
                        dispatch(cleanupPendingDocumentsAndFiles());
                        setShowInsuranceModal(false);
                    }}
                />
            </Stack>
        </Modal>
    );
}

export default InsuranceModal;
