import { createSlice } from '@reduxjs/toolkit';
import { chartNotesExtraReducers, chartNotesReducers } from './chart-notes.reducers';
import initialState from './chart-notes.state';
import { quickNotesExtraReducers } from './quick-notes/quick-notes.reducers';

const chartNotesSlice = createSlice({
    name: 'chartNotes',
    initialState,
    reducers: {
        ...chartNotesReducers,
    },
    extraReducers: (builder) => {
        chartNotesExtraReducers(builder);
        quickNotesExtraReducers(builder);
    },
});

const { reducer, actions } = chartNotesSlice;

export const { setClincalNoteFilter, setClinicalNote, setClincalNoteData, setClincalNoteDataProp, setPartialClinicalNote } =
    actions;

export default reducer;
