import {
    DatePicker,
    DayOfWeek,
    Dropdown,
    IDropdownOption,
    MessageBar,
    ProgressIndicator,
    Stack,
    TextField,
} from '@fluentui/react';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import IProvider from 'api/models/provider.model';
import { Field, Section, ValidationBar } from 'components';
import { format } from 'date-fns';
import { useSelector, useTenantId } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { encounterReasonsAsOptions } from 'state/slices/encounter-reasons/encounter-reasons.selectors';
import { selectCurrentOperatoriesAppointmentPanelOptions } from 'state/slices/lookups/operatories/operatories.selectors';
import {
    selectCurrentScheduleAppointment,
    selectCurrentScheduleAppointmentValidationErrors,
    selectTimeOptionWithinRangeBusinessHours,
} from 'state/slices/scheduling/schedule-appointment/schedule-appointment.selectors';
import { updateLOCWhenOperatoryChanges } from 'state/slices/scheduling/scheduling.actions';
import {
    selectAppointmentOverviewActiveProviders,
    selectAppointmentType,
    selectPatientAppointmentPhaseProcedures,
    selectSelectedAppointmentPatientEncounter,
    selectSelectedAppointmentData,
    selectSelectedAppointmentLoading,
} from 'state/slices/scheduling/scheduling.selectors';

import { selectOperatoriesAsList } from 'state/slices/lookups/operatories/operatories.selectors';

import {
    getChartTreatmentPlans,
    setUpdatePatientAppointment,
    updatePatientAppointmentNotes,
} from 'state/slices/scheduling/scheduling.slice';
import { AppointmentType } from 'state/slices/scheduling/scheduling.state';
import {
    getOptionsWithSelectedProvider,
    selectFilteredProviders,
    selectProvidersAsList,
    selectProvidersData,
} from 'state/slices/tenant/providers.slice';
import ScheduleAppointmentProcedures from '../../ScheduleAppointment/ScheduleAppointmentProcedures';

import TrackerStatusDropdown, { TrackerStatus } from '../../TrackerStatusDropdown';

import { EncounterStatus } from 'api/models/encounter.model';
import { ISearchComboBoxOption } from 'components/Field/SearchComboField';

function AppointmentInfo(): JSX.Element {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const appointment = useSelector(selectCurrentScheduleAppointment);
    const appointmentType = useSelector(selectAppointmentType);
    const patientAppt: IPatientAppointment | undefined =
        appointmentType === AppointmentType.Patient ? (appointment as IPatientAppointment) : undefined;
    const patientAppointment = useSelector(selectSelectedAppointmentData);
    const appointmentLoading = useSelector(selectSelectedAppointmentLoading);

    useEffect(() => {
        if (patientAppt) dispatch(getChartTreatmentPlans({ tenantId, patientId: patientAppt.patientId }));
    }, []);

    return (
        <Stack>
            {!patientAppointment || appointmentLoading === LoadingStatus.Pending ? (
                <ProgressIndicator label="Loading..." />
            ) : (
                <Stack tokens={{ childrenGap: 10 }}>
                    <PatientInfo />
                    <AppointmentDetails />
                </Stack>
            )}
        </Stack>
    );
}

function PatientInfo() {
    const _dispatch = useDispatch();
    const patientAppointment = useSelector(selectSelectedAppointmentData);
    const encounter = useSelector(selectSelectedAppointmentPatientEncounter);
    const disabledIfCompleted = encounter?.status === EncounterStatus.Billed;
    const _handleDispatch = (key: string, value: string) => {
        if (patientAppointment)
            _dispatch(
                setUpdatePatientAppointment({
                    ...patientAppointment,
                    [key]: value,
                }),
            );
    };

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <TrackerStatusDropdown
                appointment={patientAppointment}
                disabled={disabledIfCompleted}
                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                    if (option) _handleDispatch('trackerStatusId', option.key as string);
                }}
            />
        </Stack>
    );
}

export const appointmentStatusOptions: IDropdownOption[] = [
    { key: 'unconfirmed', text: 'Unconfirmed' },
    { key: 'confirmedTxt', text: 'Confirmed, TXT' },
    { key: 'confirmedPhone', text: 'Confirmed, Phone' },
    { key: 'leftMsg', text: 'Left Msg, Family/Friend' },
    { key: 'leftVoicemail', text: 'Left Voicemail' },
    { key: 'disconnected', text: 'Disconnected' },
    { key: 'wrongNumber', text: 'Wrong Number' },
    { key: 'noAnswer', text: 'No Answer' },
];

function AppointmentDetails() {
    const patientAppointment = useSelector(selectSelectedAppointmentData);

    const tenantId = useTenantId();

    const operatoryTimeOptions = useSelector((state) => selectTimeOptionWithinRangeBusinessHours(state, tenantId));
    const operatoryOptionsByLOC = useSelector((state) => selectCurrentOperatoriesAppointmentPanelOptions(state, tenantId));

    const _providers = useSelector(selectFilteredProviders);
    const activeProviders = useSelector(selectAppointmentOverviewActiveProviders);
    const providersLookup = useSelector(selectProvidersData);

    const _validationErrors = useSelector(selectCurrentScheduleAppointmentValidationErrors);
    const patientProcedures = useSelector(selectPatientAppointmentPhaseProcedures);
    const encounterReasons = useSelector(encounterReasonsAsOptions);
    const encounter = useSelector(selectSelectedAppointmentPatientEncounter);

    const _dispatch = useDispatch();

    const disabledIfCompleted = encounter?.status === EncounterStatus.Billed;

    const disabledIfEncounterId = !!patientAppointment?.encounterId;

    const [notes, setNotes] = useState<string | undefined>(patientAppointment?.notes);

    useEffect(() => {
        if (patientAppointment && patientAppointment.notes) {
            setNotes(patientAppointment.notes);
        } else {
            setNotes('');
        }
    }, [patientAppointment]);

    const attendingBillingProviderOptions: IDropdownOption[] = getOptionsWithSelectedProvider({
        options: _providers.filter((p) => p.isTreatingProvider && !p.isResident).map(mapProviderToDropdown),
        selectedProviderId: patientAppointment?.billingProviderId,
        lookup: providersLookup,
    });

    const todayTreatingProviderOptions: ISearchComboBoxOption[] = getOptionsWithSelectedProvider({
        options: activeProviders.filter((p) => p?.isTreatingProvider || p?.isAttestingHygienist).map(mapProviderToDropdown),
        selectedProviderId: patientAppointment?.treatingProviderId,
        lookup: providersLookup,
    });

    const hygienistProviderOptions: IDropdownOption[] = getOptionsWithSelectedProvider({
        options: activeProviders.filter((p) => p.isHygienist).map(mapProviderToDropdown),
        selectedProviderId: patientAppointment?.hygienistId,
        lookup: providersLookup,
    });
    const rdaProviderOptions: IDropdownOption[] = getOptionsWithSelectedProvider({
        options: activeProviders.filter((p) => p.isRegisteredDentalAssistant).map(mapProviderToDropdown),
        selectedProviderId: patientAppointment?.registeredDentalAssistantId,
        lookup: providersLookup,
    });

    const isTreatingProviderAttendingHygienist = patientAppointment?.treatingProviderId
        ? providersLookup[patientAppointment.treatingProviderId]?.isAttestingHygienist
        : false;

    useEffect(() => {
        if (isTreatingProviderAttendingHygienist) _handleDispatch('hygienistId', '');
    }, [isTreatingProviderAttendingHygienist]);

    const _handleDispatch = (key: keyof IPatientAppointment, value: string) => {
        if (!patientAppointment) return;
        if (key === 'operatoryId') {
            _dispatch(updateLOCWhenOperatoryChanges(tenantId, value, patientAppointment));
        } else {
            _dispatch(
                setUpdatePatientAppointment({
                    ...patientAppointment,
                    [key]: value,
                }),
            );
        }
    };

    const treatingProviderHasError = !patientAppointment?.treatingProviderId ? 'Treating Provider is required.' : '';

    const endTimeOptions = patientAppointment?.startTime
        ? operatoryTimeOptions.filter((option) => option.key > patientAppointment?.startTime ?? '00:00')
        : operatoryTimeOptions;

    return (
        <Stack>
            <PanelSectionHeader text="Appointment Details" />
            {_validationErrors && _validationErrors.length ? <ValidationBar errors={_validationErrors} /> : null}
            <Stack>
                <Stack horizontal grow wrap style={{ marginBottom: 10 }}>
                    <Stack tokens={{ childrenGap: 10 }} horizontal horizontalAlign="space-evenly">
                        <Stack.Item style={{ width: 200 }}>
                            <Field.Dropdown
                                label="Billing Provider"
                                placeholder="Select Billing Provider"
                                options={[{ key: '', text: 'Select Billing Provider' }, ...attendingBillingProviderOptions]}
                                selectedKey={patientAppointment?.billingProviderId}
                                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                                    if (option) _handleDispatch('billingProviderId', option.key as string);
                                }}
                                disabled={disabledIfEncounterId}
                            />
                        </Stack.Item>
                        <Stack.Item grow style={{ width: 300 }}>
                            <Field.Dropdown
                                label="Attending Provider"
                                placeholder="Select Attending Provider"
                                options={[{ key: '', text: 'Select Attending Provider' }, ...attendingBillingProviderOptions]}
                                selectedKey={patientAppointment?.supervisingProviderId}
                                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                                    if (option) _handleDispatch('supervisingProviderId', option.key as string);
                                }}
                                disabled={disabledIfEncounterId}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                        <Stack.Item grow>
                            <DatePicker
                                label="Date"
                                firstDayOfWeek={DayOfWeek.Sunday}
                                placeholder="Select a date..."
                                ariaLabel="Select a date"
                                value={new Date(`${patientAppointment?.date}T00:00:00`)} //HACK: had to add time to get the correct date value. it was getting the day before ex. 2021-08-02 returned Aug 1, 2021.
                                onSelectDate={(date: Date | null | undefined) => {
                                    if (date) _handleDispatch('date', format(new Date(date), 'yyyy-MM-dd'));
                                }}
                                disabled={disabledIfCompleted || !!disabledIfEncounterId}
                            />
                        </Stack.Item>
                        <Stack.Item grow>
                            <Dropdown
                                label="Confirmation Status"
                                placeholder="Select Confirmation Status"
                                options={[{ key: '', text: 'Select Confirmation Status' }, ...appointmentStatusOptions]}
                                selectedKey={patientAppointment?.appointmentStatusId}
                                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                                    if (option) _handleDispatch('appointmentStatusId', option.key as string);
                                }}
                                disabled={disabledIfCompleted}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                        <Stack.Item grow>
                            <Dropdown
                                label="Start"
                                placeholder="Select Start Time"
                                options={operatoryTimeOptions}
                                selectedKey={patientAppointment?.startTime ?? ''}
                                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                                    if (option) _handleDispatch('startTime', option.key as string);
                                }}
                                disabled={disabledIfCompleted}
                            />
                        </Stack.Item>
                        <Stack.Item grow>
                            <Dropdown
                                selectedKey={patientAppointment?.endTime ?? ''}
                                label="End"
                                placeholder="Select End Time"
                                options={endTimeOptions}
                                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                                    if (option) _handleDispatch('endTime', option.key as string);
                                }}
                                disabled={disabledIfCompleted}
                            />
                        </Stack.Item>
                        <Stack.Item grow>
                            <Dropdown
                                selectedKey={patientAppointment?.operatoryId ?? ''}
                                placeholder="Select an Operatory"
                                label="Operatory"
                                options={operatoryOptionsByLOC}
                                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                                    if (option) _handleDispatch('operatoryId', option.key as string);
                                }}
                                disabled={disabledIfCompleted}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                        <Stack.Item style={{ width: 200 }}>
                            <Field.SearchCombo
                                selectedKey={patientAppointment?.treatingProviderId}
                                placeholder="Select a Provider"
                                label="Treating Provider"
                                options={todayTreatingProviderOptions}
                                onChange={(event, option) => {
                                    if (option) _handleDispatch('treatingProviderId', option.key as string);
                                }}
                                required
                                errorMessage={treatingProviderHasError}
                                disabled={disabledIfCompleted || disabledIfEncounterId}
                            />
                        </Stack.Item>
                        <Stack.Item style={{ width: 200 }}>
                            <Field.Dropdown
                                selectedKey={patientAppointment?.hygienistId}
                                placeholder="Select a Hygienist"
                                label="Hygienist"
                                options={[{ key: '', text: 'Select a Hygienist' }, ...hygienistProviderOptions]}
                                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                                    if (option) _handleDispatch('hygienistId', option.key as string);
                                }}
                                disabled={
                                    !hygienistProviderOptions.length ||
                                    disabledIfCompleted ||
                                    isTreatingProviderAttendingHygienist ||
                                    disabledIfEncounterId
                                }
                            />
                        </Stack.Item>
                        <Stack.Item style={{ width: 200 }}>
                            <Field.Dropdown
                                selectedKey={patientAppointment?.registeredDentalAssistantId}
                                placeholder="Select a RDA"
                                label="RDA"
                                options={[{ key: '', text: 'Select a RDA' }, ...rdaProviderOptions]}
                                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                                    if (option) _handleDispatch('registeredDentalAssistantId', option.key as string);
                                }}
                                disabled={!rdaProviderOptions.length || disabledIfCompleted || disabledIfEncounterId}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack.Item style={{ width: 200 }}>
                        <Field.Dropdown
                            selectedKey={patientAppointment?.encounterReason}
                            placeholder="Select Encounter Reason"
                            label="Reason For Encounter"
                            disabled={disabledIfCompleted}
                            options={encounterReasons}
                            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                                if (option) _handleDispatch('encounterReason', option.key as string);
                            }}
                        />
                    </Stack.Item>
                </Stack>
                <Section heading="Treatment Plan Phases">
                    {patientProcedures && patientProcedures.length ? (
                        <ScheduleAppointmentProcedures />
                    ) : (
                        <MessageBar>There are currently no procedures available for selection.</MessageBar>
                    )}
                </Section>
                <Stack>
                    <TextField
                        value={notes}
                        label="Appt. Notes:"
                        multiline
                        deferredValidationTime={200}
                        disabled={disabledIfCompleted}
                        onNotifyValidationResult={() => {
                            if (patientAppointment) _dispatch(updatePatientAppointmentNotes(notes));
                        }}
                        onChange={(
                            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                            newValue: string | undefined,
                        ) => {
                            setNotes(newValue);
                        }}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
}

function mapProviderToDropdown(p: IProvider): ISearchComboBoxOption {
    return {
        key: p?.id ?? '',
        text: `${p?.lastName} ${p?.suffix ? `${p?.suffix}` : ''}, ${p?.firstName} `,
        groupName: p?.isTreatingProvider ? 'Treating Provider' : p?.isAttestingHygienist ? 'Hygienist' : undefined,
    };
}

export default AppointmentInfo;
