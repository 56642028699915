import { createAsyncThunk, createSelector, createSlice, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IPayerDetail } from 'api/models/payer-detail.model';
import { AxiosResponse, AxiosError } from 'axios';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { RootState } from 'state/store';

type PayerDetailsState = {
    initialLoad: LoadingStatuses;
    loading: LoadingStatuses;
    data?: Dictionary<IPayerDetail>;
    errors?: string;
};

const initialState: PayerDetailsState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
};

export const getPayerDetails = createAsyncThunk<
    AxiosResponse<Dictionary<IPayerDetail>>,
    { tenantId: string },
    {
        rejectValue: AxiosError;
    }
>('getPayerDetails', async ({ tenantId }) => {
    const res = await dentalApi.getPayerDetails(tenantId);
    return res;
});

export const refreshPayerDetails = createAsyncThunk<
    AxiosResponse<Dictionary<IPayerDetail>>,
    { tenantId: string },
    {
        rejectValue: AxiosError;
    }
>('refreshPayerDetails', async ({ tenantId }) => {
    const res = await dentalApi.getPayerDetails(tenantId);
    return res;
});

const payerDetailsSlice = createSlice({
    name: 'payerDetails',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPayerDetails.pending, (state) => {
                state.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getPayerDetails.fulfilled, (state, action) => {
                state.initialLoad = LoadingStatus.Completed;
                state.data = action.payload.data;
            })
            .addCase(getPayerDetails.rejected, (state, action) => {
                state.initialLoad = LoadingStatus.Failed;
                state.errors = action.payload?.message;
            })

            .addCase(refreshPayerDetails.pending, (state) => {
                //
            })
            .addCase(refreshPayerDetails.fulfilled, (state, action) => {
                state.data = action.payload.data;
            })
            .addCase(refreshPayerDetails.rejected, (state, action) => {
                state.errors = action.payload?.message;
            });
    },
});

const { reducer } = payerDetailsSlice;

export const selectPayerDetails = (state: RootState): PayerDetailsState | undefined => state.tenant.payerDetails;
export const selectPayerDetailsData = createSelector(selectPayerDetails, (state) => state?.data ?? {});

export default reducer;
