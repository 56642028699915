import {
    Checkbox,
    DirectionalHint,
    FontIcon,
    IButtonStyles,
    IconButton,
    IContextualMenuItem,
    Link,
    MessageBar,
    Separator,
    Stack,
    Text,
    Toggle,
    TooltipHost,
} from '@fluentui/react';
import { DocumentContext, DocumentRefTypes } from 'api/models/document.model';
import { IPatientInsurance } from 'api/models/patient.model';
import IUserTask from 'api/models/user-task.model';
import { ContentCard, SubSection } from 'components';
import UserTasksBadge from 'components/UserTasks/UserTasksBadge';
import { format, isAfter, isBefore, isValid } from 'date-fns';
import { useSelector } from 'hooks';
import { useDownloadFile } from 'hooks/useDocumentLink';
import { map } from 'lodash';
import TaskFieldLabel from 'pages/Scheduling/components/Checkin/TaskFieldLabel';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectPatientHasGuarantorInContacts } from 'state/slices/contacts/contacts.slice';
import { selectPatientDocumentsByContext } from 'state/slices/documents/documents.selectors';
import {
    filteredInsuranceList,
    insuranceAsList,
    selectEditPatienShowInsuranceHistory,
    selectEditPatient,
} from 'state/slices/edit-patient/edit-patient.selectors';

import {
    setInsurancePrimary,
    toggleInsuranceActive,
    toggleShowInsuranceHistory,
} from 'state/slices/edit-patient/edit-patient.slice';
import { selectSelectedAppointmentData } from 'state/slices/scheduling/scheduling.selectors';

import { updatePatientAppointmentProp } from 'state/slices/scheduling/scheduling.slice';

import { benefitPlanCategories } from 'state/slices/tenant/benefit-plan.slice';

import { TaskType } from 'state/task-management/taskManagement.actions';
import {
    selectInsuranceFinancialInfoByTaskTypeLookup,
    selectPatientAppointmentBasicInfoByTaskTypeLookup,
    selectRootFinancialInsuranceInfoByTaskTypeLookup,
} from 'state/task-management/taskManagement.slice';
import { calculateAgeInYears, classicDateOnly } from 'utils/dateOnly';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import { selectTenantPayersWithDisplayName } from 'state/slices/tenant/tenant-payers.slice';
import { v4 as uuid } from 'uuid';
import EligibilityModal from './EligibilityModal';
import InsuranceModal from './InsuranceModal';
import { insurancePriorityName } from './InsuranceProperties';
import UserDisplayName from 'components/UserDisplayName';

interface IInsuranceProps {
    isCheckinOrCheckout?: boolean;
}

function Insurance({ isCheckinOrCheckout }: IInsuranceProps): JSX.Element {
    const dispatch = useDispatch();

    const patient = useSelector(selectEditPatient);

    const hasGuarantor = useSelector(selectPatientHasGuarantorInContacts);

    const [insurance, setInsurance] = useState<IPatientInsurance>();
    const [showInsuranceModal, setShowInsuranceModal] = useState<boolean>(false);
    const [showEligibilityModal, setShowEligibilityModal] = useState<boolean>(false);
    const [isNew, setIsNew] = useState(false);

    const editPatientInsurance = (insurance: IPatientInsurance) => {
        setShowInsuranceModal(true);
        setInsurance(insurance);
        setIsNew(false);
    };

    const editPatientInsuranceEligibility = (insurance: IPatientInsurance) => {
        setShowEligibilityModal(true);
        setInsurance(insurance);
        setIsNew(false);
    };

    const insuranceList = useSelector(insuranceAsList);
    const insuranceListHistory = useSelector(selectEditPatienShowInsuranceHistory);

    const _currentAppointment = useSelector(selectSelectedAppointmentData);
    const _lookupPatientAppointmentBasicDetailTaskByType = useSelector(selectPatientAppointmentBasicInfoByTaskTypeLookup);

    const insuranceCards = insuranceList.map((item) => (
        <InsuranceCard
            editInsurance={editPatientInsurance}
            editEligibility={editPatientInsuranceEligibility}
            key={item.id}
            insurance={item}
        />
    ));

    const hasInsurances = (patient?.insurances?.filter((ins) => !ins.isDeleted)?.length ?? 0) > 0;
    const disableAddInsurance = (patient?.insurances?.filter((ins) => !ins.isDeleted)?.length ?? 0) >= 4;

    const addInsuranceType = () => {
        const isInsuranceDeleted = patient?.insurances?.filter((x) => !x.isDeleted);
        if (isInsuranceDeleted && isInsuranceDeleted.length) {
            if (isInsuranceDeleted.length < 2) {
                return ' Add Secondary';
            }
            if (isInsuranceDeleted.length < 3) {
                return 'Add Tertiary';
            }
            if (isInsuranceDeleted.length < 4) {
                return 'Add Quaternary';
            }
            if (isInsuranceDeleted.length < 5) {
                return 'Please deactivate an insurance to add another';
            }
        }
        return 'Add Primary';
    };

    return (
        <>
            <SubSection
                title="Insurance"
                headingCenterContent={
                    <Stack horizontal horizontalAlign="space-between">
                        <Stack.Item>
                            <TooltipHost content={addInsuranceType()}>
                                <IconButton
                                    iconProps={{ iconName: 'Add' }}
                                    onClick={() => {
                                        setInsurance(getNewInsurance());
                                        setShowInsuranceModal(true);
                                        setIsNew(true);
                                    }}
                                    disabled={disableAddInsurance}
                                />
                            </TooltipHost>
                        </Stack.Item>
                        <Stack.Item>
                            <Toggle
                                label="Active Only"
                                inlineLabel
                                checked={!insuranceListHistory}
                                styles={{ root: { marginBottom: 3 } }}
                                onClick={() => dispatch(toggleShowInsuranceHistory())}
                            />
                        </Stack.Item>
                    </Stack>
                }
            >
                <Stack tokens={{ childrenGap: 10 }}>
                    {(!hasGuarantor || !hasInsurances) && (
                        <MessageBar>
                            {!hasGuarantor && patient?.dateOfBirth && calculateAgeInYears(patient.dateOfBirth) < 18 && (
                                <li>Patient is under 18 years old. Guarantor other than patient is required.</li>
                            )}
                            {!hasGuarantor && (
                                <li>
                                    No other guarantor was identified. Therefore, the patient will be the guarantor and
                                    responsible for the services rendered.
                                </li>
                            )}
                            {!hasInsurances && (
                                <li>Patient has no active insurance. Guarantor may be responsible for services rendered.</li>
                            )}
                        </MessageBar>
                    )}

                    <Stack tokens={{ childrenGap: 10 }}>
                        <Stack.Item>
                            <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
                                {insuranceCards}
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Stack>
            </SubSection>
            {_lookupPatientAppointmentBasicDetailTaskByType[TaskType.insuranceVerification] && isCheckinOrCheckout && (
                <Checkbox
                    label={`Verify insurance${
                        _currentAppointment?.lastVerifiedInsurance
                            ? ` (${format(new Date(_currentAppointment.lastVerifiedInsurance), 'MM/dd/yyyy h:mm a')})`
                            : ''
                    }`}
                    onRenderLabel={(data) => (
                        <TaskFieldLabel
                            label={data?.label}
                            lookup={_lookupPatientAppointmentBasicDetailTaskByType}
                            taskType={TaskType.insuranceVerification}
                        />
                    )}
                    onChange={() => {
                        dispatch(
                            updatePatientAppointmentProp({
                                path: 'lastVerifiedInsurance',
                                value: !_currentAppointment?.lastVerifiedInsurance ? new Date().toISOString() : undefined,
                            }),
                        );
                    }}
                    checked={!!_currentAppointment?.lastVerifiedInsurance}
                />
            )}
            <EligibilityModal
                setInsurance={setInsurance}
                insurance={insurance}
                patient={patient}
                setShowEligibilityModal={setShowEligibilityModal}
                showEligibilityModal={showEligibilityModal}
            />
            <InsuranceModal
                setInsurance={setInsurance}
                insurance={insurance}
                patient={patient}
                showInsuranceModal={showInsuranceModal}
                setShowInsuranceModal={setShowInsuranceModal}
                isNew={isNew}
            />
        </>
    );
}

function getNewInsurance(): IPatientInsurance {
    return {
        id: uuid(),
        createdOn: '',
        createdBy: '',
        modifiedOn: '',
        modifiedBy: '',
        isDeleted: false,
        insuranceId: '',
        planId: '',
        memberId: '',
        groupNumber: '',
        effectiveDate: '',
        expirationDate: '',
        allocationSet: '',
        type: '',
        policyHolderType: '',
        employer: '',
        policyHolder: {
            id: uuid(),
            lastName: '',
            firstName: '',
            middleName: '',
            chosenName: '',
            suffix: '',
            title: '',
            dateOfBirth: '',
            sexAtBirthId: '',
            ssn: '',
            relationship: '',
            policyHolderAddressSameAsPatient: false,
            streetAddress1: '',
            city: '',
            state: '',
            zip: '',
        },
        eligibility: {},
        documents: [],
    };
}

export default Insurance;

type InsuranceCardProps = {
    insurance: IPatientInsurance;
    editInsurance: (insurance: IPatientInsurance) => void;
    editEligibility: (insurance: IPatientInsurance) => void;
};

function InsuranceCard({ insurance, editEligibility, editInsurance }: InsuranceCardProps) {
    const dispatch = useDispatch();
    const { downloadDocumentById } = useDownloadFile();

    const insuranceDocuments = useSelector((state) =>
        selectPatientDocumentsByContext(state, {
            context: DocumentContext.Insurance,
            reference: { type: DocumentRefTypes.PatientInsurance, id: insurance.id },
        }),
    );
    const insuranceTasks = useSelector(selectInsuranceFinancialInfoByTaskTypeLookup);
    const _task = useSelector(selectRootFinancialInsuranceInfoByTaskTypeLookup);
    const benefitPlan = useSelector(benefitPlanCategories);
    const _filteredInsuranceList = useSelector(filteredInsuranceList);
    const payersWithDisplayName = useSelector(selectTenantPayersWithDisplayName);

    const cardMenuItems: IContextualMenuItem[] = [
        {
            key: 'editItem',
            text: 'Edit',
            onClick: () => editInsurance(insurance),
            iconProps: _task[TaskType.patientFinancialEffectiveDate] ? { iconName: 'Info', style: { color: 'red' } } : undefined,
        },
        {
            key: 'deactivateItem',
            text: !insurance.isDeleted ? 'Deactivate' : 'Activate',
            onClick: () => {
                dispatch(toggleInsuranceActive(insurance));
            },
        },
        {
            key: 'eligibility',
            text: 'Edit Eligibility',
            onClick: () => editEligibility(insurance),
            iconProps: _task[TaskType.InsuranceEligibilityVerification]
                ? { iconName: 'Info', style: { color: 'red' } }
                : undefined,
        },
    ];

    const menuButtonStyles: IButtonStyles = {
        flexContainer: {
            selectors: {
                '.ms-Button-menuIcon': {
                    display: 'none',
                },
            },
        },
    };

    const payer = payersWithDisplayName.find((payer) => payer?.id === insurance.insuranceId);

    const payerName = payer?.displayName ? payer?.displayName : payer?.name;
    const payerIcon = payer?.specialty === 'Dental' ? 'Teeth' : '';
    const payerPhoneNumber = payer?.phone ? formatPhoneNumber(payer?.phone) : 'N/A';

    const planIDName = benefitPlan.find((plan) => plan.key === insurance.planId);

    const planName = planIDName?.text;

    const indexOfActivePayer = _filteredInsuranceList.findIndex((ins) => ins.id === insurance?.id);
    const selectedToMakePrimary = _filteredInsuranceList.find((ins) => ins.id === insurance?.id);

    const expireInsurence = isBefore(new Date(insurance.expirationDate ?? ''), new Date()) ? ' - Expired' : '';

    if (indexOfActivePayer !== 0 && !insurance.isDeleted)
        cardMenuItems.push({
            key: 'updatePrimaryInsurance',
            text: 'Set as Primary',
            onClick: () => {
                if (selectedToMakePrimary) dispatch(setInsurancePrimary(selectedToMakePrimary));
            },
        });

    const insuranceType = () => {
        return insurancePriorityName[indexOfActivePayer];
    };

    const _insuranceTasks = (map(insuranceTasks[insurance.id]).filter((item) => item !== undefined) as IUserTask[]) ?? [];
    const policyHolderType = () => {
        if (insurance?.policyHolderType === 'Other Contact') {
            return ` ${insurance?.policyHolder?.firstName ?? ''} ${insurance?.policyHolder?.lastName ?? ''}  `;
        }
        return 'Self';
    };

    const getEffectiveDate = () => {
        const effectiveDate = insurance.effectiveDate;

        if (!effectiveDate) return 'N/A';
        if (!isAfter(new Date(insurance.effectiveDate), new Date('01/01/2001'))) return 'Invalid';

        return classicDateOnly(effectiveDate);
    };

    return (
        <ContentCard
            menuButtonProps={{
                menuProps: {
                    items: cardMenuItems,
                },
                styles: menuButtonStyles,
            }}
            rightContent={!insurance.isDeleted && <UserTasksBadge size={'small'} userTasks={_insuranceTasks}></UserTasksBadge>}
            title={''}
            isActive={!insurance.isDeleted}
        >
            <Stack>
                <Stack tokens={{ childrenGap: 10 }} verticalAlign="end" grow>
                    <Stack.Item>
                        <Stack>
                            <Stack tokens={{ childrenGap: 10 }} horizontal>
                                <Text variant="mediumPlus" block>
                                    <FontIcon aria-label={payerIcon} iconName={payerIcon} />
                                </Text>
                                <Text variant="mediumPlus" block>
                                    {payerName} {expireInsurence}
                                </Text>
                            </Stack>
                            <Text variant="small">
                                Payer ID: <strong>{payer?.payerId ? payer.payerId : 'N/A'}</strong>
                            </Text>
                            <Text variant="small">
                                Plan: <strong>{insurance.planId ? planName : 'N/A'}</strong>
                            </Text>
                            {insuranceType() && (
                                <Text variant="small">
                                    Type: <strong>{insuranceType()}</strong>
                                </Text>
                            )}
                            <Text variant="small">
                                Policy Holder: <strong>{policyHolderType()}</strong>
                            </Text>
                            {insurance?.policyHolderType === 'Other Contact' && (
                                <Text variant="small">
                                    {"Patient's Relationship to Policy Holder"}:
                                    <strong>{insurance.policyHolder?.relationship ?? ''}</strong>
                                </Text>
                            )}
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Text variant="small">
                                    Member Id: <strong>{insurance.memberId ? insurance.memberId : 'N/A'}</strong>
                                </Text>
                                <Text variant="small">
                                    Group #: <strong>{insurance.groupNumber ? insurance.groupNumber : 'N/A'}</strong>
                                </Text>
                            </Stack>
                            <Text variant="small">
                                Insurance Phone: <strong>{payerPhoneNumber}</strong>
                            </Text>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Text variant="small">
                                    Eff. Date: <strong>{getEffectiveDate()}</strong>
                                </Text>
                                <Text variant="small">
                                    Exp. Date:{' '}
                                    <strong>
                                        {insurance.expirationDate ? classicDateOnly(insurance.expirationDate) : 'N/A'}
                                    </strong>
                                </Text>
                            </Stack>
                            <TooltipHost
                                directionalHint={DirectionalHint.bottomCenter}
                                content={
                                    insurance.eligibility?.lastVerifiedBy ? (
                                        <Text>
                                            Last verified by: <UserDisplayName userId={insurance.eligibility?.lastVerifiedBy} />
                                        </Text>
                                    ) : undefined
                                }
                            >
                                <Text variant="small">
                                    Last Verified Date:{' '}
                                    <strong>
                                        {insurance?.eligibility?.lastVerified
                                            ? classicDateOnly(insurance?.eligibility.lastVerified)
                                            : 'N/A'}
                                    </strong>
                                </Text>
                            </TooltipHost>
                        </Stack>
                    </Stack.Item>
                </Stack>

                {insuranceDocuments.length && (
                    <>
                        <Separator />
                        <Stack>
                            <Text variant="small">
                                Documents:{' '}
                                {insuranceDocuments.map((doc, index) => (
                                    <Link
                                        key={index}
                                        onClick={() => {
                                            downloadDocumentById(doc.id);
                                        }}
                                        style={{ marginRight: 5 }}
                                    >
                                        <span>
                                            {doc.displayName}.{doc.extension}
                                        </span>
                                    </Link>
                                ))}
                            </Text>
                        </Stack>
                    </>
                )}
            </Stack>
        </ContentCard>
    );
}
