import { Dictionary } from '@reduxjs/toolkit';
import { ChartProcedureDiagnosis, ChartProcedureStatus, IChartProcedure } from 'api/models/chart.model';
import { ApplicableArea } from 'api/models/lookup.model';
import { IProcedure, IProcedureStage } from 'api/models/procedure.model';
import { ToothArea } from 'api/models/tooth-area';
import { forEach, map } from 'lodash';
import { v4 as uuid } from 'uuid';
import { ProcedureActionType } from './chart/chart.slice';
import Pipeline from './pipeline';
import { IChartProcedureWithOriginalProcedure } from './procedureCodeBusinessRules/procedureBusinessRules.pipeline';
import ProcedureCodes from './procedureCodes';
import { IProcedureWithDiagnoses } from './procedureDiagnosisPipeline/procedureDiagnosisPipeline.pipeline';

export type ToothPayload = { toothId?: number; area?: keyof typeof ToothArea };

type PipelineArgs = {
    newProcedures: IProcedure[];
    type: ProcedureActionType;
    selectedTeeth?: number[];
    areas?: (keyof typeof ToothArea)[];
    selectedProcedureTeeth?: Dictionary<number[]>;
    notes?: string;
    encounterId?: string;
    treatingProviderId?: string;
    respectiveChartProcedureIds?: string[]; // This is how I can tell that a procedure is being edited.
    currentChartProcedures: IChartProcedure[];
    proceduresWithDiagnosis?: IProcedureWithDiagnoses[];
    status?: ChartProcedureStatus;
    onSetDate?: string;
};

enum ChartingProcedurePipelineType {
    GeneralChartProcedures = 'generalChartProcedures',
    CreatesManyProceduresPerArea = 'createsManyProceduresPerArea',
    CreatesManyProcedures = 'createsManyProcedures',
    GroupedTeethProcedures = 'proceduresWithGroupedTeeth',
}

class ChartingProceduresPipeline extends Pipeline<IChartProcedure> {
    protected type: ProcedureActionType;
    protected status: ChartProcedureStatus | undefined;

    private newProcedures: IProcedure[];
    private proceduresWithDiagnosis?: IProcedureWithDiagnoses[];
    private selectedTeeth: number[] = [];
    private areas: (keyof typeof ToothArea)[] = [];
    private selectedProcedureTeeth: Dictionary<number[]> = {};
    private diagnosisCodes?: ChartProcedureDiagnosis[] = [];
    private treatingProviderId?: string;
    private encounterId?: string;
    private respectiveChartProcedureIds?: string[];
    private currentChartProcedures: IChartProcedure[];

    private onSetDate?: string;
    private createdOn?: string = new Date().toISOString();

    constructor({
        newProcedures,
        type,
        selectedTeeth,
        selectedProcedureTeeth,
        encounterId,
        treatingProviderId,
        respectiveChartProcedureIds,
        proceduresWithDiagnosis,
        currentChartProcedures,
        status,
        onSetDate,
        areas,
    }: PipelineArgs) {
        super([]);
        if (selectedTeeth) this.selectedTeeth = selectedTeeth;
        if (areas) this.areas = areas;
        this.newProcedures = newProcedures;
        this.type = type;
        this.onSetDate = onSetDate;
        this.status = status;
        this.treatingProviderId = treatingProviderId;
        this.respectiveChartProcedureIds = respectiveChartProcedureIds;
        this.encounterId = encounterId;
        this.proceduresWithDiagnosis = proceduresWithDiagnosis;
        this.currentChartProcedures = currentChartProcedures;
        if (selectedProcedureTeeth) this.selectedProcedureTeeth = selectedProcedureTeeth;
        this.createProceduresFromActions();
    }

    private createProceduresFromActions() {
        const proceduresForCreateFunctionLookup = {
            [ChartingProcedurePipelineType.GroupedTeethProcedures]: this.newProcedures.filter(
                createsProceduresWithCombinedToothIds,
            ),
            [ChartingProcedurePipelineType.CreatesManyProcedures]: this.newProcedures.filter(createsManyProcedures),
            [ChartingProcedurePipelineType.CreatesManyProceduresPerArea]: this.newProcedures.filter(createsManyProceduresPerArea),
            [ChartingProcedurePipelineType.GeneralChartProcedures]: [
                ...this.newProcedures.filter(hasGeneralApplicableArea),
                ...this.newProcedures.filter(doesNotHaveApplicableArea),
            ],
        };

        forEach(ChartingProcedurePipelineType, (key) => {
            const procedures = proceduresForCreateFunctionLookup[key];
            switch (key) {
                case ChartingProcedurePipelineType.GroupedTeethProcedures:
                    this.createProceduresWithGroupedTeeth(procedures);
                    break;
                case ChartingProcedurePipelineType.CreatesManyProcedures:
                    this.createProceduresThatCreateMany(procedures);
                    break;
                case ChartingProcedurePipelineType.CreatesManyProceduresPerArea:
                    this.createProceduresThatCreateManyPerArea(procedures);
                    break;
                case ChartingProcedurePipelineType.GeneralChartProcedures:
                    this.createGenericProcedures(procedures);
                    break;
                default:
                    this.createUnknownProcedure(procedures);
            }
        });

        this.createStagedProcedures();
    }

    private generateStagedProcedure(
        chartProcedure: IChartProcedure,
        procedure: IProcedure,
        procedureStage: IProcedureStage,
        indexOfProcedure: number,
    ) {
        const newChartProcedure: IChartProcedure = {
            ...this.getNewChartProcedure(procedure, indexOfProcedure),
            stage: procedureStage.stage,
            toothIds: chartProcedure.toothIds,
            billingStatus: procedureStage.billableStatus,
            areas: chartProcedure.areas,
        };
        return newChartProcedure;
    }

    private getCurrentChartProcedureById(chartProcedureId: string) {
        return this.currentChartProcedures.find((p) => p.id === chartProcedureId);
    }

    private createStagedProcedures() {
        this.items.forEach((chartProcedure, indexOfChartProcedure) => {
            const procedure = this.newProcedures.find((p) => p.id === chartProcedure.procedureId);
            if (procedure?.procedureStages?.length) {
                this.removeItem(chartProcedure);
                //Having respective chart procedure ids tells me that we are editing a procedure
                if (!this.respectiveChartProcedureIds?.length) {
                    procedure.procedureStages.forEach((stage, index) => {
                        this.addItem(this.generateStagedProcedure(chartProcedure, procedure, stage, index));
                    });
                } else {
                    const originalChartProcedure = this.getCurrentChartProcedureById(
                        this.respectiveChartProcedureIds[indexOfChartProcedure] ?? '',
                    );
                    if (originalChartProcedure)
                        this.addItem(
                            this.generateStagedProcedure(
                                chartProcedure,
                                procedure,
                                { stage: originalChartProcedure?.stage, billableStatus: originalChartProcedure.billingStatus },
                                indexOfChartProcedure,
                            ),
                        );
                }
            }
        });
    }

    private getDiagnosesForProcedure(procedure: IProcedure): ChartProcedureDiagnosis[] {
        const procedureWithDx = this.proceduresWithDiagnosis?.find((p) => p.id === procedure.id);
        return procedureWithDx?.diagnosisItems
            ? procedureWithDx?.diagnosisItems.map((dx) => ({ id: dx.id, description: dx.description, displayName: dx.code }))
            : [];
    }

    private get noStatus() {
        return this.type === ProcedureActionType.Existing || this.type === ProcedureActionType.ExistingOther;
    }

    private getNewChartProcedure(procedure: IProcedure, indexOfProcedure: number): IChartProcedureWithOriginalProcedure {
        const id = this.respectiveChartProcedureIds?.length ? this.respectiveChartProcedureIds[indexOfProcedure] : uuid();
        const dx = this.getDiagnosesForProcedure(procedure);
        const diagnosisCodes = this.diagnosisCodes ? [...this.diagnosisCodes, ...dx] : [...dx];
        const status = this.status ? this.status : this.noStatus ? undefined : ChartProcedureStatus.Pending;
        const chartProcedureToEdit = this.currentChartProcedures.find((c) => c.id === id);

        return {
            id,
            procedureId: procedure.id,
            status,
            type: this.type ? this.type : undefined,
            encounterId: this.encounterId,
            modifiedOn: '',
            originalProcedureId: procedure.id,
            preAuthorization: procedure.preAuthStatus,
            onSetDate: this.onSetDate,
            isDeleted: false,
            createdOn: this.createdOn,
            diagnosisCodes,
            treatingProviderId: this.treatingProviderId,
            billingStatus: chartProcedureToEdit?.billingStatus ? chartProcedureToEdit.billingStatus : procedure.billingStatus,
        };
    }

    private generateGroupedTeethChartProcedure(procedure: IProcedure, indexOfProcedure: number) {
        return {
            ...this.getNewChartProcedure(procedure, indexOfProcedure),
            toothIds:
                this.selectedProcedureTeeth && this.selectedProcedureTeeth[procedure.id]?.length
                    ? this.selectedProcedureTeeth[procedure.id] ?? []
                    : this.selectedTeeth,
        };
    }

    private createProceduresWithGroupedTeeth(procedures: IProcedure[]): void {
        procedures.forEach((procedure, index) => {
            this.addItem(this.generateGroupedTeethChartProcedure(procedure, index));
        });
    }

    private generateCreateManyChartProcedure(procedure: IProcedure, toothId: number, indexOfProcedure: number) {
        return {
            ...this.getNewChartProcedure(procedure, indexOfProcedure),
            toothIds: [toothId],
            preAuthorization: this.noStatus ? undefined : procedure.preAuthStatus,
        };
    }

    private generateCreateManyPerAreaChartProcedure(
        procedure: IProcedure,
        area: keyof typeof ToothArea,
        indexOfProcedure: number,
    ) {
        return {
            ...this.getNewChartProcedure(procedure, indexOfProcedure),
            toothIds: [],
            areas: [area],
            preAuthorization: this.noStatus ? undefined : procedure.preAuthStatus,
        };
    }

    private createProceduresThatCreateMany(procedures: IProcedure[]): void {
        procedures.forEach((procedure) => {
            const allSelectedTeeth =
                this.selectedProcedureTeeth && this.selectedProcedureTeeth[procedure.id]?.length
                    ? this.selectedProcedureTeeth[procedure.id] ?? []
                    : this.selectedTeeth;

            allSelectedTeeth.forEach((toothId, index) => {
                this.addItem(this.generateCreateManyChartProcedure(procedure, toothId, index));
            });
        });
    }

    private createProceduresThatCreateManyPerArea(procedures: IProcedure[]): void {
        procedures.forEach((procedure) => {
            this.areas.forEach((area, index) => {
                this.addItem(this.generateCreateManyPerAreaChartProcedure(procedure, area, index));
            });
        });
    }

    private generateGenericChartProcedure(procedure: IProcedure, indexOfProcedure: number) {
        const newType = this.type ? this.type : ProcedureActionType.Treatment;
        return {
            ...this.getNewChartProcedure(procedure, indexOfProcedure),
            type: newType,
            originalProcedureId: procedure.id,
        } as IChartProcedureWithOriginalProcedure;
    }

    private createGenericProcedures(procedures: IProcedure[]) {
        procedures.forEach((procedure, index) => {
            this.addItem(this.generateGenericChartProcedure(procedure, index));
        });
    }

    private generateUnkownProcedure(procedure: IProcedure, indexOfProcedure: number) {
        return {
            ...this.getNewChartProcedure(procedure, indexOfProcedure),
            status: ChartProcedureStatus.Completed,
        };
    }

    private createUnknownProcedure(procedures: IProcedure[]) {
        procedures.forEach((procedure, index) => {
            this.addItem(this.generateUnkownProcedure(procedure, index));
        });
    }
}

export function createsProceduresWithCombinedToothIds(proc: IProcedure): boolean {
    const groupingCodes = [
        ProcedureCodes.D4341,
        ProcedureCodes.D4342,
        ProcedureCodes.D4241,
        ProcedureCodes.D4240,
        ProcedureCodes.D4260,
        ProcedureCodes.D4261,
        ProcedureCodes.D7320,
        ProcedureCodes.D7321,
        ProcedureCodes.D7311,
        ProcedureCodes.D7310,
        ProcedureCodes.D5421,
        ProcedureCodes.D5422,
        ProcedureCodes.D5211,
        ProcedureCodes.D5212,
        ProcedureCodes.D5213,
        ProcedureCodes.D5214,
        ProcedureCodes.D5221,
        ProcedureCodes.D5222,
        ProcedureCodes.D5223,
        ProcedureCodes.D5224,
        ProcedureCodes.D5225,
        ProcedureCodes.D5226,
        ProcedureCodes.D5227,
        ProcedureCodes.D5228,
        ProcedureCodes.D5282,
        ProcedureCodes.D5283,
        ProcedureCodes.D5284,
        ProcedureCodes.D5286,
        ProcedureCodes.D5720,
        ProcedureCodes.D5721,
        ProcedureCodes.D5740,
        ProcedureCodes.D5741,
        ProcedureCodes.D5760,
        ProcedureCodes.D5761,
        ProcedureCodes.D5765,
        ProcedureCodes.D5820,
        ProcedureCodes.D5821,
        ProcedureCodes.D5864,
        ProcedureCodes.D5865,
        ProcedureCodes.D5866,
        ProcedureCodes.D5867,
        ProcedureCodes.D5899,
    ];

    const isCombined = groupingCodes.findIndex((code) => proc.code === code) > -1;
    return isCombined;
}

export function createsManyProcedures(proc: IProcedure): boolean {
    return (
        !!proc?.applicableArea &&
        (proc.applicableArea === ApplicableArea.Crown ||
            proc.applicableArea === ApplicableArea.Tooth ||
            proc.applicableArea === ApplicableArea.Surface ||
            proc.applicableArea === ApplicableArea.Root ||
            proc.applicableArea === ApplicableArea.Quadrant) &&
        !createsProceduresWithCombinedToothIds(proc)
    );
}

export function createsManyProceduresPerArea(proc: IProcedure): boolean {
    return proc.applicableArea === ApplicableArea.Arch;
}

export function doesNotHaveApplicableArea(proc: IProcedure): boolean {
    return !proc?.applicableArea;
}
export function hasGeneralApplicableArea(proc: IProcedure): boolean {
    return proc?.applicableArea
        ? proc.applicableArea === ApplicableArea.MandibularArch ||
              proc.applicableArea === ApplicableArea.MaxillaryArch ||
              proc.applicableArea === ApplicableArea.Mouth ||
              proc.applicableArea === ApplicableArea.Exam
        : false;
}

export default ChartingProceduresPipeline;
