import { createSlice, createSelector, PayloadAction, Dictionary } from '@reduxjs/toolkit';
import { IPalette, ITheme } from '@fluentui/react';
import { ITooltipCard } from 'pages/components/TooltipCards/TooltipCard';

import { RootState } from 'state/store';
import appLocalStorage from 'utils/appLocalStorage';

type IPaletteCustom = Partial<IPalette> & { navbar: string; navbarLighter: string };
type EventColors = {
    trackerStatus: {
        [key: string]: {
            highlight: string;
            backgroundColor: string;
            buttonColor: string;
        };
    };
    trackerEncounterStatus: {
        [key: string]: {
            highlight: string;
            backgroundColor: string;
            buttonColor: string;
            color: string;
        };
    };
};

export type CustomThemeProps = {
    palette: IPaletteCustom;
    eventColors: EventColors;
};
export type IThemeCustom = ITheme & CustomThemeProps;

export const darkTheme: CustomThemeProps = {
    palette: {
        themePrimary: '#48a7fa',
        themeLighterAlt: '#03070a',
        themeLighter: '#0c1b28',
        themeLight: '#16324b',
        themeTertiary: '#2b6496',
        themeSecondary: '#4093dc',
        themeDarkAlt: '#5ab0fa',
        themeDark: '#74bcfb',
        themeDarker: '#98cdfc',
        neutralLighterAlt: '#1c1c1c',
        neutralLighter: '#252525',
        neutralLight: '#343434',
        neutralQuaternaryAlt: '#3d3d3d',
        neutralQuaternary: '#454545',
        neutralTertiaryAlt: '#656565',
        neutralTertiary: '#858585',
        neutralSecondary: '#ececec',
        neutralPrimaryAlt: '#f0f0f0',
        neutralPrimary: '#dddddd',
        neutralDark: '#f7f7f7',
        black: '#fbfbfb',
        white: '#111111',
        navbar: '#1d375a',
        navbarLighter: '#28538e',
    },
    eventColors: {
        trackerStatus: {
            arrived: {
                backgroundColor: '#8169A7',
                highlight: '#EDEAF3',
                buttonColor: '#387D8C',
            },
            ready: {
                backgroundColor: '#437a37',
                highlight: '#EEF5ED',
                buttonColor: '#387D8C',
            },
            inTreatment: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#E6F3F7',
            },
            checkout: {
                backgroundColor: '#68696A',
                highlight: '#EFF1F1',
                buttonColor: '#107C10',
            },
            attested: {
                backgroundColor: '#68696A',
                highlight: '#EFF1F1',
                buttonColor: '#8169A7',
            },
            amendRequired: {
                backgroundColor: '#68696A',
                highlight: '#EFF1F1',
                buttonColor: '#FFB900',
            },
            completed: {
                backgroundColor: '#444',
                highlight: '#8D8D8D',
                buttonColor: '#8D8D8D',
            },

            canceled: {
                highlight: '#FFDBDB',
                backgroundColor: '#7A0A04',
                buttonColor: '#FFDBDB',
            },
        },
        trackerEncounterStatus: {
            created: {
                backgroundColor: '#8169A7',
                highlight: '#EDEAF3',
                buttonColor: '#E6F3F7',
                color: 'black',
            },
            readyForAttestation: {
                backgroundColor: '#437a37',
                highlight: '#EEF5ED',
                buttonColor: '#107C10',
                color: 'white',
            },
            attested: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#8169A7',
                color: 'white',
            },
            amendRequired: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#FFB900',
                color: 'white',
            },
            completed: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#8D8D8D',
                color: 'white',
            },
            conversionImported: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#8D8D8D',
                color: 'white',
            },
            canceled: {
                color: 'black',
                highlight: '#FFDBDB',
                backgroundColor: '#7A0A04',
                buttonColor: '#FFDBDB',
            },
        },
    },
};

export const athenaTheme: CustomThemeProps = {
    palette: {
        themePrimary: '#0275d4',
        themeLighterAlt: '#f3f9fd',
        themeLighter: '#d1e6f8',
        themeLight: '#aad2f2',
        themeTertiary: '#5da8e5',
        themeSecondary: '#1c84d9',
        themeDarkAlt: '#026abe',
        themeDark: '#0259a1',
        themeDarker: '#014277',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
        navbar: '#514b7b',
        navbarLighter: '#393556',
    },
    eventColors: {
        trackerStatus: {
            arrived: {
                backgroundColor: '#EDEAF3',
                highlight: '#8169A7',
                buttonColor: '#387D8C',
            },
            ready: {
                backgroundColor: '#EEF5ED',
                highlight: '#437a37',
                buttonColor: '#387D8C',
            },
            inTreatment: {
                backgroundColor: '#E6F3F7',
                highlight: '#3B7D8C',
                buttonColor: '#387D8C',
            },
            checkout: {
                backgroundColor: '#EFF1F1',
                highlight: '#68696A',
                buttonColor: '#107C10',
            },
            completed: {
                backgroundColor: '#8D8D8D',
                highlight: '#444',
                buttonColor: '#444444',
            },
            canceled: {
                backgroundColor: '#FFDBDB',
                highlight: '#7A0A04',
                buttonColor: '#7A0A04',
            },
        },
        trackerEncounterStatus: {
            created: {
                backgroundColor: '#8169A7',
                highlight: '#EDEAF3',
                buttonColor: '#387D8C',
                color: 'white',
            },
            readyForAttestation: {
                backgroundColor: '#437a37',
                highlight: '#EEF5ED',
                buttonColor: '#107C10',
                color: 'white',
            },
            attested: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#8169A7',
                color: 'white',
            },
            amendRequired: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#FFB900',
                color: 'white',
            },
            completed: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#444444',
                color: 'white',
            },
            conversionImported: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#444444',
                color: 'white',
            },
            canceled: {
                backgroundColor: '#FFDBDB',
                highlight: '#7A0A04',
                buttonColor: '#7A0A04',
                color: 'white',
            },
        },
    },
};

export const lightTheme: CustomThemeProps = {
    palette: {
        themePrimary: '#006aff',
        themeLighterAlt: '#f5f9ff',
        themeLighter: '#d6e7ff',
        themeLight: '#b3d2ff',
        themeTertiary: '#66a6ff',
        themeSecondary: '#1f7cff',
        themeDarkAlt: '#0060e6',
        themeDark: '#0051c2',
        themeDarker: '#003b8f',
        neutralLighterAlt: '#f1f1f1',
        neutralLighter: '#e0e0e0',
        neutralLight: '#d9d9d9',
        neutralQuaternaryAlt: '#d3d3d3',
        neutralQuaternary: '#cacaca',
        neutralTertiaryAlt: '#c2c2c2',
        neutralTertiary: '#b2b2b2',
        neutralSecondary: '#999999',
        neutralPrimaryAlt: '#808080',
        neutralPrimary: '#1c1c1c',
        neutralDark: '#4e4e4e',
        black: '#353535',
        white: '#f7f7f7',
        navbar: '#1d375a',
        navbarLighter: '#28538e',
    },
    eventColors: {
        trackerStatus: {
            arrived: {
                backgroundColor: '#EDEAF3',
                highlight: '#8169A7',
                buttonColor: '#387D8C',
            },
            ready: {
                backgroundColor: '#EEF5ED',
                highlight: '#437a37',
                buttonColor: '#387D8C',
            },
            inTreatment: {
                backgroundColor: '#E6F3F7',
                highlight: '#3B7D8C',
                buttonColor: '#387D8C',
            },
            checkout: {
                backgroundColor: '#EFF1F1',
                highlight: '#68696A',
                buttonColor: '#107C10',
            },
            completed: {
                backgroundColor: '#8D8D8D',
                highlight: '#444',
                buttonColor: '#8D8D8D',
            },
            canceled: {
                backgroundColor: '#FFDBDB',
                highlight: '#7A0A04',
                buttonColor: '#7A0A04',
            },
        },
        trackerEncounterStatus: {
            created: {
                backgroundColor: '#8169A7',
                highlight: '#EDEAF3',
                buttonColor: '#387D8C',
                color: 'black',
            },
            readyForAttestation: {
                backgroundColor: '#437a37',
                highlight: '#EEF5ED',
                buttonColor: '#107C10',
                color: 'white',
            },
            attested: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#8169A7',
                color: 'white',
            },
            amendRequired: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#FFB900',
                color: 'white',
            },
            completed: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#8D8D8D',
                color: 'white',
            },
            conversionImported: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#8D8D8D',
                color: 'white',
            },
            canceled: {
                backgroundColor: '#FFDBDB',
                highlight: '#7A0A04',
                buttonColor: '#7A0A04',
                color: 'white',
            },
        },
    },
};

const themes: Dictionary<CustomThemeProps> = {
    athena: athenaTheme,
    default: lightTheme,
};

const themeToUse = themes['#{AppTheme}#'] ? themes['#{AppTheme}#'] : athenaTheme;

const initialState: UiState = {
    tooltipCards: [],
    theme: themeToUse,
    patientBannerExpanded: appLocalStorage.patientBannerExpanded,
    selectedTheme: appLocalStorage.theme,
    activityBarExpanded: appLocalStorage.activityBarExpanded,
    schedulingToolBarExpanded: appLocalStorage.schedulingToolbarExpanded,
    newPatientDemographicsIsOpen: false,
    editPatientDemographicsIsOpen: false,
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setTheme(state, action: PayloadAction<'dark' | 'light'>) {
            const { payload } = action;
            state.selectedTheme = payload;
        },
        setPatientBannerExpanded(state, action: PayloadAction<boolean>) {
            state.patientBannerExpanded = action.payload;
        },
        setActivityBarExpanded(state, action: PayloadAction<boolean>) {
            state.activityBarExpanded = action.payload;
        },
        setSchedulingToolBarExpanded(state, action: PayloadAction<boolean>) {
            state.schedulingToolBarExpanded = action.payload;
        },
        addTooltipCard(state, action: PayloadAction<ITooltipCard>) {
            state.tooltipCards = [action.payload, ...state.tooltipCards];
        },
        removeTooltipCard(state, action) {
            const id = action.payload;
            state.tooltipCards = state.tooltipCards.filter((card) => card.id !== id);
        },
        setNewPatientDemographicsPanelIsOpen: (state, action: PayloadAction<boolean>) => {
            state.newPatientDemographicsIsOpen = action.payload;
        },
        setEditPatientDemographicsPanelIsOpen: (state, action: PayloadAction<boolean>) => {
            state.editPatientDemographicsIsOpen = action.payload;
        },
    },
});

const { reducer, actions } = uiSlice;

export const {
    addTooltipCard,
    removeTooltipCard,
    setTheme,
    setPatientBannerExpanded,
    setActivityBarExpanded,
    setSchedulingToolBarExpanded,
    setNewPatientDemographicsPanelIsOpen,
    setEditPatientDemographicsPanelIsOpen,
} = actions;

export const selectTheme = (state: RootState) => state.ui.selectedTheme;
export const getSelectedTheme = createSelector(selectTheme, (theme) => (theme === 'dark' ? darkTheme : athenaTheme));

export const selectNewPatientDemographicsIsOpen = (state: RootState): boolean => state.ui.newPatientDemographicsIsOpen;
export const selectEditPatientDemographicsIsOpen = (state: RootState): boolean => state.ui.editPatientDemographicsIsOpen;

export default reducer;

type UiState = {
    theme: CustomThemeProps;
    selectedTheme: 'dark' | 'light';
    patientBannerExpanded: boolean;
    activityBarExpanded: boolean;
    schedulingToolBarExpanded: boolean;
    tooltipCards: ITooltipCard[];
    newPatientDemographicsIsOpen: boolean;
    editPatientDemographicsIsOpen: boolean;
};
