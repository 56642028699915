import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    Link,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    Pivot,
    PivotItem,
    PrimaryButton,
    Stack,
    Text,
} from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import IPatient from 'api/models/patient.model';
import { usePatientId, useSelector, useValidation } from 'hooks';
import { IValidationConfig } from 'hooks/useValidation';
import { AgriculturalHomelessTypeFields } from 'pages/Scheduling/components/Checkin/BasicDetailsSection';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { cleanupPatientContacts, getAllPatientContacts } from 'state/slices/contacts/contacts.slice';
import { cleanupPendingDocumentsAndFiles } from 'state/slices/documents/documents.slice';
import {
    discardPatientChanges,
    getPatientToEditAndOpenPanel,
    upsertEditPatient,
} from 'state/slices/edit-patient/edit-patient.actions';
import {
    selectEditPatienIsNewPatient,
    selectEditPatient,
    selectEditPatientError,
    selectEditPatientHasChange,
    selectEditPatientUpcomingAppointments,
    selectEditPatientValidationErrors,
} from 'state/slices/edit-patient/edit-patient.selectors';
import {
    cleanupEditPatientPanel,
    setEditPatientPanelOpen,
    setEditPatientValidationErrors,
} from 'state/slices/edit-patient/edit-patient.slice';
import ErrorTypeMessageBar from './ErrorTypeMessageBar';
import states from './Field/StateOptions';
import { BasicDetailsFields } from './PatientDemographics/BasicDetails/BasicDetails';
import { HouseholdIncomeFields } from './PatientDemographics/Finance';
import { SignatureTypeFields } from './PatientDemographics/Financial/components/PracticesAndSignature';
import PatientDemographics from './PatientDemographics/PatientDemographics';
import PatientDetailsCard from 'pages/components/PatientDetailsCard';

export function generatePatientValidationConfig(patient: IPatient | undefined): IValidationConfig {
    const physicalAddressStateType = states.find((state) => patient?.physicalAddress?.state === state.key)?.data?.type;
    const mailingAddressStateType = states.find((state) => patient?.mailingAddress?.state === state.key)?.data?.type;

    const validationConfig: IValidationConfig = [
        { fieldName: BasicDetailsFields.FirstName, validation: ['required'], value: patient?.firstName },
        { fieldName: BasicDetailsFields.LastName, validation: ['required'], value: patient?.lastName },
        { fieldName: BasicDetailsFields.LegalSex, validation: ['required'], value: patient?.legalSexId },
        {
            fieldName: BasicDetailsFields.DateOfBirth,
            validation: ['required', 'notFutureDate', 'minMaxDate'],
            value: patient?.dateOfBirth,
        },
        {
            fieldName: BasicDetailsFields.StreetAddress1,
            validation: ['required'],
            value: patient?.physicalAddress?.streetAddress1,
        },
        { fieldName: BasicDetailsFields.City, validation: ['required'], value: patient?.physicalAddress?.city },
        { fieldName: BasicDetailsFields.State, validation: ['required'], value: patient?.physicalAddress?.state },
        {
            fieldName: BasicDetailsFields.Zip,
            validation: ['required', 'zip'],
            value: patient?.physicalAddress?.zip,
            itemOptions: { stateType: physicalAddressStateType },
        },
        { fieldName: BasicDetailsFields.PhoneNumbers, validation: ['required'], value: patient?.phoneNumbers },
    ];

    if (patient?.uds?.slidingFeeIncomeStatus && patient?.uds?.slidingFeeIncomeStatus !== 'Declined') {
        validationConfig.push(
            {
                fieldName: HouseholdIncomeFields.FamilySize,
                validation: ['required'],
                value: patient?.uds?.familySize === 0 ? undefined : patient?.uds?.familySize,
            },
            {
                fieldName: HouseholdIncomeFields.Income,
                validation: ['required'],
                //Fakes out the validation so we can have a income of 0 for a patient.
                value: patient?.uds?.income === 0 ? 1 : patient?.uds?.income,
            },
        );
    }

    if (patient?.uds?.agriculturalWorker === 'Yes') {
        validationConfig.push({
            fieldName: AgriculturalHomelessTypeFields.AgriculturalType,
            validation: ['required'],
            value: patient?.uds?.agriculturalWorkerDetail,
        });
    }

    if (patient?.uds?.homelessStatus === 'Yes') {
        validationConfig.push({
            fieldName: AgriculturalHomelessTypeFields.HomelessType,
            validation: ['required'],
            value: patient?.uds?.homelessStatusDetail,
        });
    }

    if (patient?.signature?.privacyNoticeSigned === true) {
        validationConfig.push({
            fieldName: SignatureTypeFields.PrivacyNotice,
            validation: ['required', 'minMaxDate', 'notFutureDate'],
            value: patient?.signature?.privacyNoticeSignedDate,
            itemOptions: { minDate: new Date('01/01/2001') },
        });
    }
    if (patient?.signature?.billingReleaseSigned === true) {
        validationConfig.push(
            {
                fieldName: SignatureTypeFields.BillingEffectiveDate,
                validation: ['required', 'minMaxDate', 'notFutureDate'],
                value: patient?.signature?.billingReleaseSignedDate,
                itemOptions: { minDate: new Date('01/01/2001') },
            },
            {
                fieldName: SignatureTypeFields.BillingExpirationDate,
                validation: ['required', 'minMaxDate'],
                value: patient?.signature?.billingReleaseExpirationDate,
                itemOptions: {
                    minDate: patient?.signature?.billingReleaseSignedDate
                        ? new Date(patient?.signature?.billingReleaseSignedDate)
                        : undefined,
                },
            },
        );
    }
    if (patient?.signature?.benefitAssignmentSigned === true) {
        validationConfig.push(
            {
                fieldName: SignatureTypeFields.AssignmentEffectiveDate,
                validation: ['required', 'minMaxDate', 'notFutureDate'],
                value: patient?.signature?.benefitAssignmentSignedDate,
                itemOptions: { minDate: new Date('01/01/2001') },
            },
            {
                fieldName: SignatureTypeFields.AssignmentExpirationDate,
                validation: ['required', 'minMaxDate'],
                value: patient?.signature?.benefitAssignmentExpirationDate,
                itemOptions: {
                    minDate: patient?.signature?.benefitAssignmentSignedDate
                        ? new Date(patient?.signature?.benefitAssignmentSignedDate)
                        : undefined,
                },
            },
        );
    }
    if (
        patient?.slidingFees &&
        patient?.uds?.slidingFeeIncomeStatus === 'Slide' &&
        patient?.uds?.incomePercentage &&
        patient?.uds?.incomePercentage < 200
    ) {
        validationConfig.push({
            fieldName: 'SF Effective Date',
            validation: ['minMaxDate', 'notFutureDate', 'required'],
            value: patient?.slidingFees[0]?.effectiveDate,
            itemOptions: { minDate: new Date('01/01/2001') },
        });
    }
    if (
        patient?.slidingFees &&
        patient?.slidingFees[0]?.effectiveDate &&
        patient?.uds?.slidingFeeIncomeStatus === 'Slide' &&
        patient?.uds?.incomePercentage &&
        patient?.uds?.incomePercentage < 200
    ) {
        validationConfig.push({
            fieldName: 'SF Expiration Date',
            validation: ['minMaxDate', 'required'],
            value: patient?.slidingFees[0]?.expirationDate,
            itemOptions: { minDate: new Date(patient?.slidingFees[0]?.effectiveDate) },
        });
    }

    if (!patient?.mailingAddressSameAsPhysicalAddress) {
        validationConfig.push(
            {
                fieldName: BasicDetailsFields.MailingStreet1,
                validation: ['required'],
                value: patient?.mailingAddress?.streetAddress1,
            },
            { fieldName: BasicDetailsFields.MailingCity, validation: ['required'], value: patient?.mailingAddress?.city },
            { fieldName: BasicDetailsFields.MailingState, validation: ['required'], value: patient?.mailingAddress?.state },
            {
                fieldName: BasicDetailsFields.MailingZip,
                validation: ['required', 'zip'],
                value: patient?.mailingAddress?.zip,
                itemOptions: { stateType: mailingAddressStateType },
            },
        );
    }

    return validationConfig;
}

export enum EditPatientMenuItem {
    BasicDetails = 'BasicDetails',
    Contacts = 'Contacts',
    Finance = 'Finance',
    Payers = 'Payers',
    VisitHistory = 'VisitHistory',
    Communincation = 'Communincation',
}

type EditPatientPanelProps = {
    isOpen: boolean;
    onSaveEditPatient?: () => void;
    onDismiss?: () => void;
};

function EditPatientPanel({ isOpen, onSaveEditPatient, onDismiss }: EditPatientPanelProps): JSX.Element {
    const dispatch = useDispatch();
    const validationErrors = useSelector(selectEditPatientValidationErrors);
    const patientId = usePatientId();
    const hasChange = useSelector(selectEditPatientHasChange);
    const error = useSelector(selectEditPatientError);
    const patient = useSelector(selectEditPatient);
    const upcomingAppointments = useSelector(selectEditPatientUpcomingAppointments);
    const location = useLocation();
    const routePieces = location.pathname.split('/').filter((string) => string !== '/' && string !== '');
    const tenantId = routePieces[0];

    const [selectedPivotKey, setSelectedPivotKey] = useState<EditPatientMenuItem>(EditPatientMenuItem.BasicDetails);
    const [submissionCompleted, setSubmissionCompleted] = useState(false);

    const isNewPatient = useSelector(selectEditPatienIsNewPatient);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const submitAndToggleDialog = () => {
        submit();
        setSubmissionCompleted(true);
    };

    useEffect(() => {
        if (submissionCompleted && !error && !hideDialog) {
            toggleHideDialog();
            setSubmissionCompleted(false); // Reset for the next submission
        }
    }, [submissionCompleted]);

    const _closePanel = () => {
        if (hasChange) {
            toggleHideDialog();
        } else {
            if (onDismiss) {
                onDismiss();
            } else {
                dispatch(setEditPatientPanelOpen(false));
            }
        }
    };

    const _onDismissed = () => {
        dispatch(cleanupPendingDocumentsAndFiles());
        dispatch(cleanupEditPatientPanel());
        dispatch(cleanupPatientContacts());
        setSelectedPivotKey(EditPatientMenuItem.BasicDetails);
    };

    const _onSave = () => {
        if (onSaveEditPatient) {
            onSaveEditPatient();
        } else {
            dispatch(upsertEditPatient(tenantId));
        }

        if (!hideDialog) toggleHideDialog();
    };

    const _handleDiscardPatientChanges = () => {
        toggleHideDialog();
        dispatch(discardPatientChanges());
    };

    useEffect(() => {
        if (patient?.id && tenantId && isOpen) {
            dispatch(getAllPatientContacts({ tenantId, patientId: patient.id }));
        }
    }, [patient?.id, tenantId, dispatch, isOpen]);

    const [errors, submit, cleanupErrors] = useValidation(generatePatientValidationConfig(patient), _onSave);

    useEffect(() => {
        dispatch(setEditPatientValidationErrors(errors));
    }, [errors]);

    const onClickRefreash = () => {
        if (patientId) dispatch(getPatientToEditAndOpenPanel({ tenantId, patientId }));
        if (!hideDialog && !!error) toggleHideDialog();
    };

    useEffect(() => {
        if (!isOpen) cleanupErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const _onRenderFooterContent = () => {
        return (
            <Stack tokens={{ childrenGap: 10 }}>
                {error ? (
                    <Stack tokens={{ childrenGap: 2 }}>
                        <ErrorTypeMessageBar error={error}>
                            {' '}
                            <Link onClick={onClickRefreash}> Refresh </Link> to view the most current information.
                        </ErrorTypeMessageBar>
                    </Stack>
                ) : null}

                {validationErrors.length && !error ? (
                    <Stack tokens={{ childrenGap: 2 }}>
                        <MessageBar messageBarType={MessageBarType.error}>
                            Required fields on the Patient Info Tab or Household Income Tab are not complete. Unable to save
                        </MessageBar>
                    </Stack>
                ) : null}
                {hasChange && !error ? (
                    <Stack tokens={{ childrenGap: 2 }}>
                        <MessageBar messageBarType={MessageBarType.warning}>
                            There was a change to the patient demographics. Please save to keep changes.
                        </MessageBar>
                    </Stack>
                ) : null}
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton text="Save patient data" onClick={submit} disabled={!!error} />
                    <DefaultButton text="Close" onClick={_closePanel} />
                </Stack>
            </Stack>
        );
    };

    function _onLinkClick(item?: PivotItem | undefined) {
        if (item?.props.itemKey) setSelectedPivotKey(item.props.itemKey as EditPatientMenuItem);
    }

    return (
        <Panel
            isOpen={isOpen}
            type={PanelType.custom}
            customWidth={'50vw'}
            isBlocking
            isLightDismiss={false}
            onDismiss={(ev) => {
                // Don't close on random mousedown event, will mess up the cancel appt modal...
                if (ev?.type !== 'mousedown') _closePanel();
            }}
            onDismissed={_onDismissed}
            onRenderFooterContent={_onRenderFooterContent}
            isFooterAtBottom={true}
            headerText={isNewPatient ? 'Add new patient' : 'Patient profile'}
            onRenderHeader={(renderProps) => {
                return (
                    <Stack
                        grow
                        tokens={{ childrenGap: 5 }}
                        style={{
                            paddingLeft: 24,
                            padding: '5px 12px',
                            width: '100%',
                            height: '100%',
                            boxSizing: 'border-box',
                            overflowX: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Text variant="xLarge">{renderProps?.headerText}</Text>
                        <PatientDetailsCard
                            onPageNavigationClick={_closePanel}
                            showClinicalAlerts
                            patient={patient}
                            upcomingAppointments={upcomingAppointments}
                        />
                        <Pivot onLinkClick={_onLinkClick} styles={{ icon: { color: 'red' } }}>
                            <PivotItem itemKey={EditPatientMenuItem.BasicDetails} headerText="Patient Info"></PivotItem>
                            <PivotItem itemKey={EditPatientMenuItem.Contacts} headerText="Contacts"></PivotItem>
                            <PivotItem itemKey={EditPatientMenuItem.Payers} headerText="Payers"></PivotItem>
                            <PivotItem itemKey={EditPatientMenuItem.Finance} headerText="Household Income"></PivotItem>
                            {!isNewPatient && (
                                <PivotItem itemKey={EditPatientMenuItem.VisitHistory} headerText="Appointments"></PivotItem>
                            )}
                            {!isNewPatient && (
                                <PivotItem itemKey={EditPatientMenuItem.Communincation} headerText="Communication"></PivotItem>
                            )}
                        </Pivot>
                    </Stack>
                );
            }}
            styles={{
                content: { overflowY: 'auto', overflowX: 'hidden', flex: 1, position: 'relative' },
                root: { overflow: 'hidden' },
                scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
            }}
        >
            <Stack tokens={{ childrenGap: 10 }}>
                <PatientDemographics selectedPivotKey={selectedPivotKey} />
            </Stack>
            <Dialog
                hidden={hideDialog}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Unsaved Changes',
                    subText: `Your changes have not been saved. Choose 'Save Patient Data' to save or 'Cancel' to discard the changes.`,
                }}
                onDismiss={toggleHideDialog}
                modalProps={{ isBlocking: true }}
            >
                {error ? (
                    <Stack tokens={{ childrenGap: 2 }}>
                        <ErrorTypeMessageBar error={error}>
                            {' '}
                            <Link onClick={onClickRefreash}> Refresh </Link> to view the most current information.
                        </ErrorTypeMessageBar>
                    </Stack>
                ) : null}
                <DialogFooter>
                    <PrimaryButton text="Save patient data" onClick={submitAndToggleDialog} disabled={!!error} />
                    <DefaultButton text="Cancel" onClick={_handleDiscardPatientChanges} />
                </DialogFooter>
            </Dialog>
        </Panel>
    );
}

export default EditPatientPanel;
