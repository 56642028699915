import { usePatientId } from 'hooks';
import { Cookies } from 'interfaces/cookies';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { createAddTreatmentPlanCreditInformation } from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.actions';
import { selectBatches } from 'state/slices/tenant/batches.slice';
import TreatmentPlanCreditAndUACSection from './TreatmentPlanCreditAndUACSection';

export default function AddUnappliedCredit() {
    const dispatch = useDispatch();

    const patientId = usePatientId();

    const [cookies] = useCookies();
    const { data: batches } = useSelector(selectBatches);
    const selectedBatch = batches[cookies[Cookies.SelectedBatch]];

    useEffect(() => {
        if (patientId) dispatch(createAddTreatmentPlanCreditInformation(patientId, selectedBatch));
    }, []);

    return <TreatmentPlanCreditAndUACSection hasNotes heading="Add Unapplied Credit" />;
}
